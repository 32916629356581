import { INonce, Interaction, Transaction } from '@elrondnetwork/erdjs/out';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';
import { SimpleTransactionType } from '@elrondnetwork/dapp-core/types/transactions.types';
import { getChainID } from '@elrondnetwork/dapp-core/utils';

const defaultTransactionParams = {
  errorMessage: 'There was an error',
  successMessage: 'Transaction processed',
  processingMessage: 'Processing transaction',
  submittedMessage: 'Transaction submitted',
  transactionDuration: 20000,
};

type TransactionDisplayInfo = {
  errorMessage: string;
  successMessage: string;
  processingMessage: string;
  submittedMessage: string;
  transactionDuration: number;
};

export default async function sendSmartContractMultipleTransactions(
  interactions: Interaction[],
  addressNonce: INonce,
  transactionParams: Partial<TransactionDisplayInfo> = {},
  gasLimit = 20000000,
) {
  const chainId = getChainID();
  const transactions: (Transaction | SimpleTransactionType)[] = [];
  interactions.forEach(interaction => {
    interaction = interaction.withNonce(addressNonce).withGasLimit(gasLimit).withChainID(chainId);
    const transaction = interaction.buildTransaction();
    transactions.push(transaction);
  });

  const { sessionId } = await sendTransactions({
    transactions: transactions,
    transactionsDisplayInfo: {
      ...defaultTransactionParams,
      ...transactionParams,
    },
    completedTransactionsDelay: 100,
    redirectAfterSigning: false,
  });

  return sessionId;
}
