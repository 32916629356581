import SerializableModel from '../../Core/models/serializable.model';

export default class FeedSettingsModel extends SerializableModel {
  showEcosystemStats = true;
  showDailyHighlights = true;
  showAirdrops = true;
  showLaunchpadSales = true;
  showEarnPrograms = true;

  constructor(params: any) {
    super();
    this.initializeModel(params);
  }
}
