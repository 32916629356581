import React from 'react';
import styles from './partner.item.module.scss';

import Icon from '../../../../../../../components/Atomic/Icon';

export type TPartnerItem = {
  logo: string;
  title: string;
  description: string;
  social: { [key: string]: string };
  website: string;
};

type Props = {
  partner: TPartnerItem;
};

export default function PartnerItem(props: Props) {
  const { partner } = props;

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={partner.logo} className={styles.logo} />
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <h4>{partner.title}</h4>
          <div>{partner.description}</div>
        </div>

        <div className={styles.footer}>
          <div className={styles.linksWrapper}>
            <div className={styles.sectionTitle}>{'Social Networks'}</div>
            {Object.keys(partner.social).map((key: string) => {
              return (
                <a
                  className={styles.socialIcon}
                  key={key}
                  href={partner.social[key]}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <Icon name={`fa-brands fa-${key}`} />
                </a>
              );
            })}
          </div>
          <div className={styles.linksWrapper}>
            <div className={styles.sectionTitle}>Website:</div>
            <a href={partner.website} target={'_blank'} rel="noreferrer">
              {partner.website}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
