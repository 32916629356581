import SerializableModel from '../../Core/models/serializable.model';
import ApplicantModel from './applicant.model';
import DocumentModel from './document.model';
import CheckModel from './check.model';
import { KycApplicationType } from '../../Account/types/kyc.application.type';

export default class KycInfoModel extends SerializableModel {
  kycApplication: KycApplicationType | null;
  applicant: ApplicantModel | null;
  documents: DocumentModel[];
  checks: CheckModel[];

  constructor(params: any = {}) {
    super();
    this.initializeModel(params);
  }
}
