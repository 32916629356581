import React from 'react';

import styles from './Switch.module.scss';

type Props = {
  value: boolean;
  onChange: (newValue: boolean) => void;
  disabled?: boolean;
};

const DefaultSwitch = (props: Props) => {
  const { value, onChange, disabled = false } = props;

  return (
    <div className={styles.container} data-disabled={disabled}>
      <div className={styles.option} onClick={() => onChange(true)} data-active={value}>
        <div>{'ON'}</div>
      </div>
      <div className={styles.option} onClick={() => onChange(false)} data-active={!value}>
        <div>{'OFF'}</div>
      </div>
    </div>
  );
};

export default DefaultSwitch;
