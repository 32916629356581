import React, { useContext, useMemo } from 'react';

import TokenSelector, { TokenSelectorProps } from './TokenSelector';
import { AccountContext } from '../../../modules/Account/AccountContextProvider';
import { EgldToken, LockedMexToken } from '../../../modules/Tokens/CustomTokens';

type TEsdtTokenSelector = TokenSelectorProps & {
  onlyVerifiedTokens?: boolean;
};

const WalletEsdtTokenSelector = ({ onlyVerifiedTokens = false, ...props }: TEsdtTokenSelector) => {
  const { esdtTokens } = useContext(AccountContext);

  const usedTokens = useMemo(
    () =>
      onlyVerifiedTokens ? esdtTokens.verifiedTokensList : [EgldToken, LockedMexToken, ...esdtTokens.allTokensList],
    [onlyVerifiedTokens, esdtTokens],
  );

  return <TokenSelector options={usedTokens} showBalance={true} {...props} />;
};

export default WalletEsdtTokenSelector;
