import React, { useEffect } from 'react';
import { useGetLoginInfo } from '@elrondnetwork/dapp-core/hooks';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { AUTH_ROUTE } from '../NavigationConstants';

export default function AuthenticatedRoute() {
  const { isLoggedIn } = useGetLoginInfo();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(AUTH_ROUTE, {
        state: {
          backgroundLocation: location,
        },
      });
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
}
