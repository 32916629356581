export const filterOption = (candidate: any, input: string) => {
  const getSafeValue = (value: string | null | undefined) => (value || '').toLowerCase();
  const safelyIncludesInput = (value: string | null | undefined) => getSafeValue(value).includes(getSafeValue(input));

  return (
    safelyIncludesInput(candidate.data.name) ||
    safelyIncludesInput(candidate.data.ticker) ||
    safelyIncludesInput(candidate.data.collection) ||
    safelyIncludesInput(candidate.data.identifier)
  );
};

export const tokenLabelExtractor = (item: any) => `${item.name} (${item.identifier})`;
export const tokenValueExtractor = (item: any) => item.identifier;
