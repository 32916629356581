import React from 'react';
import { useTheme } from 'styled-components';
import Select, { components, PlaceholderProps, Props, SingleValueProps } from 'react-select';
import styles from './Select.module.scss';
import { OptionComponent } from './OptionComponent';
import Typography from '../../Typography';
import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ControlComponent = ({ children, error, ...props }: any) => {
  return (
    <div
      className={styles.controlContainer}
      data-is-error={!!error}
      data-is-disabled={props.isDisabled}
      data-is-focused={props.isFocused}
    >
      <components.Control {...props}>
        <div className={styles.controlText}>{children}</div>
      </components.Control>
    </div>
  );
};

const PlaceholderComponent = ({ children, ...props }: PlaceholderProps) => {
  const classes = [styles.placeholderText, props.isDisabled ?? 'disabled'];

  return (
    <div className={styles.absoluteContainer}>
      <div className={_.join(classes, ' ')}>{children}</div>
    </div>
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps) => {
  const classes = [styles.valueText, props.isDisabled ?? 'disabled'];

  return (
    <div className={styles.absoluteContainer}>
      <div className={_.join(classes, ' ')}>{children}</div>
    </div>
  );
};

export type InputProps = Props & {
  label?: string;
  error?: string;
};

const DefaultSelect = ({ label, components, error, ...props }: InputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();

  const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (styles: any) => ({
      ...styles,
      padding: 5,
      background: theme.colors.background,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (styles: any) => ({
      ...styles,
      color: theme.colors.text,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (styles: any) => ({
      ...styles,
      overflow: 'hidden',
      background: theme.colors.background,
      border: '1px solid ' + theme.colors.border,
      borderRadius: 'var(--default-border-radius)',
    }),
  };

  return (
    <div className={styles.wrapper}>
      {label && <Typography.Copy>{label}</Typography.Copy>}
      <div className={styles.selectContainer} data-type={'select'}>
        <Select
          styles={customStyles}
          components={{
            Control: props => <ControlComponent error={error} {...props} />,
            Placeholder: PlaceholderComponent,
            SingleValue,
            Option: OptionComponent,
            ...components,
          }}
          controlShouldRenderValue
          {...props}
        />
      </div>
      {error && (
        <span className={styles.errorContainer}>
          <Typography.Copy>{error}</Typography.Copy>
        </span>
      )}
    </div>
  );
};

export default DefaultSelect;
