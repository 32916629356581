import SerializableModel from '../Core/models/serializable.model';
import { ProjectTagsEnum } from './enums/projectTagsEnum';

export default class ProjectModel extends SerializableModel {
  id: string;
  ownerAddress: string;
  name: string;
  shortDescription: string;
  longDescription: string;
  imageUrl: string;
  websiteUrl: string;
  whitepaperUrl: string;
  roadmapUrl: string;
  tokens: string[] = [];
  managers: string[] = [];
  smartContracts: {
    name: string;
    address: string;
  }[] = [];
  status: string;
  saleRounds: [];
  updatedAt: Date;
  createdAt: Date;
  tags: ProjectTagsEnum[];

  socialLinks: { [key: string]: string | undefined } = {
    facebook: undefined,
    twitter: undefined,
    telegram: undefined,
    linkedIn: undefined,
    whatsApp: undefined,
    youtube: undefined,
    instagram: undefined,
    discord: undefined,
  };

  constructor(params: Partial<ProjectModel> & { industry?: ProjectTagsEnum } = {}) {
    super();
    this.initializeModel(params);

    if (params.updatedAt) this.updatedAt = new Date(params.updatedAt);
    if (params.createdAt) this.createdAt = new Date(params.createdAt);

    if (params.industry) {
      this.tags = [params.industry];
    }
  }
}
