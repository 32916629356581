import React, { useContext, useState } from 'react';
import styles from './profile.details.module.scss';

import Modal from '../../../../../components/Atomic/Modal';
import DefaultInput from '../../../../../components/Atomic/Input';
import DefaultButton from '../../../../../components/Atomic/Buttons/DefaultButton';

import AccountService from '../../../../../modules/Account/AccountService';
import { AccountContext } from '../../../../../modules/Account/AccountContextProvider';
import { useRegisterHeroTagTransaction } from '../../../../../modules/Dapp/interactions/hero-tag/useRegisterHeroTagTransaction';

import defaultCover from './assets/default-cover.png';
import defaultAvatar from './assets/default-avatar.png';

export default function ProfileDetails() {
  const { account } = useContext(AccountContext);
  const [heroTag, setHeroTag] = useState('');
  const registerHeroTag = useRegisterHeroTagTransaction();
  const [heroTagError, setHeroTagError] = useState(false);
  const [isRegisterHeroTagActive, setIsRegisterHeroTagActive] = useState(false);

  const closeModal = () => {
    setHeroTag('');
    setHeroTagError(false);
    setIsRegisterHeroTagActive(false);
  };

  const registerHeroTagCallback = () => {
    AccountService.checkHeroTagExists(heroTag).then(async response => {
      if (!response) {
        await registerHeroTag(heroTag);
        closeModal();
      } else {
        setHeroTagError(true);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.cover} style={{ backgroundImage: `url(${defaultCover})` }}></div>

      <div className={styles.content}>
        <div className={styles.avatar} style={{ backgroundImage: `url(${defaultAvatar})` }} />

        <div className={styles.textContainer}>
          {account.accountInformation?.username ? (
            <h4 className={styles.heroTag}>{account.heroTag}</h4>
          ) : (
            <DefaultButton
              onClick={() => setIsRegisterHeroTagActive(true)}
              text={'Register your herotag'}
              type={'primary'}
              mode={'text'}
            />
          )}
        </div>
      </div>

      {isRegisterHeroTagActive && (
        <Modal title={'Register Herotag'} onClose={closeModal}>
          <div className={styles.modalContent}>
            <DefaultInput
              label={'Herotag'}
              name={'heroTag'}
              placeholder={'e.g.: esdtspace'}
              value={heroTag}
              onChange={e => setHeroTag(e.target.value)}
              error={heroTagError ? 'Herotag already exists' : undefined}
            />

            <DefaultButton
              mode={'text'}
              type={'primary'}
              text={'Register herotag'}
              disabled={!heroTag}
              onClick={registerHeroTagCallback}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
