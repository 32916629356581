import React, { useState } from 'react';
import styled from 'styled-components';

import Typography from '../../../components/Atomic/Typography';
import MaxWidthContainer from '../../../components/Layout/Containers/MaxWidthContainer';
import JoinCommunitySection from '../../../components/screens/common/join.community.section';
import LoadMoreProjectsList from '../../../components/Project/ProjectsList/LoadMoreProjectsList';

import ProjectFilterModel from '../../../modules/Project/ProjectFilterModel';
import { ProjectListProvider } from '../../../modules/Project/ProjectListProvider';

const ScreenContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xxl}px;
  --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;
    gap: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const TitleAndParagraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;
`;

const ProjectListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProjectsContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  gap: ${props => props.theme.sizes.xxl * 3}px;
`;

const initialFilters = new ProjectFilterModel();
initialFilters.skip = 0;
initialFilters.limit = 9;

const LatestProjects = () => {
  const [filters, setFilters] = useState<ProjectFilterModel>(initialFilters);

  return (
    <ScreenContainer>
      <ContentContainer>
        <TitleAndParagraphs>
          <Typography.H1>{'Latest Projects'}</Typography.H1>
          <Typography.Copy>{'Explore the latest projects added to the ESDT SPACE ecosystem'}</Typography.Copy>
        </TitleAndParagraphs>
        <ProjectListProvider filters={filters}>
          <ProjectsContainer>
            <ProjectListContainer>
              <LoadMoreProjectsList disableLoadMore={false} filters={filters} onPageChange={setFilters} />
            </ProjectListContainer>
          </ProjectsContainer>
        </ProjectListProvider>
        <JoinCommunitySection />
      </ContentContainer>
    </ScreenContainer>
  );
};

export default LatestProjects;
