import React, { useEffect, useState } from 'react';
import Typography from '../../../../../components/Atomic/Typography';

import styles from './kyc.applications.module.scss';

import KYCService from '../../../../../modules/KYC/KYCService';
import ProjectModel from '../../../../../modules/Project/ProjectModel';
import KycInfoModel from '../../../../../modules/KYC/models/kyc.info.model';

import ProjectRepository from '../../../../../modules/Project/ProjectRepository';
import { KycApplicationType } from '../../../../../modules/Account/types/kyc.application.type';

type Props = {
  applications: KycApplicationType[];
};

export default function KycApplications(props: Props) {
  const { applications } = props;

  const [kycApplicantsIds, setKycApplicantsIds] = useState<string[]>([]);
  const [kycApplications, setKycApplications] = useState<KycInfoModel[]>([]);
  const [kycApplicationsProjects, setKycApplicationsProjects] = useState<ProjectModel[]>([]);

  useEffect(() => {
    if (applications) {
      const promises = applications.map(item => {
        return ProjectRepository.getProject(item.projectId);
      });

      Promise.all(promises).then(response => {
        setKycApplicationsProjects(response);
      });

      setKycApplicantsIds(applications.map(item => item.applicantId));
    }
  }, [applications]);

  useEffect(() => {
    const promises = kycApplicantsIds.map(id => {
      return KYCService.getApplicantKycById(id);
    });

    Promise.all(promises).then(response => {
      setKycApplications(response);
    });
  }, [kycApplicantsIds]);

  return (
    <div className={styles.container}>
      <h4>{'KYC Applications'}</h4>

      {kycApplications.length === 0 && <div>No KYC data found</div>}
      {kycApplications.length > 0 && (
        <div className={styles.itemsWrapper}>
          {kycApplications.map((kycInfo, key) => {
            const project = kycApplicationsProjects.find(project => project.id === kycInfo.kycApplication?.projectId);
            return (
              <div className={styles.itemContainer} key={key}>
                <div className={styles.participantRow}>
                  <Typography.Copy className={'title'}>{'Project'}</Typography.Copy>
                  <Typography.Copy>{project?.name}</Typography.Copy>
                </div>

                <div className={styles.participantRow}>
                  <Typography.Copy className={'title'}>{'Name'}</Typography.Copy>
                  <Typography.Copy>{kycInfo.applicant?.firstName + ' ' + kycInfo.applicant?.lastName}</Typography.Copy>
                </div>

                <div className={styles.participantRow}>
                  <Typography.Copy className={'title'}>{'Email'}</Typography.Copy>
                  <Typography.Copy>{kycInfo.applicant?.email}</Typography.Copy>
                </div>

                <div className={styles.participantRow}>
                  <Typography.Copy className={'title'}>{'Kyc identity status'}</Typography.Copy>
                  <Typography.Copy>{kycInfo.kycApplication?.identityStatus}</Typography.Copy>
                </div>

                <div className={styles.participantRow}>
                  <Typography.Copy className={'title'}>{'Kyc documents status'}</Typography.Copy>
                  <Typography.Copy>{kycInfo.kycApplication?.documentsStatus}</Typography.Copy>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
