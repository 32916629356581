import SerializableModel from './serializable.model';

export default class DefaultQueryParamsModel extends SerializableModel {
  skip = 0;
  limit = 10;

  constructor(params: unknown) {
    super();
    this.initializeModel(params);
  }
}
