import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import DefaultInput from '../../../components/Atomic/Input';
import Typography from '../../../components/Atomic/Typography';
import DefaultTextarea from '../../../components/Atomic/Input/Textarea';
import Stepper from '../../../components/Atomic/Stepper/components/Stepper';
import ProjectTagPicker from '../../../components/Pickers/ProjectTagPicker';
import BoxWithGlowShadow from '../../../components/Atomic/BoxWithGlowShadow';
import DefaultButton from '../../../components/Atomic/Buttons/DefaultButton';
import { useStepperContext } from '../../../components/Atomic/Stepper/StepperProvider';
import MaxWidthContainer from '../../../components/Layout/Containers/MaxWidthContainer';
import WalletEsdtTokenSelector from '../../../components/Tokens/Selectors/WalletEsdtTokenSelector';

import TokenModel from '../../../modules/Tokens/models/TokenModel';
import ProjectRepository from '../../../modules/Project/ProjectRepository';
import CreateProjectDto from '../../../modules/Project/dto/create.project.dto';
import { LocalizationContext } from '../../../modules/I18n/LocalizationProvider';
import { ProjectTagsEnum } from '../../../modules/Project/enums/projectTagsEnum';

import ProjectValidation from './Validation/ProjectValidation';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xxxl}px;
  padding-top: ${props => props.theme.sizes.xxxl * 2}px;
  padding-bottom: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    padding: ${props => props.theme.sizes.xxl * 2}px 0;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    min-height: initial;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    padding: ${props => props.theme.sizes.xxl * 2}px 0;
  }
`;

const TitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;
`;

const SectionItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.sizes.xl}px;

  @media all and ${({ theme: { device } }) => device.sm} {
    grid-template-columns: 1fr;
  }
`;

const StepperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xl}px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${props => props.theme.sizes.xl}px;
`;

const ProjectCreateScreen = () => {
  const navigate = useNavigate();
  const { incrementCurrentStep, decrementCurrentStep } = useStepperContext();

  const { translations } = useContext(LocalizationContext);
  const translationsObject = translations.screens.projectCreate;

  const [project, setProject] = useState(new CreateProjectDto());
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedTokens, setSelectedTokens] = useState<TokenModel[]>([]);

  const onChangeAttribute = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setProject(Object.assign(new CreateProjectDto(), project, { [name]: value }));
  };

  const onChangeToken = (value: TokenModel[]) => {
    if (value.length > 0) {
      setProject(
        Object.assign(new CreateProjectDto(), project, { tokens: value.map((item: TokenModel) => item.identifier) }),
      );
      setSelectedTokens(value);
    } else {
      setProject(Object.assign(new CreateProjectDto(), project, { tokens: [] }));
      setSelectedTokens([]);
    }
  };

  const onProjectTagChange = (value: ProjectTagsEnum[]) => {
    setProject(Object.assign(new CreateProjectDto(), project, { tags: value }));
  };

  const onChangeSocialLinkAttribute = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const socialLinks = Object.assign({}, project.socialLinks);
    socialLinks[name] = value;

    setProject(Object.assign(new CreateProjectDto(), project, { socialLinks: socialLinks }));
  };

  const createProjectCallback = () => {
    ProjectRepository.createProject(project).then(() => {
      navigate('/app/projects/me');
    });
  };

  const onClickGeneralInfoNext = () => {
    const errors = ProjectValidation.validateProjectGeneralInfo(project);

    if (JSON.stringify(errors) !== JSON.stringify({})) {
      setErrors(errors);
      return;
    }

    incrementCurrentStep();
  };

  const onClickProjectLinksNext = () => {
    const errors = ProjectValidation.validateProjectLinks(project);

    if (JSON.stringify(errors) !== JSON.stringify({})) {
      setErrors(errors);
      return;
    }

    incrementCurrentStep();
  };

  return (
    <MaxWidthContainer>
      <SectionWrapper>
        <TitleAndDescriptionWrapper>
          <Typography.H2>{translationsObject.title}</Typography.H2>
          <Typography.Copy>{translationsObject.description}</Typography.Copy>
        </TitleAndDescriptionWrapper>
        <BoxWithGlowShadow>
          <Stepper>
            <Stepper.Steps>
              <Stepper.Step id="first" name={translationsObject.sections.projectInformation}>
                <StepperContent>
                  <SectionItemWrapper>
                    <DefaultInput
                      label={translationsObject.fields.name.label}
                      name={'name'}
                      placeholder={translationsObject.fields.name.placeholder}
                      value={project.name}
                      onChange={onChangeAttribute}
                      error={errors.name ? translationsObject.errors[errors.name] : null}
                    />

                    <ProjectTagPicker
                      value={project.tags}
                      onChange={onProjectTagChange}
                      error={errors.tags ? translationsObject.errors[errors.tags] : null}
                    />

                    <DefaultInput
                      label={translationsObject.fields.shortDescription.label}
                      name={'shortDescription'}
                      placeholder={translationsObject.fields.shortDescription.placeholder}
                      value={project.shortDescription}
                      onChange={onChangeAttribute}
                      error={errors.shortDescription ? translationsObject.errors[errors.shortDescription] : null}
                    />

                    <WalletEsdtTokenSelector
                      value={selectedTokens}
                      onChange={onChangeToken}
                      label={translationsObject.fields.token.label}
                      placeholder={translationsObject.fields.token.placeholder}
                      error={errors.tokenIdentifier ? translationsObject.errors[errors.tokenIdentifier] : null}
                    />

                    <DefaultTextarea
                      label={translationsObject.fields.longDescription.label}
                      name={'longDescription'}
                      placeholder={translationsObject.fields.longDescription.placeholder}
                      value={project.longDescription}
                      onChange={onChangeAttribute}
                      error={errors.longDescription ? translationsObject.errors[errors.longDescription] : null}
                    />
                  </SectionItemWrapper>

                  <ButtonsContainer>
                    <DefaultButton text={'Next step'} onClick={onClickGeneralInfoNext} />
                  </ButtonsContainer>
                </StepperContent>
              </Stepper.Step>
              <Stepper.Step id="second" name={translationsObject.sections.projectLinks}>
                <StepperContent>
                  <SectionItemWrapper>
                    <DefaultInput
                      label={translationsObject.fields.websiteUrl.label}
                      name={'websiteUrl'}
                      placeholder={translationsObject.fields.websiteUrl.placeholder}
                      value={project.websiteUrl}
                      onChange={onChangeAttribute}
                      error={errors.websiteUrl ? translationsObject.errors[errors.websiteUrl] : null}
                    />

                    <DefaultInput
                      label={translationsObject.fields.whitepaperUrl.label}
                      name={'whitepaperUrl'}
                      placeholder={translationsObject.fields.whitepaperUrl.placeholder}
                      value={project.whitepaperUrl}
                      onChange={onChangeAttribute}
                      error={errors.whitepaperUrl ? translationsObject.errors[errors.whitepaperUrl] : null}
                    />

                    <DefaultInput
                      label={translationsObject.fields.roadmapUrl.label}
                      name={'roadmapUrl'}
                      placeholder={translationsObject.fields.roadmapUrl.placeholder}
                      value={project.roadmapUrl}
                      onChange={onChangeAttribute}
                      error={errors.roadmapUrl ? translationsObject.errors[errors.roadmapUrl] : null}
                    />

                    <DefaultInput
                      label={translationsObject.fields.imageUrl.label}
                      name={'imageUrl'}
                      placeholder={translationsObject.fields.imageUrl.placeholder}
                      value={project.imageUrl}
                      onChange={onChangeAttribute}
                      error={errors.imageUrl ? translationsObject.errors[errors.imageUrl] : null}
                    />
                  </SectionItemWrapper>

                  <ButtonsContainer>
                    <DefaultButton text={'Previous step'} onClick={decrementCurrentStep} />
                    <DefaultButton text={'Next step'} onClick={onClickProjectLinksNext} />
                  </ButtonsContainer>
                </StepperContent>
              </Stepper.Step>
              <Stepper.Step id="third" name={translationsObject.sections.socialLinks}>
                <StepperContent>
                  <Typography.H3 className={'title'}>{translationsObject.sections.socialLinks}</Typography.H3>
                  <SectionItemWrapper>
                    <DefaultInput
                      label={translationsObject.fields.facebookUrl.label}
                      name={'facebook'}
                      placeholder={translationsObject.fields.facebookUrl.placeholder}
                      value={project.socialLinks.facebook}
                      onChange={onChangeSocialLinkAttribute}
                    />
                    <DefaultInput
                      label={translationsObject.fields.twitterUrl.label}
                      name={'twitter'}
                      placeholder={translationsObject.fields.twitterUrl.placeholder}
                      value={project.socialLinks.twitter}
                      onChange={onChangeSocialLinkAttribute}
                    />
                    <DefaultInput
                      label={translationsObject.fields.telegramUrl.label}
                      name={'telegram'}
                      placeholder={translationsObject.fields.telegramUrl.placeholder}
                      value={project.socialLinks.telegram}
                      onChange={onChangeSocialLinkAttribute}
                    />
                    <DefaultInput
                      label={translationsObject.fields.linkedInUrl.label}
                      name={'linkedIn'}
                      placeholder={translationsObject.fields.linkedInUrl.placeholder}
                      value={project.socialLinks.linkedIn}
                      onChange={onChangeSocialLinkAttribute}
                    />

                    <DefaultInput
                      label={translationsObject.fields.whatsAppUrl.label}
                      name={'whatsApp'}
                      placeholder={translationsObject.fields.whatsAppUrl.placeholder}
                      value={project.socialLinks.whatsApp}
                      onChange={onChangeSocialLinkAttribute}
                    />
                    <DefaultInput
                      label={translationsObject.fields.youtubeUrl.label}
                      name={'youtube'}
                      placeholder={translationsObject.fields.youtubeUrl.placeholder}
                      value={project.socialLinks.youtube}
                      onChange={onChangeSocialLinkAttribute}
                    />
                    <DefaultInput
                      label={translationsObject.fields.instagramUrl.label}
                      name={'instagram'}
                      placeholder={translationsObject.fields.instagramUrl.placeholder}
                      value={project.socialLinks.instagram}
                      onChange={onChangeSocialLinkAttribute}
                    />
                    <DefaultInput
                      label={translationsObject.fields.discordUrl.label}
                      name={'discord'}
                      placeholder={translationsObject.fields.discordUrl.placeholder}
                      value={project.socialLinks.discord}
                      onChange={onChangeSocialLinkAttribute}
                    />
                  </SectionItemWrapper>
                  <ButtonsContainer>
                    <DefaultButton text={'Previous step'} onClick={decrementCurrentStep} />
                    <DefaultButton text={translationsObject.buttonText} onClick={createProjectCallback} />
                  </ButtonsContainer>
                </StepperContent>
              </Stepper.Step>
            </Stepper.Steps>
          </Stepper>
        </BoxWithGlowShadow>
      </SectionWrapper>
    </MaxWidthContainer>
  );
};

export default ProjectCreateScreen;
