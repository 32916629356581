import URLHelper from '../../utils/URLHelper';
import TokenSaleModel from './TokenSaleModel';
import ApiClient from '../Http/ApiClient';
import Environment from '../Environment';

export default class TokenSaleRepository {
  static API_URL = Environment.microservice.launchpadApi;
  private static api = new ApiClient();
  static findSalesRoundsByProjectID(id: string): Promise<TokenSaleModel[]> {
    const queryString = URLHelper.buildQueryString({ projectId: id });
    return this.api
      .withBaseUrl(this.API_URL)
      .get('/sale-rounds?' + queryString)
      .then(response => {
        return response.data.results.map((item: any) => new TokenSaleModel(item));
      });
  }
}
