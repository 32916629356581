import Environment from '../Environment';
import ApiClient from '../Http/ApiClient';

export default class TokenPricesService {
  static API_URL = Environment.microservice.spaceApi;
  private static api = new ApiClient();

  static async getTokenPrices() {
    return this.api
      .withBaseUrl(this.API_URL)
      .get(`/tokens/prices`)
      .then(response => response.data)
      .catch(() => []);
  }
}
