import React from 'react';
import styles from './feed.settings.module.scss';

import Typography from '../../../../../components/Atomic/Typography';
import DefaultSwitch from '../../../../../components/Atomic/Input/Switch';

import FeedSettingsModel from '../../../../../modules/Account/models/feed.settings.model';

type Props = {
  settings: FeedSettingsModel;
  changeHandler: (key: string, value: unknown) => void;
};

type ItemProps = {
  title: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

function SettingItem(props: ItemProps) {
  const { title, value, onChange } = props;

  return (
    <div className={styles.itemContainer}>
      <Typography.Copy>{title}</Typography.Copy>
      <DefaultSwitch value={value} onChange={onChange} />
    </div>
  );
}

export default function FeedSettings(props: Props) {
  const { settings, changeHandler } = props;

  return (
    <div className={styles.container}>
      <h4>{'Feed Settings'}</h4>
      <div className={styles.itemsWrapper}>
        <SettingItem
          title={'Ecosystem Stats'}
          value={settings.showEcosystemStats}
          onChange={(value: boolean) => changeHandler('showEcosystemStats', value)}
        />
        <SettingItem
          title={'Daily Highlights'}
          value={settings.showDailyHighlights}
          onChange={(value: boolean) => changeHandler('showDailyHighlights', value)}
        />
        <SettingItem
          title={'Ongoing & Upcoming Airdrops'}
          value={settings.showAirdrops}
          onChange={(value: boolean) => changeHandler('showAirdrops', value)}
        />
        <SettingItem
          title={'Ongoing & Upcoming Launchpad'}
          value={settings.showLaunchpadSales}
          onChange={(value: boolean) => changeHandler('showLaunchpadSales', value)}
        />
        <SettingItem
          title={'Ongoing & Upcoming Learn & Earn Programs'}
          value={settings.showEarnPrograms}
          onChange={(value: boolean) => changeHandler('showEarnPrograms', value)}
        />
      </div>
    </div>
  );
}
