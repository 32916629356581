import React from 'react';
import Typography from '../../../Typography';
import styles from './ListItemsRenderer.module.scss';

import DynamicContentRenderer from '../index';

// @typescript-eslint/no-explicit-any
const ListItemsRenderer = ({ content }: { content: any }) => {
  const listType = content.listType || 'circle';
  const title = content.listTitle || '';

  const listStyleItemMap: any = {
    custom: 'none',
    circle: 'circle',
    number: 'decimal',
  };

  return (
    <div className={styles.listContainer}>
      {title.length > 0 && <Typography.Copy dangerouslySetInnerHTML={{ __html: title }} />}
      <div className={listType == 'custom' ? 'customListItemWrapper' : ''}>
        {content.items.map((listItem: any, listItemIndex: number) => (
          <div className={listType == 'custom' ? 'customListItemContainer' : ''} key={listItemIndex}>
            {listType === 'custom' && <Typography.Copy>{listItem.listItemSymbol}</Typography.Copy>}
            {/*<ParagraphsListRenderer content={listItem} />*/}
            <DynamicContentRenderer content={listItem} customRenderers={null} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListItemsRenderer;
