import alexBerce from './assets/AlexBerce.png';
import cosminMitroi from './assets/CosminMitroi.png';
import florinHorne from './assets/FlorinHorne.png';
import andreiGorgan from './assets/AndreiGorgan.png';
import alinaBerce from './assets/AlinaBerce.png';
import vladLungu from './assets/VladLungu.png';

export type TeamMember = {
  name: string;
  image: string;
  position: string;
  description: string;

  social: {
    twitter?: string;
    linkedin?: string;
  };
};

const coreTeam: TeamMember[] = [
  {
    name: 'Alexandru Berce',
    position: 'Co-Founder & Full-stack Developer',
    description:
      'Alex is a Sr. Full-Stack developer with over 12 years of experience building highly-scalable enterprise web and mobile solutions.',
    image: alexBerce,
    social: {
      twitter: 'https://twitter.com/AlexandruBerce',
      linkedin: 'https://www.linkedin.com/in/alexandruberce',
    },
  },
  {
    name: 'Andrei Gorgan',
    position: 'Co-Founder & Full-stack Developer',
    description:
      'Andrei is a highly motivated individual with a variety of skills who consistently meets deadlines, even under pressure. He work extremely well with clients and the rest of the team to develop the ideal project solutions.',
    image: andreiGorgan,
    social: {
      twitter: 'https://twitter.com/gorgan_andrei',
      linkedin: 'https://www.linkedin.com/in/andrei-gorgan-35623456',
    },
  },
  {
    name: 'Cosmin Mitroi',
    position: 'Co-Founder & Full-stack Developer',
    description:
      'Cosmin worked on a variety of projects for more than 12 years, and his can-do attitude enabled him to produce outstanding results on every project he worked on. He is a driven, motivated individual.',
    image: cosminMitroi,
    social: {
      twitter: 'https://twitter.com/CosminMitroi',
      linkedin: 'https://www.linkedin.com/in/cosmin-mitroi-72453637',
    },
  },
  {
    name: 'Florin Horne',
    position: 'Co-Founder & Full-stack Developer',
    description:
      'Florin has over 14 years of expertise writing reliable code for high-volume businesses, he is a creative and dynamic software engineer.',
    image: florinHorne,
    social: {
      twitter: 'https://twitter.com/thunder86rulz',
      linkedin: 'https://www.linkedin.com/in/florin-daniel-horne-3430266b',
    },
  },
];

const members: TeamMember[] = [
  {
    name: 'Alina Berce',
    position: 'Sr. Full-stack Developer',
    description: 'Dedicated full-stack developer with over 8 years of experience in building web applications.',
    image: alinaBerce,
    social: {
      twitter: 'https://twitter.com/AlinaBerce',
      linkedin: 'https://www.linkedin.com/in/alina-berce-5971b6ab',
    },
  },
  {
    name: 'Vlad Lungu',
    position: 'Sr. Quality Assurance Engineer',
    description:
      'Vlad has more than 6 years of experience in the domain of quality assurance working with numerous programming languages and testing tools like Java and Selenium, Jmeter and Cypress.',
    image: vladLungu,
    social: {
      twitter: 'https://twitter.com/VladLungu06',
      linkedin: 'https://www.linkedin.com/in/vlad-lungu-06524b109',
    },
  },
];

const shuffledCore = coreTeam
  .map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);

const allTeam = [...shuffledCore, ...members];

export default allTeam;
