import React from 'react';
import styles from './contact.section.module.scss';

export default function ContactSection() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>If you are interested in partnering with EsdtSpace, we would love to hear from you!</div>
        <div>
          Please send us an email at <span>contact@esdt.space</span> to get in touch and start exploring the
          possibilities.
        </div>
      </div>
    </div>
  );
}
