import React from 'react';

import StatsSection from '../../../../components/screens/common/stats.section';
import blockStyles from '../../../../components/screens/common/page.blocks/page.module.scss';
import JoinCommunitySection from '../../../../components/screens/common/join.community.section';

/** Local Components **/
import HeroSection from './components/hero.section';
import AboutSection from './components/about.section';
import SolutionsSection from './components/solutions.section';
import InlineLinksSection from './components/inline.links.section';

export default function HomepageScreen() {
  return (
    <div className={blockStyles.screenContainer}>
      <div className={blockStyles.heroSectionWrapper}>
        <HeroSection />
      </div>
      <div className={blockStyles.sectionsMaxWidthWrapper}>
        <StatsSection />
        <AboutSection />
        <InlineLinksSection />
        <SolutionsSection />
        <JoinCommunitySection />
      </div>
    </div>
  );
}
