import React, { useEffect } from 'react';
import { useStepperContext } from '../StepperProvider';

export const StepperSteps = function ({ children }: { children: JSX.Element[] }) {
  const { currentStep, steps, addSteps } = useStepperContext();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stepperSteps = React.Children.toArray(children).map((step: any) => step.props);
    addSteps(stepperSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {children &&
        React.Children.map(children, child => {
          if (steps.length) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return child.props.id === steps[currentStep].id ? child : null;
          }
        })}
    </div>
  );
};

export const StepperStep = function ({ children }: { children: JSX.Element; id: string; name: string }) {
  return <>{children}</>;
};
