import React from 'react';

import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import heroStyles from '../../../../../../components/screens/common/page.blocks/hero.module.scss';

/** Local Imports **/
import styles from './hero.section.module.scss';
import sectionImage from './../../assets/hero-illustration.png';

const HeroSection = () => {
  return (
    <div className={styles.componentWrapper}>
      <div className={styles.componentContent}>
        <div className={styles.limitedContent}>
          <div className={heroStyles.heroContentContainer}>
            <div>
              <h1 className={heroStyles.heroTitle}>ESDT SPACE</h1>
              <h2 className={heroStyles.heroSubtitle}>Blockchain Solutions Partner</h2>
            </div>
            <div className={heroStyles.heroParagraphsWrapper}>
              <div className={heroStyles.heroParagraph}>
                Effortless blockchain interactions for every individual and business out there.
              </div>
              <div className={heroStyles.heroParagraph}>
                Reduce the blockchain complexity by leveraging our accessible, borderless and customer-centric ecosystem
                of blockchain apps.
              </div>
              <div className={heroStyles.heroParagraph}>
                We are enhancing the building experience on <strong className={'multiversX'}>MultiversX</strong> by
                helping teams have a smooth transition to blockchain and reduce their costs with development &
                blockchain infrastructure.
              </div>
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            <DefaultButton type={'primary'} text={'EXPLORE PROJECTS'} />
            <DefaultButton mode={'text'} type={'secondary'} text={'WHITEPAPER (coming soon)'} />
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={sectionImage} />
      </div>
    </div>
  );
};

export default HeroSection;
