import React from 'react';
import styles from './about.section.module.scss';

import SolutionItem from './solution.item';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

const data = {
  personal: {
    title: 'For Individuals',
    features: [
      'Portfolio Tracker',
      'Wallet Management',
      'Token Management Tools',
      'Learn & Earn Programs',
      'ICO Launchpad Access',
    ],
    link: '/personal',
  },
  business: {
    title: 'For Business',
    features: [
      'Project Exposure',
      'Project Management',
      'Token Snapshots',
      'Blockchain Event Listeners',
      'Staking Pools',
    ],
    link: '/business',
  },
};

export default function AboutSection() {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h2 className={sectionStyles.sectionTitle}>
          One ecosystem,
          <br />
          all things blockchain.
        </h2>
        <div className={sectionStyles.sectionParagraph}>
          Versatile ecosystem providing both product and service-based solutions to accommodate individuals and
          businesses and help them through the entire blockchain journey. We partake in making the blockchain experience
          more comprehensible for everyone by introducing a new level of efficiency and automation.
        </div>
      </div>

      <div className={styles.itemsWrapper}>
        <SolutionItem
          type={'personal'}
          link={data.personal.link}
          title={data.personal.title}
          features={data.personal.features}
        />
        <SolutionItem
          type={'business'}
          link={data.business.link}
          title={data.business.title}
          features={data.business.features}
        />
      </div>
    </div>
  );
}
