import React from 'react';
import styled from 'styled-components';

import solutionsImg from './assets/solutions-image.png';
import Typography from '../../../../../../components/Atomic/Typography';
import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

const SectionWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  @media all and ${({ theme: { device } }) => device.sm} {
    min-height: initial;
    height: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${Typography.H2}, ${Typography.H3} {
    font-weight: bold;
  }

  @media all and ${({ theme: { device } }) => device.md} {
    gap: ${props => props.theme.sizes['xxl']}px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.sizes.xxxl}px;
  width: 100%;
  align-items: center;

  @media all and ${({ theme: { device } }) => device.md} {
    flex-direction: column;
    gap: ${props => props.theme.sizes.xxl}px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;
  max-width: 750px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 20px;
`;

const IllustrationContainer = styled.div`
  flex: 1;
  display: flex;
  min-height: 650px;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url(${solutionsImg});

  @media all and ${({ theme: { device } }) => device.lg} {
    min-height: 450px;
  }

  @media all and ${({ theme: { device } }) => device.md} {
    width: 100%;
    min-height: 350px;
    background-position: center;
  }
`;

export default function SolutionsSection() {
  return (
    <SectionWrapper>
      <Container>
        <SectionContainer>
          <IllustrationContainer />
          <ContentContainer>
            <h2 className={sectionStyles.sectionTitle}>Our Solutions</h2>
            <div className={sectionStyles.sectionParagraphsWrapper}>
              <div className={sectionStyles.sectionParagraph}>
                At EsdtSpace, we are building a versatile ecosystem designed to accommodate the needs of any individual
                or business looking to integrate blockchain technology into their operations. Our ecosystem offers both
                product and service-based solutions to help you navigate every step of the blockchain journey, from
                start to finish.
              </div>

              <div className={sectionStyles.sectionParagraph}>
                Our team of experts is here to provide a wide range of personalized solutions, including token sale
                support, vesting solutions, staking services, and even DAO creation. No matter what your needs may be,
                technology and achieve your goals. we have the resources and expertise to help you make the most of
                blockchain.
              </div>
            </div>
            <ButtonContainer>
              <DefaultButton mode={'outline'} type={'primary'} text={'FOR INDIVIDUALS'} to={'/personal'} />
              <DefaultButton mode={'outline'} type={'secondary'} text={'FOR BUSINESSES'} to={'/business'} />
            </ButtonContainer>
          </ContentContainer>
        </SectionContainer>
      </Container>
    </SectionWrapper>
  );
}
