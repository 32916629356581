import ProjectModel from '../Project/ProjectModel';
import SerializableModel from '../Core/models/serializable.model';
import { ETokenSaleStatus } from './enums/sale.status.enum';
import TokenSalePackModel from './TokenSalePackModel';
import { AccessTypeEnum } from './enums/access.type.enum';
import { SaleRoundTypeEnum } from './enums/sale-round.type.enum';
import { TokenDistributionTypeEnum } from './enums/token.distribution.type.enum';
import LotteryConfiguration from './models/lottery.configuration.model';
import NormalSaleConfiguration from './models/normal.sale.configuration.model';
import WhitelistConfiguration from './models/whitelist.configuration.model';
import OvertimeDistributionConfiguration from './models/overtime.distribution.configuration.model';
import TimelineItemModel from './models/timeline.item.model';
import ParticipantModel from './models/participant.model';

export default class TokenSaleModel extends SerializableModel {
  id: string;
  name: string;
  project: ProjectModel;
  projectId: string;
  saleType: SaleRoundTypeEnum;
  accessType: AccessTypeEnum;
  saleToken: string;
  paymentToken: string;
  participantsCount: number;
  kycRequired: boolean;
  approved: boolean;
  participants: ParticipantModel[];
  landingPageTemplate: object;
  totalTokens: string;
  tokensLeft: string;
  tokenPacks: TokenSalePackModel[];
  timeline: TimelineItemModel[];
  status: ETokenSaleStatus;
  buyerTokensDistributionType: TokenDistributionTypeEnum;
  ownerTokensDistributionType: TokenDistributionTypeEnum;
  lotteryConfiguration: LotteryConfiguration;
  normalSaleConfiguration: NormalSaleConfiguration;
  whitelistConfiguration: WhitelistConfiguration;
  buyerOvertimeDistributionConfiguration: OvertimeDistributionConfiguration[];
  ownerOvertimeDistributionConfiguration: OvertimeDistributionConfiguration[];
  updatedAt: Date;
  createdAt: Date;

  constructor(params: any) {
    super();
    this.initializeModel(params);

    if (params.updatedAt) this.updatedAt = new Date(params.updatedAt);
    if (params.createdAt) this.createdAt = new Date(params.createdAt);
  }
}
