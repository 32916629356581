import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import './theme/global.styles.scss';

import { DappProvider as CustomDappProvider } from '../modules/Dapp/DappProvider';

import { ThemeProvider } from '../modules/Theme/ThemeProvider';
import ApplicationRouter from '../modules/Navigation/ApplicationRouter';
import { EsdtTokensProvider } from '../modules/Tokens/Esdt/EsdtTokensContextProvider';

function App() {
  return (
    <BrowserRouter>
      <CustomDappProvider>
        <EsdtTokensProvider>
          <ThemeProvider>
            <ApplicationRouter />
          </ThemeProvider>
        </EsdtTokensProvider>
      </CustomDappProvider>
    </BrowserRouter>
  );
}

export default App;
