import React, { useContext } from 'react';

import LegalArticlesContent from '../../../../components/screens/legal/legal.articles.content';
import { LocalizationContext } from '../../../../modules/I18n/LocalizationProvider';

const PrivacyPolicyScreen = () => {
  const { translations } = useContext(LocalizationContext);
  const translationsObject = translations.screens.privacyPolicy;

  return (
    <LegalArticlesContent
      title={translationsObject.title}
      paragraphs={translationsObject.paragraphs}
      articles={translationsObject.articles}
    />
  );
};

export default PrivacyPolicyScreen;
