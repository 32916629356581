import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { MenuListProps } from 'react-select';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AutoSizer, List } from 'react-virtualized';

const MenuListContainer = styled.div`
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
`;

const RowContainer = styled.div``;

const MenuList: React.FC<MenuListProps> = ({ children }) => {
  const rows = _.toArray(children);

  const rowRenderer = ({ key, index, style }: { key: string; index: number; style: any }) => (
    <RowContainer key={key} style={style}>
      {rows[index]}
    </RowContainer>
  );

  const numberOfRows = rows.length || 1;
  const rowHeight = 65;
  const maxListHeight = 300;
  const currentListHeight = numberOfRows * rowHeight;
  const listHeight = currentListHeight > maxListHeight ? maxListHeight : currentListHeight;

  return (
    <MenuListContainer style={{ height: listHeight }}>
      <AutoSizer>
        {({ width, height }: { width: string; height: string }) => (
          <List width={width} height={height} rowHeight={rowHeight} rowCount={numberOfRows} rowRenderer={rowRenderer} />
        )}
      </AutoSizer>
    </MenuListContainer>
  );
};

export default MenuList;
