import Environment from '../Environment';
import ApiClient from '../Http/ApiClient';
import SkipLimitPaginationModel from '../Core/models/skip.limit.pagination.model';

import ProjectModel from './ProjectModel';
import ProjectFilterModel from './ProjectFilterModel';
import CreateProjectDto from './dto/create.project.dto';
import URLHelper from '../../utils/URLHelper';

const API_ADDRESS = Environment.microservice.spaceApi;

export default class ProjectRepository {
  private static api = new ApiClient();
  static findProjects(filters: ProjectFilterModel): Promise<SkipLimitPaginationModel<ProjectModel>> {
    const queryString = URLHelper.buildQueryString(filters);
    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .get(`/projects?${queryString}`)
      .then((response: any) => {
        return new SkipLimitPaginationModel(response.data);
      });
  }
  static findOwnProjects(filters: ProjectFilterModel): Promise<SkipLimitPaginationModel<ProjectModel>> {
    const queryString = URLHelper.buildQueryString(filters);
    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .get(`/projects/mine?${queryString}`)
      .then((response: any) => {
        return new SkipLimitPaginationModel(response.data);
      });
  }

  static createProject(data: CreateProjectDto): Promise<ProjectModel> {
    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .post('/projects', data.serialize())
      .then((response: any) => {
        return new ProjectModel(response.data);
      });
  }

  static updateProject(id: string, data: ProjectModel): Promise<ProjectModel> {
    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .put('/projects/' + id, data.serialize())
      .then((response: any) => {
        return new ProjectModel(response.data);
      });
  }

  static getProject(id: string): Promise<ProjectModel> {
    return this.api
      .withBaseUrl(API_ADDRESS)
      .get('/projects/list/' + id)
      .then((response: any) => {
        return new ProjectModel(response.data);
      });
  }
}
