import BigNumber from 'bignumber.js';

type ReturnValue = {
  rawValue: number;
  formattedValue: string;
  approximatedValue: string;
  bigNumberValue: BigNumber;
};

const getStringValue = (value: BigNumber): string => {
  const toFormat = value.toFormat(2);
  const toPrecision = value.toPrecision(2);

  let usedValue = toFormat;

  if (value.isGreaterThanOrEqualTo(10000)) {
    usedValue = `${value.toFormat(0)}.00`;
  } else if (new BigNumber(toFormat).isZero()) {
    const powerArray = toPrecision.split('e');
    const power = (powerArray || []).length > 1 ? powerArray[1] : null;

    usedValue = power ? value.toFormat(Math.abs(Number(power))) : toPrecision;
  }

  return usedValue;
};

const getApproxValuePrefix = (value: string, bigNumberInstance: BigNumber): string => {
  let prefix = '';
  if (!new BigNumber(value.replace(/,/g, '')).isEqualTo(bigNumberInstance)) {
    prefix = '≈';
  }

  return prefix;
};

const commasToSpace = (value: string) => value.replace(/,/g, ' ');
const removeExtraZeroes = (value: string) => value.replace(/(\.0+|0+)$/, '');

export const transformNumber = (value: string | number | BigNumber): ReturnValue => {
  let bigNumber = new BigNumber(value);
  if (bigNumber.isNaN()) {
    bigNumber = new BigNumber(0);
  }

  const stringValue = getStringValue(bigNumber);
  const prefix = getApproxValuePrefix(stringValue, bigNumber);

  return {
    bigNumberValue: bigNumber,
    rawValue: bigNumber.toNumber(),
    formattedValue: commasToSpace(bigNumber.toFormat()),
    approximatedValue: prefix + removeExtraZeroes(commasToSpace(stringValue)),
  };
};
