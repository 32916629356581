import intl from 'react-intl-universal';
import React, { createContext, useEffect, useState } from 'react';

import Environment from '../Environment';

/** Local Imports **/
import { ELanguages } from './enums';
import determineInitialLocale from './helpers';
import { TRANSLATIONS } from './LocalizationConstants';

const initialState = {
  language: Environment.defaultLanguage,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  translations: TRANSLATIONS[Environment.defaultLanguage],
};

export const LocalizationContext = createContext(initialState);
export const LocalizationProvider = ({ children }: { children: JSX.Element }) => {
  const [language, setLanguage] = useState<ELanguages>(initialState.language as ELanguages);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [translations, setTranslations] = useState<any>(initialState.translations);

  useEffect(() => {
    const currentLocale = determineInitialLocale();
    intl.init({
      currentLocale: currentLocale,
      locales: TRANSLATIONS,
    });

    setLanguage(currentLocale as ELanguages);
  }, []);

  useEffect(() => {
    setTranslations(TRANSLATIONS[language]);
  }, [language]);

  return (
    <LocalizationContext.Provider
      value={{
        translations,
        language,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
