import React from 'react';
import styles from './partners.list.module.scss';

import partners from './partners.data';
import PartnerItem from './partner.item';

const PartnersList = () => {
  return (
    <div className={styles.container}>
      <h2>Our Partners</h2>
      <div className={styles.itemsWrapper}>
        {partners.map((partner, index) => (
          <PartnerItem key={index} partner={partner} />
        ))}
      </div>
    </div>
  );
};

export default PartnersList;
