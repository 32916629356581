import { useCallback } from 'react';
import { ContractFunction, BytesValue, Interaction, SmartContract, Address } from '@elrondnetwork/erdjs/out';
import { getAccount, getLatestNonce } from '@elrondnetwork/dapp-core/utils';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import sendSmartContractTransaction from '../useSmartContractTransaction';
import { TTokenControlChangesProperties } from '../../../Tokens/types';
import Environment from '../../../Environment';

export const useUpdateControlChangesTransaction = () => {
  const { address } = useGetAccountInfo();
  const GAS_LIMIT = 60000000;
  const getTransactionData = (tokenIdentifier: string, controlChanges: TTokenControlChangesProperties) => {
    const transactionPayload = [];
    transactionPayload.push(BytesValue.fromUTF8(tokenIdentifier));
    (Object.keys(controlChanges) as (keyof typeof controlChanges)[]).forEach(key => {
      transactionPayload.push(BytesValue.fromUTF8(key));
      transactionPayload.push(BytesValue.fromUTF8(String(controlChanges[key])));
    });

    return {
      transactionPayload: transactionPayload,
    };
  };

  return useCallback(
    async (tokenIdentifier: string, controlChanges: TTokenControlChangesProperties) => {
      const account = await getAccount(address);
      const nonce = getLatestNonce(account);
      const dataToSend = getTransactionData(tokenIdentifier, controlChanges);

      const contract = new SmartContract({
        address: new Address(Environment.elrond.smartContractAddressTokensManagement),
      });

      const smartContractFunction = new ContractFunction('controlChanges');

      const interaction = new Interaction(contract, smartContractFunction, dataToSend.transactionPayload);

      return sendSmartContractTransaction(interaction, nonce, {}, GAS_LIMIT);
    },
    [address],
  );
};
