import React, { useContext, useEffect, useState } from 'react';
import ProjectModel from '../../../../../modules/Project/ProjectModel';
import Typography from '../../../../../components/Atomic/Typography';
import { EsdtTokensContext } from '../../../../../modules/Tokens/Esdt/EsdtTokensContextProvider';
import DefaultButton from '../../../../../components/Atomic/Buttons/DefaultButton';
import TokenSaleRepository from '../../../../../modules/TokenSale/TokenSaleRepository';
import TokenSaleModel from '../../../../../modules/TokenSale/TokenSaleModel';
import Environment from '../../../../../modules/Environment';
import shortLogo from '../../../../../components/Atomic/Branding/AppIcon/assets/short_logo.svg';
import EmptySalesList from './components/EmptySalesList';
import { AccountContext } from '../../../../../modules/Account/AccountContextProvider';
import styles from './TokenSalesSection.module.scss';

const TokenSalesSection = ({ project }: { project: ProjectModel }) => {
  const [tokenSales, setTokenSales] = useState<TokenSaleModel[]>([]);
  const { esdtTokens } = useContext(EsdtTokensContext);
  const { account } = useContext(AccountContext);
  const accessArray = [...project.managers];
  accessArray.push(project.ownerAddress);

  const getTokenInfo = (identifier: string) => {
    return esdtTokens?.find(token => token?.identifier === identifier);
  };

  useEffect(() => {
    if (project.id) {
      TokenSaleRepository.findSalesRoundsByProjectID(project.id).then(response => {
        setTokenSales(response);
      });
    }
  }, [project.id]);

  return (
    <div className={styles.section}>
      <Typography.H2>{'Sale Rounds'}</Typography.H2>
      <div className={styles.card}>
        {tokenSales.length === 0 && (
          <EmptySalesList loading={false} hasAccessToCreate={accessArray.includes(account.address)} />
        )}
        {tokenSales.map((tokenSale, key) => {
          const tokenInfo = getTokenInfo(tokenSale.saleToken);
          const logo = tokenInfo?.hasAssetImage() ? tokenInfo.getAssetImageUrl() : shortLogo;

          return (
            <div className={styles.row} key={key}>
              <div className={styles.logo}>
                <img width={60} height={60} src={logo ? logo : ''} />
                <Typography.Copy>{tokenInfo?.name + ' ' + ' Round ' + (key + 1)}</Typography.Copy>
              </div>
              <DefaultButton
                mode={'outline'}
                type={'primary'}
                text={'View on ESDT Launchpad'}
                onClick={() =>
                  (window.location.href = Environment.website.launchpad + '/app/token-sales/' + tokenSale.id + '/view')
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TokenSalesSection;
