import React from 'react';
import styles from './inline.link.module.scss';

import Icon from '../../../Atomic/Icon';
import Typography from '../../../Atomic/Typography';

import backgroundImage from './assets/background-illustration.png';
import { useNavigate } from 'react-router-dom';

type Item = {
  title: string;
  isComingSoon?: boolean;
  url?: string;
};

export default function InlineLink(props: Item) {
  const navigate = useNavigate();
  const { title, isComingSoon, url } = props;

  const navigateToScreen = () => {
    if (!url) {
      return null;
    }

    navigate(url);
  };

  return (
    <div
      className={styles.itemContainer}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      onClick={navigateToScreen}
    >
      <div className={styles.contentContainer}>
        <div>
          <Typography.H3>{title}</Typography.H3>
          {isComingSoon && <div className={styles.comingSoonText}>coming soon</div>}
        </div>
        <Icon className={styles.icon} name={'fa-solid fa-arrow-right'} />
      </div>
    </div>
  );
}
