import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Typography from '../../../../../components/Atomic/Typography';
import EsdtTokenModel from '../../../../../modules/Tokens/models/EsdtTokenModel';
import DefaultInput from '../../../../../components/Atomic/Input';
import DefaultSwitch from '../../../../../components/Atomic/Input/Switch';
import DefaultButton from '../../../../../components/Atomic/Buttons/DefaultButton';
import DefaultSelect from '../../../../../components/Atomic/Pickers/Select';
import { useBurnTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useBurnTransaction';
import { useMintTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useMintTransaction';
import { useFreezeTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useFreezeTransaction';
import { useUnFreezeTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useUnFreezeTransaction';
import { usePauseTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/usePauseTransaction';
import { useUnPauseTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useUnPauseTransaction';
import { useWipeTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useWipeTransaction';
import { useChangeTokenOwnerTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useChangeTokenOwnerTransaction';
import { useSetSpecialRolesTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useSetSpecialRolesTransaction';
import { useUpdateControlChangesTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useUpdateControlChangesTransaction';
import { TTokenControlChangesProperties } from '../../../../../modules/Tokens/types';
import { useUnsetSpecialRolesTransaction } from '../../../../../modules/Dapp/interactions/update-token-properties/useUnsetSpecialRolesTransaction';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;
  height: 100%;
`;

const TokensSections = styled.div`
  --number-of-columns: 2;

  @media all and (min-width: 1800px) {
    --number-of-columns: 2;
  }

  @media all and ${({ theme: { device } }) => device.md} {
    --number-of-columns: 2;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    --number-of-columns: 1;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    --number-of-columns: 1;
  }

  display: grid;
  grid-template-columns: repeat(var(--number-of-columns), 1fr);
  row-gap: ${props => props.theme.sizes.xl}px;
  column-gap: ${props => props.theme.sizes.xxl}px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.sizes.lg}px;
  gap: ${props => props.theme.sizes.lg}px;
  background: ${props => props.theme.colors.surface};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  height: 100%;
`;

const TokenRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .name {
    font-weight: 700;
  }
`;

const TokenRowProperties = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  .name {
    font-weight: 700;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
`;

const TokenPropertiesSection = ({ token }: { token: EsdtTokenModel }) => {
  const [minted, setMinted] = useState('');
  const [burned, setBurned] = useState('');
  const [freezeAddress, setFreezeAddress] = useState('');
  const [wipeAddress, setWipeAddress] = useState('');
  const [unfreezeAddress, setUnFreezeAddress] = useState('');
  const [specialRoleAddress, setSpecialRoleAddress] = useState('');
  const [specialRole, setSpecialRole] = useState('');
  const [paused, setPaused] = useState(!!token.isPaused);
  const [owner, setOwner] = useState(token.owner);
  const [controlSettings, setControlSettings] = useState<TTokenControlChangesProperties>({
    canBurn: false,
    canChangeOwner: false,
    canFreeze: false,
    canMint: false,
    canPause: false,
    canWipe: false,
    canAddSpecialRoles: false,
  });
  const burnTokenTransaction = useBurnTransaction();
  const mintTokenTransaction = useMintTransaction();
  const freezeTokenTransaction = useFreezeTransaction();
  const unfreezeTokenTransaction = useUnFreezeTransaction();
  const pauseTokenTransaction = usePauseTransaction();
  const unpauseTokenTransaction = useUnPauseTransaction();
  const wipeTokenTransaction = useWipeTransaction();
  const changeTokenOwnerTransaction = useChangeTokenOwnerTransaction();
  const setSpecialRolesTransaction = useSetSpecialRolesTransaction();
  const unsetSpecialRolesTransaction = useUnsetSpecialRolesTransaction();
  const updateControlChangesTransaction = useUpdateControlChangesTransaction();

  useEffect(() => {
    if (token.owner) {
      setOwner(token.owner);
    }
    setControlSettings({
      canBurn: !!token.canBurn,
      canChangeOwner: !!token.canChangeOwner,
      canFreeze: !!token.canFreeze,
      canMint: !!token.canMint,
      canPause: !!token.canPause,
      canWipe: !!token.canWipe,
      canAddSpecialRoles: !!token.canAddSpecialRoles,
    });
  }, [token]);

  const options = useMemo(() => {
    if (token.isNft() || token.isMetaEsdt()) {
      return [
        {
          value: 'ESDTRoleNFTCreate',
          label: 'ESDTRoleNFTCreate',
        },
        {
          value: 'ESDTRoleNFTBurn',
          label: 'ESDTRoleNFTBurn',
        },
        {
          value: 'ESDTRoleNFTUpdateAttributes',
          label: 'ESDTRoleNFTUpdateAttributes',
        },
        {
          value: 'ESDTRoleNFTAddURI',
          label: 'ESDTRoleNFTAddURI',
        },
        {
          value: 'ESDTTransferRole',
          label: 'ESDTTransferRole',
        },
      ];
    } else {
      return [
        {
          value: 'ESDTRoleLocalBurn',
          label: 'ESDTRoleLocalBurn',
        },
        {
          value: 'ESDTRoleLocalMint',
          label: 'ESDTRoleLocalMint',
        },
      ];
    }
  }, [token]);

  const selectedOption = useMemo(() => options.find(item => item.value === specialRole), [specialRole, options]);

  return (
    <SectionContainer>
      <Typography.H2>{'Edit Token Properties'}</Typography.H2>
      <CardContainer>
        <TokensSections>
          <TokenRowProperties>
            <Typography.Copy className={'name'}>{'Can Burn'}</Typography.Copy>
            <DefaultSwitch
              disabled={!token.canUpgrade}
              value={controlSettings.canBurn}
              onChange={(value: boolean) => setControlSettings(state => ({ ...state, canBurn: value }))}
            />
          </TokenRowProperties>
          <TokenRowProperties>
            <Typography.Copy className={'name'}>{'Can Change Owner'}</Typography.Copy>
            <DefaultSwitch
              disabled={!token.canUpgrade}
              value={controlSettings.canChangeOwner}
              onChange={(value: boolean) => setControlSettings(state => ({ ...state, canChangeOwner: value }))}
            />
          </TokenRowProperties>
          <TokenRowProperties>
            <Typography.Copy className={'name'}>{'Can Freeze'}</Typography.Copy>
            <DefaultSwitch
              disabled={!token.canUpgrade}
              value={controlSettings.canFreeze}
              onChange={(value: boolean) => setControlSettings(state => ({ ...state, canFreeze: value }))}
            />
          </TokenRowProperties>
          <TokenRowProperties>
            <Typography.Copy className={'name'}>{'Can Mint'}</Typography.Copy>
            <DefaultSwitch
              disabled={!token.canUpgrade}
              value={controlSettings.canMint}
              onChange={(value: boolean) => setControlSettings(state => ({ ...state, canMint: value }))}
            />
          </TokenRowProperties>
          <TokenRowProperties>
            <Typography.Copy className={'name'}>{'Can Pause'}</Typography.Copy>
            <DefaultSwitch
              disabled={!token.canUpgrade}
              value={controlSettings.canPause}
              onChange={(value: boolean) => setControlSettings(state => ({ ...state, canPause: value }))}
            />
          </TokenRowProperties>
          <TokenRowProperties>
            <Typography.Copy className={'name'}>{'Can Wipe'}</Typography.Copy>
            <DefaultSwitch
              disabled={!token.canUpgrade}
              value={controlSettings.canWipe}
              onChange={(value: boolean) => setControlSettings(state => ({ ...state, canWipe: value }))}
            />
          </TokenRowProperties>
          <TokenRowProperties>
            <Typography.Copy className={'name'}>{'Can add special roles'}</Typography.Copy>
            <DefaultSwitch
              disabled={!token.canUpgrade}
              value={controlSettings.canAddSpecialRoles}
              onChange={(value: boolean) => setControlSettings(state => ({ ...state, canAddSpecialRoles: value }))}
            />
          </TokenRowProperties>
        </TokensSections>
        <ButtonsContainer>
          <DefaultButton
            onClick={() => updateControlChangesTransaction(token.identifier, controlSettings)}
            disabled={!token.canUpgrade}
            text={'Save'}
          />
        </ButtonsContainer>
      </CardContainer>
      <TokensSections>
        <CardContainer>
          <TokenRow>
            <Typography.Copy size={'large'} className={'name'}>
              {'Minting'}
            </Typography.Copy>
            <DefaultInput
              disabled={!token.canMint}
              label={'Minting amount'}
              name={'name'}
              placeholder={'eg. 1000'}
              value={minted}
              onChange={e => setMinted(e.target.value)}
              type={'number'}
            />
          </TokenRow>
          <ButtonsContainer>
            <DefaultButton
              onClick={() => mintTokenTransaction(token.identifier, minted)}
              disabled={!token.canMint || !minted}
              text={'Save'}
            />
          </ButtonsContainer>
        </CardContainer>
        <CardContainer>
          <TokenRow>
            <Typography.Copy size={'large'} className={'name'}>
              {'Burning'}
            </Typography.Copy>
            <DefaultInput
              disabled={!token.canBurn}
              label={'Burning amount'}
              name={'name'}
              placeholder={'eg. 1000'}
              value={burned}
              onChange={e => setBurned(e.target.value)}
              type={'number'}
            />
          </TokenRow>
          <ButtonsContainer>
            <DefaultButton
              onClick={() => burnTokenTransaction(token.identifier, burned)}
              disabled={!token.canBurn || !burned}
              text={'Save'}
            />
          </ButtonsContainer>
        </CardContainer>
        <CardContainer>
          <TokenRow>
            <Typography.Copy size={'large'} className={'name'}>
              {'Pause'}
            </Typography.Copy>
            <DefaultSwitch disabled={!token.canPause} value={paused} onChange={setPaused} />
          </TokenRow>
          <ButtonsContainer>
            <DefaultButton
              onClick={() =>
                paused ? pauseTokenTransaction(token.identifier) : unpauseTokenTransaction(token.identifier)
              }
              disabled={!token.canPause}
              text={'Save'}
            />
          </ButtonsContainer>
        </CardContainer>
        <CardContainer>
          <TokenRow>
            <Typography.Copy size={'large'} className={'name'}>
              {'Freezing'}
            </Typography.Copy>
            <DefaultInput
              disabled={!token.canFreeze}
              label={'Account address'}
              name={'name'}
              placeholder={'Account address'}
              value={freezeAddress}
              onChange={e => setFreezeAddress(e.target.value)}
            />
          </TokenRow>
          <ButtonsContainer>
            <DefaultButton
              onClick={() => freezeTokenTransaction(token.identifier, freezeAddress)}
              disabled={!token.canFreeze || !freezeAddress}
              text={'Save'}
            />
          </ButtonsContainer>
        </CardContainer>
        <CardContainer>
          <TokenRow>
            <Typography.Copy size={'large'} className={'name'}>
              {'Unfreezing'}
            </Typography.Copy>
            <DefaultInput
              disabled={!token.canFreeze}
              label={'Account address'}
              name={'name'}
              placeholder={'Account address'}
              value={unfreezeAddress}
              onChange={e => setUnFreezeAddress(e.target.value)}
            />
          </TokenRow>
          <ButtonsContainer>
            <DefaultButton
              onClick={() => unfreezeTokenTransaction(token.identifier, unfreezeAddress)}
              disabled={!token.canFreeze || !unfreezeAddress}
              text={'Save'}
            />
          </ButtonsContainer>
        </CardContainer>

        <CardContainer>
          <TokenRow>
            <Typography.Copy size={'large'} className={'name'}>
              {'Wiping'}
            </Typography.Copy>
            <DefaultInput
              label={'Account address'}
              name={'name'}
              disabled={!token.canWipe}
              placeholder={'Account address'}
              value={wipeAddress}
              onChange={e => setWipeAddress(e.target.value)}
            />
          </TokenRow>
          <ButtonsContainer>
            <DefaultButton
              onClick={() => wipeTokenTransaction(token.identifier, wipeAddress)}
              disabled={!token.canWipe || !wipeAddress}
              text={'Save'}
            />
          </ButtonsContainer>
        </CardContainer>
        <CardContainer>
          <TokenRow>
            <Typography.Copy size={'large'} className={'name'}>
              {'Add Special Roles'}
            </Typography.Copy>
            <DefaultInput
              label={'Account address'}
              name={'name'}
              disabled={!token.canAddSpecialRoles}
              placeholder={'Account address'}
              value={specialRoleAddress}
              onChange={e => setSpecialRoleAddress(e.target.value)}
            />
            <DefaultSelect
              name={'roles'}
              options={options}
              isDisabled={!token.canAddSpecialRoles}
              value={selectedOption}
              label={'Special role'}
              placeholder={'Special role'}
              onChange={(option: any) => setSpecialRole(option.value)}
            />
          </TokenRow>
          <ButtonsContainer>
            <DefaultButton
              onClick={() => setSpecialRolesTransaction(token.identifier, specialRoleAddress, specialRole)}
              disabled={!token.canAddSpecialRoles || (!specialRoleAddress && !specialRole)}
              text={'Save'}
            />
          </ButtonsContainer>
        </CardContainer>
        <CardContainer>
          <TokenRow>
            <Typography.Copy size={'large'} className={'name'}>
              {'Change owner'}
            </Typography.Copy>
            <DefaultInput
              disabled={!token.canChangeOwner}
              label={'Account address'}
              name={'name'}
              placeholder={'Account address'}
              value={owner}
              onChange={e => setOwner(e.target.value)}
            />
          </TokenRow>
          <ButtonsContainer>
            <DefaultButton
              onClick={() => changeTokenOwnerTransaction(token.identifier, owner)}
              disabled={!token.canChangeOwner || !owner}
              text={'Save'}
            />
          </ButtonsContainer>
        </CardContainer>
      </TokensSections>
    </SectionContainer>
  );
};

export default TokenPropertiesSection;
