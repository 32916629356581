import React, { ReactNode } from 'react';
import styled from 'styled-components';
// import { useStepperContext } from '../StepperProvider';
import { StepperStep, StepperSteps } from './StepperSteps';

// const StyledStepperHeaderItem = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   flex: 1;
//
//   font-weight: 700;
//   font-size: 16px;
//
//   .step-counter {
//     position: relative;
//     z-index: 3;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 50px;
//     height: 50px;
//     border-radius: 50%;
//     background: ${props => props.theme.colors.highlight};
//     color: ${props => props.theme.colors.textOnSurface};
//     margin-bottom: 6px;
//   }
//
//   .step-name {
//     text-align: center;
//   }
//
//   &::after {
//     position: absolute;
//     content: '';
//     border-bottom: 1px solid ${props => props.theme.colors.highlight};
//     width: 100%;
//     top: 25px;
//     left: 50%;
//     z-index: 1;
//   }
//
//   &.completed {
//     .step-counter {
//       background-color: ${props => props.theme.colors.primary};
//       color: ${props => props.theme.colors.textOnPrimary};
//     }
//     &::before {
//       position: absolute;
//       content: '';
//       border-bottom: 1px solid ${props => props.theme.colors.primary};
//       width: 100%;
//       top: 25px;
//       left: -50%;
//       z-index: 2;
//     }
//   }
//
//   &:first-child {
//     &::before {
//       content: none;
//     }
//   }
//
//   &:last-child {
//     &::after {
//       content: none;
//     }
//   }
//
//   @media (max-width: 768px) {
//     font-size: 12px;
//   }
// `;

// const StyledStepperHeader = styled.div`
//   margin-top: auto;
//   display: flex;
//   justify-content: space-between;
// `;

const StyledStepperContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledStepperBody = styled.div`
  padding-top: 50px;
`;

const Stepper = ({ children }: { children: ReactNode }) => {
  // const { currentStep, steps } = useStepperContext();

  return (
    <StyledStepperContainer>
      {/*<StyledStepperHeader>*/}
      {/*  {steps.length*/}
      {/*    ? steps.map((step: any, index: number) => (*/}
      {/*        <StyledStepperHeaderItem key={step.id} className={currentStep >= index ? 'completed' : ''}>*/}
      {/*          <div className="step-counter">{index + 1}</div>*/}
      {/*          <div className="step-name">{step.name}</div>*/}
      {/*        </StyledStepperHeaderItem>*/}
      {/*      ))*/}
      {/*    : null}*/}
      {/*</StyledStepperHeader>*/}
      <StyledStepperBody>{children}</StyledStepperBody>
    </StyledStepperContainer>
  );
};

Stepper.Step = StepperStep;
Stepper.Steps = StepperSteps;

export default Stepper;
