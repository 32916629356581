import SerializableModel from '../../Core/models/serializable.model';
import FeedSettingsModel from './feed.settings.model';
import { KycApplicationType } from '../types/kyc.application.type';
import Role from '../enums/role.enum';

export default class PublicAccountModel extends SerializableModel {
  id: string;
  address: string;
  kycApplications: KycApplicationType[];
  role: Role;
  feedSettings: FeedSettingsModel;
  createdAt: Date;
  updatedAt: Date;

  constructor(params: any) {
    super();
    this.initializeModel(params);
  }
}
