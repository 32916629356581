import React from 'react';
import styles from './inline.links.section.module.scss';

import InlineLink from '../../../../../../components/screens/common/inline.link';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

export default function InlineLinksSection() {
  return (
    <div className={styles.sectionContainer}>
      <h3 className={`${sectionStyles.sectionSubtitle} ${styles.sectionTitle}`}>Explore our Solutions</h3>

      <div className={styles.itemsWrapper}>
        <InlineLink title={'Token Sales'} isComingSoon />
        <InlineLink title={'Staking Pools'} isComingSoon />
        <InlineLink title={'Airdrops'} isComingSoon />
        <InlineLink title={'Giveaways'} isComingSoon />
      </div>
    </div>
  );
}
