import React from 'react';

import JoinCommunitySection from '../../../../components/screens/common/join.community.section';
import pageStyles from '../../../../components/screens/common/page.blocks/page.module.scss';

/** Local Components **/
import HeroSection from './components/hero.section';
import ToolsSection from './components/tools.section';
import InlineLinksSection from './components/inline.links.section';

export default function PersonalHomepageScreen() {
  return (
    <div className={pageStyles.screenContainer}>
      <div className={pageStyles.heroSectionWrapper}>
        <HeroSection />
      </div>
      <div className={pageStyles.sectionsMaxWidthWrapper}>
        <InlineLinksSection />
        <ToolsSection />
        <JoinCommunitySection />
      </div>
    </div>
  );
}
