import React from 'react';
import styles from './tabs.module.scss';

type TTabsProps = {
  onChange: any;
  tabs: { name: string; value: string; isActive: boolean }[];
};

const Tabs = ({ tabs, onChange }: TTabsProps) => {
  return (
    <div className={styles.container}>
      {tabs.map((tab, key) => {
        return (
          <div className={styles.option} key={key} onClick={() => onChange(tab.value)} data-active={tab.isActive}>
            {tab.name}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
