import Environment from '../Environment';
import ApiClient from '../Http/ApiClient';

const API_ADDRESS = Environment.microservice.spaceApi;

export default class AuthService {
  private static api = new ApiClient();
  static signIn(walletAddress: string, signature: string | undefined, loginToken: string | undefined) {
    const data = {
      address: walletAddress,
      loginToken: loginToken,
      signature: signature,
    };
    return this.api
      .withBaseUrl(API_ADDRESS)
      .post(`/auth/sign-in`, data)
      .then(response => response.data)
      .then(response => {
        if (response.accessToken) {
          ApiClient.saveAccessToken(response.accessToken);
          ApiClient.saveRefreshToken(response.refreshToken);
        }
      });
  }

  static refreshToken(refreshToken: string) {
    return this.api
      .withBaseUrl(API_ADDRESS)
      .post(`/auth/refresh-token?refreshToken=${refreshToken}`, {})
      .then(response => response.data)
      .then(response => {
        ApiClient.saveAccessToken(response.accessToken);
        ApiClient.saveRefreshToken(response.refreshToken);
      });
  }
}
