import { useMemo } from 'react';
import { EsdtTokensList, EsdtTokensMap, TTokenListDecorator } from '../types';
import EsdtTokenModel from '../models/EsdtTokenModel';

export default function useTokenListDecorator(tokens: EsdtTokensList): TTokenListDecorator {
  return useMemo<TTokenListDecorator>(() => {
    const allTokensMap: EsdtTokensMap = {};
    const verifiedTokensList: EsdtTokensList = [];
    const verifiedTokensMap: EsdtTokensMap = {};

    tokens.forEach((item: EsdtTokenModel) => {
      allTokensMap[item.identifier] = item;

      if (item.isVerified()) {
        verifiedTokensList.push(item);
        verifiedTokensMap[item.identifier] = item;
      }
    });

    return {
      allTokensList: tokens,
      allTokensMap,
      verifiedTokensList,
      verifiedTokensMap,
    } as TTokenListDecorator;
  }, [tokens]);
}
