import React, { InputHTMLAttributes, FocusEvent, useRef, useState } from 'react';
import Typography from '../Typography';
import styles from './Input.module.scss';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  inputWidth?: number;
  label?: string;
  placeholder?: string;
  leftContent?: JSX.Element;
  rightContent?: JSX.Element;
  belowInputContent?: JSX.Element;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
};

const DefaultInput = ({
  placeholder,
  leftContent,
  rightContent,
  belowInputContent,
  error,
  label,
  ref,
  inputWidth,
  ...props
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [isFocused, setIsFocused] = useState(false);

  const focusHandler = (event: FocusEvent<HTMLInputElement, Element>) => {
    setIsFocused(true);
    props.onFocus && props.onFocus(event);
  };

  const blurHandler = (event: FocusEvent<HTMLInputElement, Element>) => {
    setIsFocused(false);
    props.onBlur && props.onBlur(event);
  };

  const containerClickHandler = () => {
    inputRef.current?.focus && inputRef.current.focus();
  };

  return (
    <div className={styles.wrapper} style={{ width: inputWidth }}>
      {label && <div>{label}</div>}
      <div
        className={styles.container}
        data-is-error={!!error}
        data-type={'input'}
        data-is-focused={isFocused}
        onClick={containerClickHandler}
      >
        <div className={styles.extra} onClick={e => e.stopPropagation()}>
          {leftContent}
        </div>
        <div className={styles.input}>
          <input
            ref={ref ? ref : inputRef}
            placeholder={placeholder}
            type={'text'}
            {...props}
            onFocus={focusHandler}
            onBlur={blurHandler}
          />

          {belowInputContent && <div>{belowInputContent}</div>}
        </div>
        <div className={styles.extra} onClick={e => e.stopPropagation()}>
          {rightContent}
        </div>
      </div>
      {error && (
        <div className={styles.error}>
          <Typography.Copy>{error}</Typography.Copy>
        </div>
      )}
    </div>
  );
};

export default DefaultInput;
