import React, { useContext, useEffect, useState } from 'react';

import styles from './PortfolioScreen.module.scss';
import blockStyles from '../../../components/screens/app/page.blocks/page.module.scss';

import AccountService from '../../../modules/Account/AccountService';
import { PortfolioType } from '../../../modules/Account/types/portfolio.type';
import { AccountContext } from '../../../modules/Account/AccountContextProvider';

import PortfolioStats from './components/PortfolioStats';
import PortfolioSection from './components/PortfolioSection';

const PortfolioScreen = () => {
  const { account } = useContext(AccountContext);
  const [portfolio, setPortfolio] = useState<PortfolioType | null>(null);

  useEffect(() => {
    if (account.address) {
      AccountService.getAccountPortfolio()
        .then(portfolio => {
          setPortfolio(portfolio);
        })
        .catch(() => {
          /* do nothing */
        });
    }
  }, [account.address]);

  return (
    <div className={blockStyles.screenContainer}>
      <div className={blockStyles.sectionsMaxWidthWrapper}>
        <div className={styles.titleContainer}>
          <h1>Portfolio</h1>
          <div className={styles.subTitle}>
            Track your <span className={'multiversX'}>MultiversX</span> portfolio
          </div>
        </div>

        <PortfolioStats portfolio={portfolio} />
        <PortfolioSection portfolio={portfolio} />
      </div>
    </div>
  );
};

export default PortfolioScreen;
