import Environment from '../../../../modules/Environment';

function stripText(text: string) {
  return text
    .replace('https://', '')
    .replace('http://', '')
    .replace('www.', '')
    .replace(/\/$/, '')
    .toLowerCase()
    .trim();
}

const searchArray = (array: string[], keyword: string) => {
  return array.find(item => stripText(item) === stripText(keyword)) !== undefined;
};

const officialChannels = {
  websites: [
    'https://esdt.space',
    'https://testnet.esdt.space',

    'https://esdt.market',
    'https://testnet.esdt.market',

    'https://launchpad.esdt.space',
    'https://launchpad-testnet.esdt.space',

    'https://drops.esdt.space',
    'https://drops-testnet.esdt.space',

    'https://staking.esdt.space',
    'https://staking-testnet.esdt.space',

    'https://charity.esdt.space',
    'https://charity-testnet.esdt.space',

    'https://dao.esdt.space',
    'https://dao-testnet.esdt.space',
  ],
  teamMembersTelegram: ['@abCryptoDev', '@cosminmitroi', '@florinhorne'],
  telegramAdmin: ['@Flakarika', '@Tufayel1', '@Nafis_Sadik', '@Agasta47', '@ved_here'],
  telegramChannels: ['@EsdtMarket', '@EsdtSpace', '@EsdtLaunchpad'],
  twitterPages: ['https://twitter.com/EsdtMarket', 'https://twitter.com/EsdtLaunchpad'],
  social: Object.values(Environment.socialLinks),
};

export default function verifyChannel(channel: string) {
  const isOfficialWebsite = searchArray(officialChannels.websites, channel);
  const isOfficialSocialPage = searchArray(officialChannels.social, channel);
  const isOfficialTwitterPage = searchArray(officialChannels.twitterPages, channel);
  const isOfficialTelegramAdmin = searchArray(officialChannels.telegramAdmin, channel);
  const isOfficialTelegramTeamMember = searchArray(officialChannels.teamMembersTelegram, channel);
  const isOfficialTelegramChannel = searchArray(officialChannels.telegramChannels, channel);

  let isOfficialHostname = false;

  try {
    const searchStringUrl = new URL(channel);
    const hostname = searchStringUrl.hostname;
    isOfficialHostname = searchArray(officialChannels.websites, hostname);
  } catch (e) {}

  let isOfficial = true;
  let text = `The <strong>${channel}</strong> you inquired is `;
  const textSuffix = ' of <strong>EsdtSpace</strong>.';
  const attention = ' Please pay attention to your assets and protect your personal information!';

  switch (true) {
    case isOfficialWebsite:
    case isOfficialHostname:
      text += 'an official website / app';
      text += textSuffix;
      break;
    case isOfficialTelegramTeamMember:
      text += 'an official team member';
      text += textSuffix;
      break;
    case isOfficialTelegramAdmin:
      text += 'an official telegram admin';
      text += textSuffix;
      break;
    case isOfficialTelegramChannel:
      text += 'an official telegram channel';
      text += textSuffix;
      break;
    case isOfficialSocialPage:
    case isOfficialTwitterPage:
      text += 'an official social channel';
      text += textSuffix;
      break;
    default:
      text += '<strong>NOT</strong> an official channel';
      text += textSuffix;
      text += attention;
      isOfficial = false;
      break;
  }

  return { isOfficial, text };
}
