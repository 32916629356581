import React from 'react';

import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import heroStyles from '../../../../../../components/screens/common/page.blocks/hero.module.scss';

/** Local Imports **/
import styles from './hero.section.module.scss';
import sectionImage from './assets/hero-illustration.png';

const HeroSection = () => {
  return (
    <div className={styles.componentWrapper}>
      <div className={styles.componentContent}>
        <div className={styles.limitedContent}>
          <div className={heroStyles.heroContentContainer}>
            <div>
              <h1 className={heroStyles.heroTitle}>About us</h1>
            </div>
            <div className={heroStyles.heroParagraphsWrapper}>
              <div className={heroStyles.heroParagraph}>
                ESDT SPACE is a blockchain solutions partner for MultiversX, committed to enhancing the building
                experience and helping teams seamlessly transition to blockchain technology. By providing expert
                guidance and cutting-edge solutions, we help teams reduce costs and streamline their development and
                blockchain infrastructure.
              </div>

              <div className={heroStyles.heroParagraph}>
                With a team of highly skilled professionals and a deep understanding of the blockchain industry, we are
                uniquely positioned to help businesses navigate the complex world of blockchain and achieve their goals.
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <DefaultButton type={'primary'} mode={'outline'} text={'MEET THE TEAM'} to={'#team'} />
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={sectionImage} />
      </div>
    </div>
  );
};

export default HeroSection;
