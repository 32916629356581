import React from 'react';
import styled from 'styled-components';

import styles from './../section.module.scss';

import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

import { CREATE_PROJECT_ROUTE } from '../../../../../../modules/Navigation/NavigationConstants';

import projectViewImage from './../../assets/project-view-image.png';

const IllustrationContainer = styled.div`
  flex: 1;
  height: 854px;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url(${projectViewImage});
  position: relative;

  :before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(116.74deg, #25008c 0%, rgba(0, 117, 255, 0.8) 100%);
    opacity: 0.25;
    filter: blur(200px);
  }

  @media all and ${({ theme: { device } }) => device.md} {
    width: 100%;
    height: auto;
    min-height: 350px;
    background-position: center;
  }
`;

export default function ManageProjectSection() {
  const paragraphs = ['Appoint managers to act on your behalf in our ecosystem and manage your tokens from our app.'];

  return (
    <div className={styles.sectionContainer}>
      <IllustrationContainer />

      <div className={styles.contentContainer}>
        <h2 className={sectionStyles.sectionTitle}>Manage your project</h2>

        <div className={sectionStyles.sectionParagraphsWrapper}>
          {paragraphs.map((item, index) => (
            <div className={sectionStyles.sectionParagraph} key={index}>
              {item}
            </div>
          ))}
        </div>

        <div className={styles.buttonsContainer}>
          <DefaultButton mode={'outline'} type={'primary'} text={'Register Project'} to={CREATE_PROJECT_ROUTE} />
        </div>
      </div>
    </div>
  );
}
