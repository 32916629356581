export const AUTH_ROUTE = '/app/auth';

export const HOMEPAGE_ROUTE = '/';
export const PROJECTS_ROUTE = '/app/projects';
export const PROJECTS_LATEST_ROUTE = '/app/projects/latest';
export const MY_PROJECTS_ROUTE = '/app/projects/me';
export const CREATE_PROJECT_ROUTE = '/app/projects/create';

export const ABOUT_ROUTE = '/about';
export const SOLUTIONS_ROUTE = '/solutions';
export const WHITEPAPER_ROUTE = '/whitepaper';
export const NOT_FOUND_ROUTE = '/404';

export const LANDING_PAGE_ROUTES = [HOMEPAGE_ROUTE, NOT_FOUND_ROUTE];
