import React from 'react';
import styles from './PortfolioStats.module.scss';
import { PortfolioType } from '../../../../../modules/Account/types/portfolio.type';
import { EgldToken } from '../../../../../modules/Tokens/CustomTokens';

type Props = {
  portfolio: PortfolioType | null;
};

type PortfolioItemProps = {
  title: string;
  usdValue?: string | number;
  egldValue?: string | number;
};

function NetWorth(props: Props) {
  const { portfolio } = props;
  const netWorthEgld = portfolio?.netWorthEGLD ? portfolio.netWorthEGLD.toFixed(2) : 0;
  const netWorthUsd = portfolio?.netWorthUSD ? portfolio.netWorthUSD.toFixed(2) : 0;

  return (
    <div className={styles.netWorthContainer}>
      <div className={styles.title}>Net Worth</div>
      <div className={styles.egldValue}>
        {netWorthEgld} {EgldToken.ticker}
      </div>
      <div className={styles.usdValue}>${netWorthUsd}</div>
    </div>
  );
}

function PortfolioItem(props: PortfolioItemProps) {
  const { title, egldValue = 0, usdValue = 0 } = props;
  return (
    <div className={styles.item}>
      <div className={styles.title}>{title}</div>
      <div>
        <div className={styles.egldValue}>
          {egldValue} {EgldToken.ticker}
        </div>
        <div className={styles.usdValue}>${usdValue} USD</div>
      </div>
    </div>
  );
}

export default function PortfolioStats(props: Props) {
  const { portfolio } = props;
  const egldLiquidityValue = portfolio?.liquidityTotalBalanceEGLD ? portfolio.liquidityTotalBalanceEGLD.toFixed(2) : 0;
  const usdLiquidityValue = portfolio?.liquidityTotalBalanceUSD ? portfolio.liquidityTotalBalanceUSD.toFixed(2) : 0;
  const egldFarmsValue = portfolio?.farmsTotalBalanceEGLD ? portfolio.farmsTotalBalanceEGLD.toFixed(2) : 0;
  const usdFarmsValue = portfolio?.farmsTotalBalanceUSD ? portfolio.farmsTotalBalanceUSD.toFixed(2) : 0;

  return (
    <div className={styles.container}>
      <NetWorth portfolio={portfolio} />
      <div className={styles.itemsWrapper}>
        <PortfolioItem title={'Liquidity'} egldValue={egldLiquidityValue} usdValue={usdLiquidityValue} />

        <PortfolioItem title={'Farms'} egldValue={egldFarmsValue} usdValue={usdFarmsValue} />

        <PortfolioItem title={'Metabonding'} egldValue={0} usdValue={0} />
        <PortfolioItem title={'Metastaking'} egldValue={0} usdValue={0} />
      </div>
    </div>
  );
}
