import React from 'react';
import styled from 'styled-components';

import styles from '../section.module.scss';

import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

import { CREATE_PROJECT_ROUTE } from '../../../../../../modules/Navigation/NavigationConstants';

import projectsImage from './../../assets/latest-projects.png';

const IllustrationContainer = styled.div`
  flex: 1;
  height: 468px;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url(${projectsImage});

  @media all and ${({ theme: { device } }) => device.md} {
    width: 100%;
    height: auto;
    max-width: 90%;
    min-height: 300px;
    background-position: center;
  }
`;

export default function RegisterProjectSection() {
  const paragraphs = ['Join the projects already registered in our ecosystem and start gaining more exposure.'];

  return (
    <div className={styles.sectionContainer} data-reversed={true}>
      <div className={styles.contentContainer}>
        <h2 className={sectionStyles.sectionTitle}>Register your project</h2>

        <div className={sectionStyles.sectionParagraphsWrapper}>
          {paragraphs.map((item, index) => (
            <div className={sectionStyles.sectionParagraph} key={index}>
              {item}
            </div>
          ))}
        </div>

        <div className={styles.buttonsContainer}>
          <DefaultButton mode={'outline'} type={'primary'} text={'Register Project'} to={CREATE_PROJECT_ROUTE} />
        </div>
      </div>

      <IllustrationContainer />
    </div>
  );
}
