import React from 'react';
import { OptionProps } from 'react-select';
import Typography from '../../Typography';
import styles from './OptionComponent.module.scss';

export const OptionComponent = (props: OptionProps) => {
  const {
    data: { label },
    innerProps,
  } = props as {
    data: { label: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    innerProps: any;
  };

  return (
    <div {...innerProps} className={styles.optionContainer}>
      <div className={styles.tokenInfoContainer}>
        <Typography.Copy size={'tiny'}>{String(label)}</Typography.Copy>
      </div>
    </div>
  );
};
