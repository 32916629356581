import marketIcon from './../../assets/icons/Name=Market.png';
import launchpadIcon from './../../assets/icons/Name=Launchpad.png';
import stakingIcon from './../../assets/icons/Name=Staking.png';
import tokenDistributionIcon from './../../assets/icons/Name=Drops.png';
import charityIcon from './../../assets/icons/Name=Charity.png';
import daoIcon from './../../assets/icons/Name=DAO.png';
import developerToolsIcon from './../../assets/icons/Name=DeveloperTools.png';

const items = [
  {
    icon: marketIcon,
    title: 'P2P ESDT Market',
    paragraphs: [
      'Secure & fully decentralized peer-to-peer marketplace for trading any ESDT token on <strong className="multiversX">MultiversX.</strong>',
    ],
    link: 'https://esdt.market',
  },
  {
    icon: launchpadIcon,
    title: 'Launchpad',
    paragraphs: [
      'Get early access to the ideas of tomorrow or create and mange your own token sales & vesting schedule.',
    ],
    link: 'https://launchpad.esdt.space',
  },
  {
    icon: stakingIcon,
    title: 'Staking',
    paragraphs: [
      'Create customized staking pools to offer your community an easy way to stake your token and earn rewards.',
    ],
    link: undefined,
    disabled: true,
    linkText: 'Coming soon',
  },
  {
    icon: tokenDistributionIcon,
    title: 'Airdrops & Giveaways',
    paragraphs: ['Create pools and learn & earn programs to distribute any ESDT token to your community.'],
    link: undefined,
    disabled: true,
  },
  {
    icon: charityIcon,
    title: 'Donations & Charity',
    paragraphs: ['Let others know you care!', 'Create and contribute to various charity events and causes.'],
    disabled: true,
    link: undefined,
    linkText: 'Coming soon',
  },
  {
    icon: daoIcon,
    title: 'DAO',
    paragraphs: [
      'A revolutionary way to coordinate and run your projects or organizations using smart contracts and blockchain technology to provide them with transparency, immutability, autonomy, and security.',
    ],
    disabled: true,
    link: undefined,
    linkText: 'Coming soon',
  },
  {
    icon: developerToolsIcon,
    title: 'Developer Tools',
    paragraphs: [
      'White-label embeddable widgets for all the tools so that your users can benefit from our solutions without having to interact with our platforms.',
    ],
    disabled: true,
    link: undefined,
    linkText: 'Coming soon',
  },
];

export default items;
