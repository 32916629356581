import React, { useCallback, useContext, useMemo, useState } from 'react';
import styles from './PortfolioSection.module.scss';

import Tabs from './../../../../../components/Atomic/Tabs';
import DefaultTable from './../../../../../components/Atomic/Table/Table';
import Typography from './../../../../../components/Atomic/Typography';
import shortLogo from './../../../../../components/Atomic/Branding/AppIcon/assets/short_logo.svg';

import { EsdtTokensContext } from './../../../../../modules/Tokens/Esdt/EsdtTokensContextProvider';
import { PortfolioType, TokenAmountType } from './../../../../../modules/Account/types/portfolio.type';

type Props = {
  portfolio: PortfolioType | null;
};

type TabTableProps = {
  tokens: TokenAmountType[];
};

const tableHeaders = [
  { name: 'Token', hiddenInMobile: true },
  { name: 'Portfolio %' },
  { name: 'Price' },
  { name: 'Balance' },
];

function useTokenLogo() {
  const { allTokensMap } = useContext(EsdtTokensContext);

  return useCallback(
    (tokenIdentifier: string): string | undefined => {
      const token = allTokensMap[tokenIdentifier];
      if (token && token.hasAssetImage() && token.getAssetImageUrl()) {
        return token.getAssetImageUrl() ?? undefined;
      }

      return shortLogo;
    },
    [allTokensMap],
  );
}

function TabTable(props: TabTableProps) {
  const { tokens } = props;
  const getTokenLogo = useTokenLogo();
  const { allTokensMap } = useContext(EsdtTokensContext);

  const rows = useMemo(
    () =>
      tokens.map((token: TokenAmountType) => ({
        cells: [
          <div className={styles.tokenWrapper} key={1}>
            <img
              width={90}
              height={90}
              alt={`${token.tokenIdentifier} Logo`}
              src={getTokenLogo(token.tokenIdentifier)}
            />
            <div>
              <Typography.H3>{allTokensMap[token.tokenIdentifier]?.name}</Typography.H3>
              <Typography.Copy size={'large'}>{token.tokenIdentifier}</Typography.Copy>
            </div>
          </div>,
          <Typography.H3 key={2}>{token.portfolioPercent.toFixed(2) + '%'}</Typography.H3>,
          <Typography.H3 key={3}>{'$' + token.tokenPrice.toFixed(2)}</Typography.H3>,
          <React.Fragment key={4}>
            <Typography.H3>{'$' + token.balanceUSD.toFixed(2)}</Typography.H3>
            <Typography.Copy size={'large'}>
              {token.balance} {token.tokenIdentifier}
            </Typography.Copy>
          </React.Fragment>,
        ],
      })),
    [tokens, getTokenLogo, allTokensMap],
  );

  return <DefaultTable tableHeaders={tableHeaders} tableRows={rows} />;
}

export default function PortfolioSection(props: Props) {
  const { portfolio } = props;
  const [activeTab, setActiveTab] = useState('wallet');

  const tabs = useMemo(
    () => [
      {
        name: 'Wallet',
        value: 'wallet',
        isActive: activeTab === 'wallet',
      },
      {
        name: 'Farms',
        value: 'farms',
        isActive: activeTab === 'farms',
      },
      {
        name: 'Liquidity',
        value: 'liquidity',
        isActive: activeTab === 'liquidity',
      },
    ],
    [activeTab],
  );

  return (
    <div className={styles.container}>
      <Tabs onChange={setActiveTab} tabs={tabs} />
      {activeTab === 'wallet' && <TabTable tokens={portfolio?.wallet?.esdt || []} />}
      {activeTab === 'farms' && <TabTable tokens={portfolio?.farms || []} />}
      {activeTab === 'liquidity' && <TabTable tokens={portfolio?.liquidity || []} />}
    </div>
  );
}
