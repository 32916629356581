import React from 'react';

import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import heroStyles from '../../../../../../components/screens/common/page.blocks/hero.module.scss';

/** Local Imports **/
import styles from './hero.section.module.scss';
import sectionImage from './../../assets/hero-illustration.png';

const HeroSection = () => {
  return (
    <div className={styles.componentWrapper}>
      <div className={styles.componentContent}>
        <div className={styles.limitedContent}>
          <div className={heroStyles.heroContentContainer}>
            <div>
              <h1 className={heroStyles.heroTitle}>ESDT SPACE</h1>
              <h2 className={heroStyles.heroSubtitle}>FOR PERSONAL USE</h2>
            </div>
            <div className={heroStyles.heroParagraphsWrapper}>
              <div className={heroStyles.heroParagraph}>
                Your <strong className={'multiversX'}>MultiversX</strong> journey has just become smoother!
              </div>
              <div className={heroStyles.heroParagraph}>
                Choose from a wide range of fully personalized solutions to enhance your blockchain experience. From
                personal feed, portfolio tracking, to giveaways, airdrops and token sales access &#8211; our ecosystem
                has got you covered!
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <DefaultButton type={'primary'} mode={'outline'} text={'EXPLORE FEATURES'} to={'#features'} />
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={sectionImage} />
      </div>
    </div>
  );
};

export default HeroSection;
