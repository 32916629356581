import styled from 'styled-components';

const Typography = styled.div``;

const Heading = styled(Typography)`
  font-weight: 700;
  line-height: 1.2;
  font-weight: 400;
  color: #fff;
`;

const H1 = styled(Heading)`
  font-size: 4.5em;

  @media all and ${({ theme: { device } }) => device.sm} {
    font-size: 3.2em;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    font-size: 2.5em;
  }
`;

const H2 = styled(Heading)`
  font-size: 3.4em;

  @media all and ${({ theme: { device } }) => device.sm} {
    font-size: 2.4em;
  }
`;

const H3 = styled(Heading)`
  font-size: 2.2em;

  @media all and ${({ theme: { device } }) => device.sm} {
    font-size: 1.8em;
  }
`;

const H4 = styled(Heading)`
  font-size: 1.5em;

  @media all and ${({ theme: { device } }) => device.sm} {
    //font-size: 20px;
  }
`;

const H5 = styled(Heading)``;
const H6 = styled(Heading)``;

const Copy = styled(Typography)<{ size?: 'normal' | 'large' | 'small' | 'tiny' }>`
  font-size: 1em;
  line-height: 1.5;

  ${({ size }) => size === 'large' && `font-size: 1.6em;`}
  ${({ size }) => size === 'small' && `font-size: 0.8em;`}
  ${({ size }) => size === 'tiny' && `font-size: 0.6em;`}

  @media all and ${({ theme: { device } }) => device.sm} {
    ${({ size }) => size === 'large' && `font-size: 1.4em;`}
    ${({ size }) => size === 'small' && ``}
    ${({ size }) => size === 'tiny' && ``}
  }
`;

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Copy,
};
