import React, { useContext } from 'react';

/** Local Imports **/
import TitleParagraphsSection from './TitleParagraphsSection';

import playButtonImage from './assets/play.png';
import { ThemeContext } from '../../../../../modules/Theme/ThemeProvider';
import ProjectModel from '../../../../../modules/Project/ProjectModel';

import styles from './HeroSection.module.scss';

const sectionImageVariants = {
  light: playButtonImage,
  dark: playButtonImage,
};

const HeroSection = ({ project }: { project: ProjectModel }) => {
  const { mode } = useContext(ThemeContext);

  const sectionImage = mode === 'dark' ? sectionImageVariants.dark : sectionImageVariants.light;
  const accessArray = [...project.managers];
  accessArray.push(project.ownerAddress);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.image} style={{ backgroundImage: sectionImage }}>
          <div className={styles.content}>
            <TitleParagraphsSection
              title={'Project Overview'}
              paragraphs={[project.longDescription]}
              accessWallets={accessArray}
              id={project.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
