import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Typography from '../../../components/Atomic/Typography';
import MaxWidthContainer from '../../../components/Layout/Containers/MaxWidthContainer';

import AccountService from '../../../modules/Account/AccountService';
import { PortfolioType } from '../../../modules/Account/types/portfolio.type';
import FeedSettingsModel from '../../../modules/Account/models/feed.settings.model';
import { KycApplicationType } from '../../../modules/Account/types/kyc.application.type';

import FeedSettings from './components/feed.settings';
import ProfileStats from './components/profile.stats';
import ProfileDetails from './components/profile.details';
import KycApplications from './components/kyc.applications';

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xxl}px;
  --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;
    gap: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const TitleAndParagraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xs}px;
  align-items: flex-start;

  ${Typography.H1} {
    font-weight: 700;
  }
`;

function useAccountPortfolio() {
  const [portfolio, setPortfolio] = useState<PortfolioType | null>(null);

  useEffect(() => {
    AccountService.getAccountPortfolio()
      .then(portfolio => {
        setPortfolio(portfolio);
      })
      .catch(() => {});
  }, []);

  return portfolio;
}

function useFeedSettingsAndKyc() {
  const [settings, setSettings] = useState(new FeedSettingsModel({}));
  const [kycApplications, setKycApplications] = useState<KycApplicationType[]>([]);

  useEffect(() => {
    AccountService.getOwnAccount()
      .then(account => {
        setSettings(account.feedSettings);
        setKycApplications(account.kycApplications);
      })
      .catch(() => {});
  }, []);

  return { kycApplications, settings, setSettings };
}

const ProfileScreen = () => {
  const portfolio = useAccountPortfolio();
  const { settings, setSettings, kycApplications } = useFeedSettingsAndKyc();

  const updateFeedSettings = (key: string, value: unknown) => {
    const settingsUpdated = Object.assign(new FeedSettingsModel({}), settings, {
      [key]: value,
    });

    setSettings(settingsUpdated);

    AccountService.updateFeedSettings(settingsUpdated).then(response => {
      toast.success('Feed settings updated succesfully');
    });
  };

  return (
    <ScreenContainer>
      <ProfileDetails />

      <ContentContainer>
        <ProfileStats portfolio={portfolio} />
        <FeedSettings settings={settings} changeHandler={updateFeedSettings} />
        <KycApplications applications={kycApplications} />
      </ContentContainer>
    </ScreenContainer>
  );
};

export default ProfileScreen;
