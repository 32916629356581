import React from 'react';

import styles from './TableRenderer.module.scss';

const TableRenderer = ({ content }: { content: any }) => {
  const tableHeaders = content.tableHeaders || [];
  const tableRows = content.tableRows || [];

  return (
    <div className={styles.table}>
      {tableHeaders.length > 0 && (
        <div className={styles.row}>
          {tableHeaders.map((header: any, index: number) => (
            <div className={styles.headerCell} key={`header-${index}`}>
              {header}
            </div>
          ))}
        </div>
      )}

      {tableRows.map((row: any, rowIndex: number) => (
        <div className={styles.row} key={`row-${rowIndex}`}>
          {row.map((rowCell: any, index: number) => (
            <div className={styles.cell} key={`row-${rowIndex}-cell-${index}`}>
              {rowCell}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TableRenderer;
