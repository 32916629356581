import React from 'react';
import { Rating } from 'react-simple-star-rating';
import styles from './Rating.module.scss';

const DefaultRating = ({
  totalFilledStars,
  onChangeRating,
  readonly,
  ...props
}: {
  totalFilledStars: number;
  onChangeRating?: (newRating: number) => void;
  readonly: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}) => {
  const changeRating = (newRating: number) => {
    onChangeRating && onChangeRating(newRating);
  };

  return (
    <div className={styles.container}>
      <Rating
        initialValue={totalFilledStars}
        onClick={changeRating}
        fillColor={'#D9D9D9'}
        emptyColor={'rgba(217, 217, 217, 0.3)'}
        iconsCount={5}
        readonly={readonly}
        allowHover={true}
        {...props}
      />
    </div>
  );
};

export default DefaultRating;
