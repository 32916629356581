import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '../../../components/Atomic/Typography';
import MaxWidthContainer from '../../../components/Layout/Containers/MaxWidthContainer';
import { useParams } from 'react-router-dom';
import EsdtTokenModel from '../../../modules/Tokens/models/EsdtTokenModel';
import HeroSection from './components/HeroSection/HeroSection';
import TokenPropertiesSection from './components/TokenPropertiesSection';
import TokensService from '../../../modules/Tokens/TokensService';
import { EgldToken } from '../../../modules/Tokens/CustomTokens';

const ScreenContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xxl}px;
  --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;
    gap: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);

  ${Typography.H1} {
    font-weight: 700;
  }
`;

const ViewToken = () => {
  const [token, setToken] = useState<EsdtTokenModel>(new EsdtTokenModel({}));
  const { tokenIdentifier } = useParams();

  useEffect(() => {
    if (tokenIdentifier) {
      if (tokenIdentifier === EgldToken.identifier) {
        setToken(EgldToken);
      } else {
        TokensService.getEsdtTokenByIdentifier(tokenIdentifier).then(response => {
          setToken(response);
        });
      }
    }
  }, [tokenIdentifier]);

  return (
    <ScreenContainer>
      <ContentContainer>
        <Typography.H1>{token.name}</Typography.H1>
        <HeroSection token={token} />
        <TokenPropertiesSection token={token} />
      </ContentContainer>
    </ScreenContainer>
  );
};

export default ViewToken;
