import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '../../../components/Atomic/Typography';
import MaxWidthContainer from '../../../components/Layout/Containers/MaxWidthContainer';
import ProjectModel from '../../../modules/Project/ProjectModel';
import { useParams } from 'react-router-dom';
import ProjectRepository from '../../../modules/Project/ProjectRepository';
import HeroSection from './components/HeroSection/HeroSection';
import ProjectTokens from './components/ProjectTokens';
import ManagersSection from './components/ManagersSection';
import SmartContractsSection from './components/SmartContractsSection';
import TokenSalesSection from './components/TokenSalesSection';

const ScreenContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xxl}px;
  --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;
    gap: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const ProjectTokensAndManagers = styled.div`
  --number-of-columns: 2;

  @media all and (min-width: 1800px) {
    --number-of-columns: 2;
  }

  @media all and ${({ theme: { device } }) => device.md} {
    --number-of-columns: 2;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    --number-of-columns: 1;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    --number-of-columns: 1;
  }

  display: grid;
  grid-template-columns: repeat(var(--number-of-columns), 1fr);
  row-gap: ${props => props.theme.sizes.xl}px;
  column-gap: ${props => props.theme.sizes.xl}px;
`;

const ViewProject = () => {
  const [project, setProject] = useState<ProjectModel>(new ProjectModel());
  const { projectId } = useParams();

  useEffect(() => {
    if (projectId) {
      ProjectRepository.getProject(projectId).then(response => {
        setProject(response);
      });
    }
  }, [projectId]);

  return (
    <ScreenContainer>
      <ContentContainer>
        <Typography.H1>{project.name}</Typography.H1>
        <HeroSection project={project} />
        <ProjectTokensAndManagers>
          <ProjectTokens project={project} />
          <ManagersSection project={project} />
        </ProjectTokensAndManagers>
        {project.smartContracts.length > 0 && <SmartContractsSection project={project} />}
        <TokenSalesSection project={project} />
      </ContentContainer>
    </ScreenContainer>
  );
};

export default ViewProject;
