import React from 'react';

import styles from './join.community.section.module.scss';
import sectionStyles from '../page.blocks/section.module.scss';

import cometIllustration from './assets/comet-illustration.svg';

import Icon from '../../../Atomic/Icon';

import Environment from '../../../../modules/Environment';

const links = [
  { iconName: 'discord', url: Environment.socialLinks.discordUrl },
  { iconName: 'telegram', url: Environment.socialLinks.telegramUrl },
  { iconName: 'twitter', url: Environment.socialLinks.twitterUrl },
];

export default function JoinCommunitySection() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={sectionStyles.sectionTitle}>Join our community</h2>
        <div className={sectionStyles.sectionParagraph}>
          Be part of the change discover the experience from the inside.
        </div>

        <div className={styles.linksWrapper}>
          {links.map(({ url, iconName }, index) => (
            <a className={styles.link} key={index} href={url}>
              <Icon name={`fa-brands fa-${iconName}`} />
            </a>
          ))}
        </div>
      </div>
      <div className={styles.illustrationContainer} style={{ backgroundImage: `url(${cometIllustration})` }} />
    </div>
  );
}
