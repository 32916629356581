import React from 'react';

import blockStyles from '../../../../components/screens/common/page.blocks/page.module.scss';

import HeroSection from './components/hero.section';
import ContactSection from './components/contact.section';
import PartnersList from './components/partners.list.section';

const PartnersScreen = () => {
  return (
    <div className={blockStyles.screenContainer}>
      <div className={blockStyles.heroSectionWrapper}>
        <HeroSection />
      </div>
      <div className={blockStyles.sectionsMaxWidthWrapper}>
        <PartnersList />
        <ContactSection />
      </div>
    </div>
  );
};

export default PartnersScreen;
