import React from 'react';

import styles from './HeroSection.module.scss';

/** Local Imports **/
import TitleParagraphsSection from './TitleParagraphsSection';

import sectionImageDark from './assets/play.png';
import EsdtTokenModel from '../../../../../modules/Tokens/models/EsdtTokenModel';

const HeroSection = ({ token }: { token: EsdtTokenModel }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.image} style={{ backgroundImage: sectionImageDark }}>
          <div className={styles.content}>
            <TitleParagraphsSection
              title={'Token Overview'}
              paragraphs={[
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
              ]}
              token={token}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
