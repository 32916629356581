import React from 'react';
import { Link } from 'react-router-dom';
import styles from './solution.item.module.scss';

import Typography from '../../../../../../../components/Atomic/Typography';
import { ReactComponent as Background } from './../../../assets/solution-background.svg';

type Props = {
  type: 'personal' | 'business';
  title: string;
  features: string[];
  link: string;
  linkDisabled?: boolean;
};

export default function SolutionItem(props: Props) {
  const { type, title, features, link, linkDisabled } = props;

  return (
    <div data-type={type} className={`${styles.container}`}>
      <Background className={styles.background} />
      <Typography.H4 className={styles.title}>{title}</Typography.H4>
      <div className={styles.featuresWrapper}>
        {features.map((feature, index) => (
          <Typography.Copy key={index}>{feature}</Typography.Copy>
        ))}
      </div>

      <Link data-disabled={linkDisabled} className={styles.link} to={link}>
        Learn More
        {linkDisabled === true && ' (coming soon)'}
      </Link>
    </div>
  );
}
