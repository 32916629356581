import React from 'react';

import styles from './tools.section.module.scss';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

import items from './tools.data';
import ToolItem from './tool.item';

export default function ToolsSection() {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.titleWrapper}>
        <h2 className={sectionStyles.sectionTitle}>Powerful tools for your business</h2>
        <div className={sectionStyles.sectionParagraph}>
          Benefit from a wide range of powerful tools to help you cover every possible aspect of blockchain services.
        </div>
      </div>

      <div className={styles.itemsWrapper}>
        {items.map((item, index) => (
          <ToolItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}
