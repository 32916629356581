import { useCallback } from 'react';
import {
  ContractFunction,
  BytesValue,
  Interaction,
  SmartContract,
  Address,
  AddressValue,
} from '@elrondnetwork/erdjs/out';
import { getAccount, getLatestNonce } from '@elrondnetwork/dapp-core/utils';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import sendSmartContractMultipleTransactions from '../useSmartContractMultipleTransactions';
import Environment from '../../../Environment';

export const useWipeTransaction = () => {
  const { address } = useGetAccountInfo();
  const GAS_LIMIT = 60000000;
  const getTransactionData = (tokenIdentifier: string, wallet: string) => {
    const transactionPayload = [];
    transactionPayload.push(BytesValue.fromUTF8(tokenIdentifier));
    transactionPayload.push(new AddressValue(new Address(wallet)));

    return {
      transactionPayload: transactionPayload,
    };
  };

  return useCallback(
    async (tokenIdentifier: string, wallet: string) => {
      const account = await getAccount(address);
      const nonce = getLatestNonce(account);
      const dataToSend = getTransactionData(tokenIdentifier, wallet);

      const contract = new SmartContract({
        address: new Address(Environment.elrond.smartContractAddressTokensManagement),
      });
      const freezeSmartContractFunction = new ContractFunction('freeze');
      const freezeInteraction = new Interaction(contract, freezeSmartContractFunction, dataToSend.transactionPayload);
      const wipeSmartContractFunction = new ContractFunction('wipe');
      const wipeInteraction = new Interaction(contract, wipeSmartContractFunction, dataToSend.transactionPayload);
      const unFreezeSmartContractFunction = new ContractFunction('unFreeze');
      const unFreezeInteraction = new Interaction(
        contract,
        unFreezeSmartContractFunction,
        dataToSend.transactionPayload,
      );

      const interactions = [freezeInteraction, wipeInteraction, unFreezeInteraction];
      return sendSmartContractMultipleTransactions(interactions, nonce, {}, GAS_LIMIT);
    },
    [address],
  );
};
