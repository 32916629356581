import React from 'react';
import styles from './inline.links.section.module.scss';

import InlineLink from '../../../../../../components/screens/common/inline.link';

export default function InlineLinksSection() {
  return (
    <div className={styles.sectionContainer}>
      <h3 className={`${styles.sectionTitle}`}>Explore our Solutions</h3>

      <div className={styles.itemsWrapper}>
        <InlineLink title={'Projects Explorer'} isComingSoon />
        <InlineLink title={'Personal Feed'} isComingSoon />
        <InlineLink title={'Portfolio Tracker'} isComingSoon />
        <InlineLink title={'Giveaways'} isComingSoon />
      </div>
    </div>
  );
}
