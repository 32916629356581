import supercityIcon from './assets/superciety-logo.png';
import plataIcon from './assets/plata-logo.png';
import landboardIcon from './assets/landboard-logo.png';

import onfidoLogo from './assets/onfido-logo.png';
import newLegalWayLogo from './assets/new-legal-way-logo.png';
import { TPartnerItem } from './partner.item/partner.item';

const partners: TPartnerItem[] = [
  {
    logo: onfidoLogo,
    title: 'Onfido',
    description:
      'Onfido is recognized as a global leader in AI for identity verification and authentication. The company makes it easy for people to access services by digitally verifying them using its Real Identity Platform.',
    social: {
      facebook: 'https://www.facebook.com/Onfido',
      twitter: 'https://twitter.com/Onfido',
      linkedin: 'https://www.linkedin.com/company/onfido',
    },
    website: 'https://onfido.com',
  },
  {
    logo: newLegalWayLogo,
    title: 'New Legal Way',
    description:
      'With more than 15 years of legal experience, New Legal Way helps you to carry out crypto and technology projects in compliance with the legal provisions.',
    social: {
      facebook: 'https://www.facebook.com/NewLegalWay',
      twitter: 'https://twitter.com/NewLegalWay1',
      linkedin: 'https://www.linkedin.com/company/newlegalway',
    },
    website: 'https://www.newlegalway.com',
  },
  {
    logo: supercityIcon,
    title: 'Superciety',
    description:
      'Superciety helps communities & organizations become highly autonomous, transparent, and vastly decentralized.',
    social: {
      discord: 'http://superciety.chat',
      twitter: 'https://twitter.com/SupercietyHQ',
      telegram: 'https://t.me/SupercietyCommunity',
      github: 'https://github.com/superciety',
    },
    website: 'https://superciety.com',
  },
  {
    logo: plataIcon,
    title: 'Plata Network',
    description: 'Racing themed project built on the Elrond blockchain.',
    social: {
      twitter: 'https://twitter.com/platanetwork',
      telegram: 'https://t.me/plataNetwork',
    },
    website: 'https://plata.network',
  },
  {
    logo: landboardIcon,
    title: 'Landboard',
    description:
      'Landboard is the first Metaverse ecosystem built on the Elrond blockchain, aiming to bridge the gap between the real world and the Metaverse.',
    social: {
      discord: 'https://discord.com/invite/Rff4bFrCSD',
      twitter: 'https://twitter.com/landboard_io',
      telegram: 'https://t.me/landboardio',
      instagram: 'https://www.instagram.com/landboard.io',
    },
    website: 'https://www.landboard.io',
  },
];

export default partners;
