import React from 'react';

import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import heroStyles from '../../../../../../components/screens/common/page.blocks/hero.module.scss';

/** Local Imports **/
import styles from './hero.section.module.scss';
import sectionImage from './../../assets/hero-illustration.png';

const HeroSection = () => {
  return (
    <div className={styles.componentWrapper}>
      <div className={styles.componentContent}>
        <div className={styles.limitedContent}>
          <div className={heroStyles.heroContentContainer}>
            <div>
              <h1 className={heroStyles.heroTitle}>ESDT SPACE</h1>
              <h2 className={heroStyles.heroSubtitle}>FOR BUSINESS</h2>
            </div>
            <div className={heroStyles.heroParagraphsWrapper}>
              <div className={heroStyles.heroParagraph}>
                Managing your blockchain business has just become smoother! Choose from a wide range of fully
                personalized solutions and turn your blockchain project into reality.
              </div>
              <div className={heroStyles.heroParagraph}>
                From project management, token sale, staking pools to blockchain events and token snapshots &#8211; our
                ecosystem has got you covered!
              </div>
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            <DefaultButton type={'secondary'} mode={'outline'} text={'EXPLORE SOLUTIONS'} to={'/business/solutions'} />
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={sectionImage} />
      </div>
    </div>
  );
};

export default HeroSection;
