import React, { useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useScrollToTopHandler } from './hooks/useScrollToTopHandler';

/** Layouts **/
const AppLayout = React.lazy(() => import('../../components/Layout/App'));
const AuthModalLayout = React.lazy(() => import('../../components/Layout/AuthModal'));

/** Landing Pages **/
import HomepageScreen from '../../pages/Landing/common/homepage.screen';
import AboutUsScreen from '../../pages/Landing/common/about.us.screen';
import PartnersScreen from '../../pages/Landing/common/partners.screen';

import BusinessHomepageScreen from '../../pages/Landing/business/business.homepage';
import PersonalHomepageScreen from '../../pages/Landing/personal/personal.homepage';

/** Legal Screens **/
import PrivacyPolicyScreen from '../../pages/Landing/legal/privacy.policy.screen';
import TermsAndConditions from '../../pages/Landing/legal/terms.and.conditions.screen';

/** Auth Screens **/
const AuthButtonsScreen = React.lazy(() => import('../../pages/Auth/AuthButtonsScreen'));

/** App Pages **/
import ProfileScreen from '../../pages/App/ProfileScreen';
import PortfolioScreen from '../../pages/App/PortofolioScreen';
import PersonalFeedScreen from '../../pages/App/PersonalFeedScreen';

import ViewProject from '../../pages/App/ViewProject';
import ViewToken from '../../pages/App/ViewToken';
import OwnProjects from '../../pages/App/OwnProjects';

import LatestProjects from '../../pages/App/LatestProjects';
import ProjectEditScreen from '../../pages/App/ProjectEditScreen';
import ProjectCreateScreen from '../../pages/App/ProjectCreateScreen';
import AuthenticatedRoute from './RouteTypes/AuthenticatedRoute';
import RouteWithStepper from './RouteTypes/RouteWithStepper';
import EditToken from '../../pages/App/EditToken';
import VerificationScreen from '../../pages/Landing/common/verification.screen';

const NotFoundScreen = React.lazy(() => import('../../pages/Landing/NotFoundScreen'));

function ApplicationRouter() {
  const location = useLocation();
  const state = useMemo(() => location.state as { backgroundLocation?: Location }, [location]);
  useScrollToTopHandler();

  return (
    <React.Suspense fallback={<></>}>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<HomepageScreen />} />
          <Route path="about" element={<AboutUsScreen />} />
          <Route path="partners" element={<PartnersScreen />} />

          <Route path="business" element={<BusinessHomepageScreen />} />
          <Route path="personal" element={<PersonalHomepageScreen />} />

          <Route path={'privacy-policy'} element={<PrivacyPolicyScreen />} />
          <Route path={'terms-of-service'} element={<TermsAndConditions />} />
          <Route path={'official-verification'} element={<VerificationScreen />} />

          <Route path={'app'}>
            <Route element={<AuthenticatedRoute />}>
              <Route path="account">
                <Route path={'profile'} element={<ProfileScreen />} />
                <Route path={'feed'} element={<PersonalFeedScreen />} />
                <Route path={'portfolio'} element={<PortfolioScreen />} />
              </Route>

              <Route path="projects">
                <Route path={'me'} element={<OwnProjects />} />

                <Route element={<RouteWithStepper />}>
                  <Route path={'create'} element={<ProjectCreateScreen />} />
                  <Route path={':id/update'} element={<ProjectEditScreen />} />
                </Route>

                <Route path={':projectId/view'} element={<ViewProject />} />
              </Route>
            </Route>

            <Route path="projects">
              <Route index element={<LatestProjects />} />
              <Route path={'latest'} element={<LatestProjects />} />

              <Route path={':projectId/view'} element={<ViewProject />} />
            </Route>
            <Route path="tokens">
              <Route path={':tokenIdentifier/view'} element={<ViewToken />} />
              <Route path={':tokenIdentifier/edit'} element={<EditToken />} />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<NotFoundScreen />} />
      </Routes>

      <React.Suspense fallback={<></>}>
        <Routes>
          <Route path="/app/auth" element={<AuthModalLayout />}>
            <Route index element={<AuthButtonsScreen />} />
          </Route>
        </Routes>
      </React.Suspense>
    </React.Suspense>
  );
}

export default ApplicationRouter;
