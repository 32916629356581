import { createGlobalStyle } from 'styled-components';
import { GlobalStyleProps } from '../types';

export const GlobalStyles = createGlobalStyle<GlobalStyleProps>`
  :root {
    --default-border-radius: ${props => props.theme.sizes.xxs}px;

    --border-top-left-radius: var(--default-border-radius);
    --border-top-right-radius: var(--default-border-radius);
    --border-bottom-left-radius: var(--default-border-radius);
    --border-bottom-right-radius: var(--default-border-radius);

    --default-input-height: 50px;
    --header-height: 90px;

    --padding-horizontal: ${props => props.theme.sizes.xxl}px;

    @media all and ${({ theme: { device } }) => device.sm} {
      --padding-horizontal: ${props => props.theme.sizes.md}px;
    }
  }

  * {
    box-sizing: border-box;
  }

  iframe {
    display: none !important;
  }


  body {
    padding: 0;
    margin: 0;

    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.body};
    transition: background 0.2s ease-in, color 0.2s ease-in;

    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.35;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }

  .modal-backdrop {
    display: none !important;
  }

  .transactions-toast-list_toasts .transaction-toast button {
    color: ${props => props.theme.colors.text} !important;
  }

  .dapp-core-ui-component .toast-messages .toast {
    color: ${props => props.theme.colors.text};
    border-color: ${props => props.theme.colors.border};
    background-color: ${props => props.theme.colors.background};
  }

  .dapp-core-component__transactionsToastListStyles__toasts.dapp-core-component__transactionsToastListStyles__toastWrapper {
    background-color: ${props => props.theme.colors.background} !important;
    border: 1px solid ${props => props.theme.colors.border};
  }

  .dapp-core-component__styles__content .dapp-core-component__styles__right .dapp-core-component__styles__heading .dapp-core-component__styles__close {
    color: ${props => props.theme.colors.text} !important;
  }

  .dapp-core-component__ledgerConnectStyles__login-container .dapp-core-component__main__card,
  .dapp-core-component__walletConnectLoginContainerStyles__login-container .dapp-core-component__main__card {
    background-color: ${props => props.theme.colors.background};
  }

  .sign-transaction-modal_wrapper.modal {
    display: flex !important;
    position: absolute;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.sizes.xxl}px !important;
    background-color: ${props => props.theme.colors.overlay};

    @media all and ${({ theme: { device } }) => device.sm} {
    	padding: 0!important;
    }

    min-width: 100vw;
    min-height: 100vh;

    top: 0;
    left: 0;

    .modal-dialog.modal-dialog-centered {
      display: block;
      align-self: center;
      justify-self: center;
      border-radius: var(--default-border-radius);
      padding: ${props => props.theme.sizes.xl}px;
      font-size: 1.3em;
      margin: 0;

      &,
      .modal-content,
      .dapp-core-ui-component .modal-content,
      .sign-transaction-modal_wrapper {
        border: none;
        color: ${props => props.theme.colors.text};
        background-color: ${props => props.theme.colors.background};
      }

      width: 500px;
      max-width: 100%;

      .bg-warning {
        color: ${props => props.theme.colors.pending};
      }

      .bg-danger {
        color: ${props => props.theme.colors.error};
      }

      button {
        padding: 10px 30px;
        color: ${props => props.theme.colors.text};
        border-color: ${props => props.theme.colors.text};
      }
    }
  }
`;
