import React from 'react';
import ProjectModel from '../../../../../modules/Project/ProjectModel';
import styled from 'styled-components';
import Typography from '../../../../../components/Atomic/Typography';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;
  height: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.sizes.lg}px;
  gap: ${props => props.theme.sizes.lg}px;
  background: ${props => props.theme.colors.surface};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  height: 100%;
`;

const TokenRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ManagersSection = ({ project }: { project: ProjectModel }) => {
  return (
    <SectionContainer>
      <Typography.H2>{'Managers'}</Typography.H2>
      <CardContainer>
        {project.managers.map((manager, key) => {
          return (
            <TokenRow key={key}>
              <Typography.Copy>{manager.slice(0, 16) + '...' + manager.slice(-16)}</Typography.Copy>
            </TokenRow>
          );
        })}
      </CardContainer>
    </SectionContainer>
  );
};

export default ManagersSection;
