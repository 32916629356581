import React from 'react';
import styles from './Icon.module.scss';

type IconProps = {
  name: string;
  size?: number;
  title?: string;

  className?: string;
};

function Icon({ name, size, className, ...rest }: IconProps) {
  return <i className={`${styles.icon} ${name} ${className}`} style={{ fontSize: size + 'px' }} {...rest} />;
}

export default Icon;
