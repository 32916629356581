import queryString from 'query-string';

export default class URLHelper {
  static getUrlParams = () => {
    if (window.location.search) {
      return queryString.parse(window.location.search);
    }

    return {};
  };

  static getUrlParamValue = (param: string) => {
    const arrSearch = URLHelper.getUrlParams();

    if (typeof arrSearch[param] !== 'undefined') {
      return arrSearch[param];
    }

    return null;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static buildQueryString = (filters: any): string => {
    const queryString = Object.keys(filters)
      .map(key => filters[key] && key + '=' + filters[key])
      .join('&');

    return queryString;
  };

  static getParameterByName(name: string, url: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
