import React from 'react';
import styles from './profile.stats.module.scss';

import DefaultButton from '../../../../../components/Atomic/Buttons/DefaultButton';

import { EgldToken } from '../../../../../modules/Tokens/CustomTokens';
import { PortfolioType } from '../../../../../modules/Account/types/portfolio.type';

type Props = {
  portfolio: PortfolioType | null;
};

type BoxProps = {
  title: string;
  amount?: number;
  tokenTicker: string;
  buttonLink: string;
  buttonText: string;
};

function AmountBox(props: BoxProps) {
  const { title, amount = 0, tokenTicker, buttonText, buttonLink } = props;
  const amountParts = amount.toFixed(2).toString().split('.');

  return (
    <div className={styles.box}>
      <h4>{title}</h4>
      <div className={styles.amountContainer}>
        {tokenTicker}
        <div className={styles.amountValue}>
          {amountParts[0]}
          <span>.{amountParts[1]}</span>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <DefaultButton to={buttonLink} mode={'text'} type={'primary'} text={buttonText} />
      </div>
    </div>
  );
}

export default function ProfileStats(props: Props) {
  const { portfolio } = props;

  return (
    <div className={styles.container}>
      <AmountBox
        title={'Wallet Value'}
        tokenTicker={EgldToken.ticker}
        amount={portfolio?.netWorthEGLD}
        buttonText={'See portfolio'}
        buttonLink={'/app/account/portfolio'}
      />

      <AmountBox
        title={'SPACE Balance'}
        amount={0}
        tokenTicker={'SPACE'}
        buttonText={'Get Space Tokens'}
        buttonLink={'/app/account/portfolio'}
      />

      <AmountBox
        title={'Staking Balance'}
        amount={0}
        tokenTicker={'SPACE'}
        buttonText={'Manage Staking'}
        buttonLink={'/app/account/portfolio'}
      />
    </div>
  );
}
