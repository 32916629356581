import DefaultQueryParams from '../Core/models/default.query.params.model';

export default class ProjectFilterModel extends DefaultQueryParams {
  ownerWalletAddress: string;
  tag: string;
  status: string;

  constructor(params = null) {
    super(params);
    this.initializeModel(params);
  }
}
