import React, { TextareaHTMLAttributes, useRef, useState } from 'react';
import styles from './Textarea.module.scss';
import Typography from '../../Typography';

export type InputProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  placeholder?: string;
  leftContent?: JSX.Element;
  rightContent?: JSX.Element;
  belowInputContent?: JSX.Element;
  error?: string;
};

const DefaultTextarea = ({
  placeholder,
  leftContent,
  rightContent,
  belowInputContent,
  label,
  error,
  ...props
}: InputProps) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const focusHandler = (event: any) => {
    setIsFocused(true);
    props.onFocus && props.onFocus(event);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const blurHandler = (event: any) => {
    setIsFocused(false);
    props.onBlur && props.onBlur(event);
  };

  const containerClickHandler = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef.current && inputRef.current.focus && inputRef.current.focus();
  };

  return (
    <div className={styles.wrapper}>
      {label && <Typography.Copy>{label}</Typography.Copy>}
      <div
        className={styles.container}
        data-is-error={!!error}
        data-type={'input'}
        data-is-focused={isFocused}
        onClick={containerClickHandler}
      >
        <div className={styles.extraContentContainer} onClick={e => e.stopPropagation()}>
          {leftContent}
        </div>

        <div className={styles.inputContainer}>
          <textarea
            ref={inputRef}
            placeholder={placeholder}
            rows={4}
            {...props}
            onFocus={focusHandler}
            onBlur={blurHandler}
          />

          {belowInputContent && <div>{belowInputContent}</div>}
        </div>

        <div className={styles.extraContentContainer} onClick={e => e.stopPropagation()}>
          {rightContent}
        </div>
      </div>
      {error && (
        <div className={styles.errorContainer}>
          <Typography.Copy>{error}</Typography.Copy>
        </div>
      )}
    </div>
  );
};

export default DefaultTextarea;
