import React from 'react';
import styled from 'styled-components';

import styles from './statistics.section.module.scss';

import Typography from '../../../Atomic/Typography';

const ItemContainer = styled.div`
  ${Typography.H2} {
    font-weight: 900;
  }
`;

const items = [
  {
    total: '50000+',
    text: 'USERS',
  },
  {
    total: '$9M+',
    text: 'TRANSACTIONS',
  },
  {
    total: '6',
    text: 'PRODUCTS',
  },
];

export default function StatsSection() {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.itemsWrapper}>
        {items.map((item, index) => {
          return (
            <ItemContainer className={styles.itemContainer} key={index}>
              <Typography.H2>{item.total}</Typography.H2>
              <Typography.Copy className={styles.text}>{item.text}</Typography.Copy>
            </ItemContainer>
          );
        })}
      </div>
    </div>
  );
}
