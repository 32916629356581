import React from 'react';

import styles from './verification.screen.module.scss';
import blockStyles from '../../../../components/screens/common/page.blocks/page.module.scss';

import Environment from '../../../../modules/Environment';

import VerificationForm from './verification.form';

export default function VerificationScreen() {
  return (
    <div className={blockStyles.screenContainer}>
      <div className={blockStyles.heroSectionWrapper}>
        <div className={styles.componentWrapper}>
          <div className={styles.componentContent}>
            <div className={styles.header}>
              <div className={styles.titlesWrapper}>
                <h1>ESDT SPACE</h1>
                <h2>Official Verification</h2>
              </div>

              <div>
                Please make sure that you are visiting <span className={styles.websiteUrl}>{Environment.dapp.url}</span>
              </div>
            </div>

            <div className={styles.body}>
              <div className={styles.disclaimersWrapper}>
                <div>
                  To prevent fraud in the name of <strong>EsdtSpace</strong>, you can confirm whether a channel /
                  website / social media page is official or not, including but not limited to Twitter, Facebook,
                  Telegram, Medium, YouTube, Instagram, LinkedIn, and Reddit.
                </div>

                <div>
                  Please note that <strong>EsdtSpace</strong> works with other Partners to identify quality projects and
                  tokens. However, these Partners are not employees or representatives of <strong>EsdtSpace</strong>,
                  and are not authorized to enter any contracts or accept any payments on behalf of{' '}
                  <strong>EsdtSpace</strong>.
                </div>
              </div>

              <VerificationForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
