import axios from 'axios';
import Environment from '../Environment';
import { logout } from '@elrondnetwork/dapp-core/utils';

export default class ApiClient {
  private headers = {};
  private baseUrl = Environment.microservice.spaceApi;
  private Http;

  constructor() {
    this.Http = axios.create({ baseURL: this.baseUrl });
    this.Http.interceptors.response.use(
      response => {
        if (response.status === 401) {
          if (ApiClient.getRefreshToken()) {
            axios
              .create({ baseURL: this.baseUrl })
              .post(`/auth/refresh-token?refreshToken=${ApiClient.getRefreshToken()}`, {})
              .then(response => response.data)
              .then(response => {
                ApiClient.saveAccessToken(response.accessToken);
                ApiClient.saveRefreshToken(response.refreshToken);
              })
              .catch(error => {
                ApiClient.removeAccessToken();
                ApiClient.removeAccessToken();
                logout();
              });
          }
        }
        return response;
      },
      error => {
        if (error.response && error.response.status === 401) {
          if (ApiClient.getRefreshToken()) {
            return axios
              .create({ baseURL: this.baseUrl })
              .post(`/auth/refresh-token?refreshToken=${ApiClient.getRefreshToken()}`, {})
              .then(response => response.data)
              .then(response => {
                ApiClient.saveAccessToken(response.accessToken);
                ApiClient.saveRefreshToken(response.refreshToken);
              })
              .catch(error => {
                ApiClient.removeAccessToken();
                ApiClient.removeAccessToken();
                logout();
              });
          }
        }
        if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
      },
    );
  }

  withBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;

    return this;
  }

  withAuthentication() {
    this.headers = {
      Authorization: `Bearer ${ApiClient.getAccessToken()}`,
    };

    return this;
  }

  get(route: string) {
    const config = {
      headers: this.headers,
      baseURL: this.baseUrl,
    };
    return this.Http.get(route, config);
  }

  post(route: string, data: unknown) {
    const config = {
      headers: this.headers,
      baseURL: this.baseUrl,
    };
    return this.Http.post(route, data, config);
  }

  put(route: string, data: unknown) {
    const config = {
      headers: this.headers,
      baseURL: this.baseUrl,
    };
    return this.Http.put(route, data, config);
  }

  delete(route: string) {
    const config = {
      headers: this.headers,
      baseURL: this.baseUrl,
    };
    return this.Http.delete(route, config);
  }

  static saveAccessToken(token: string) {
    localStorage.setItem('access-token', token);
  }

  static removeAccessToken() {
    localStorage.removeItem('access-token');
  }

  static getAccessToken() {
    return localStorage.getItem('access-token');
  }

  static saveRefreshToken(token: string) {
    localStorage.setItem('refresh-token', token);
  }

  static getRefreshToken() {
    return localStorage.getItem('refresh-token');
  }

  static removeRefreshToken() {
    return localStorage.removeItem('refresh-token');
  }
}
