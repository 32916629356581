import React, { useContext } from 'react';

import { ReactComponent as EmptyStateLight } from './assets/empty-state-light.svg';
import { ReactComponent as EmptyStateDark } from './assets/empty-state-dark.svg';
import { ThemeContext } from '../../../../../../modules/Theme/ThemeProvider';
import Typography from '../../../../../../components/Atomic/Typography';
import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import Environment from '../../../../../../modules/Environment';

import styles from './EmptySalesList.module.scss';

type Props = {
  loading: boolean;
  hasAccessToCreate: boolean;
};

const EmptySalesList = (props: Props) => {
  const { isDarkTheme } = useContext(ThemeContext);

  const { loading, hasAccessToCreate } = props;
  const EmptyStateComponent = isDarkTheme ? EmptyStateDark : EmptyStateLight;

  return (
    <div className={styles.container}>
      {!loading && (
        <div className={styles.content}>
          <div className={styles.wrapper}>
            <Typography.Copy size={'large'}>Sales list is empty</Typography.Copy>
            <Typography.Copy size={'small'}>
              {hasAccessToCreate
                ? 'Why don’t you go ahead and create one now?'
                : 'Once the owners will create a sale it will be visible here'}
            </Typography.Copy>
          </div>

          {hasAccessToCreate && (
            <DefaultButton
              onClick={() => (window.location.href = Environment.website.launchpad + '/app/token-sales/create')}
              text={'CREATE SALE ON LAUNCHPAD'}
            />
          )}
        </div>
      )}

      {loading && (
        <div className={styles.content}>
          <div className={styles.wrapper}>
            <Typography.Copy size={'large'}>Loading your sales...</Typography.Copy>
            <Typography.Copy size={'small'}>Please wait...</Typography.Copy>
          </div>
        </div>
      )}

      <EmptyStateComponent />
    </div>
  );
};

export default EmptySalesList;
