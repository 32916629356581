import styled from 'styled-components';

const MaxWidthContainer = styled.div`
  box-sizing: border-box;

  padding-left: var(--padding-horizontal);
  padding-right: var(--padding-horizontal);

  margin: auto;
  width: 100%;
  max-width: 1700px;
`;

export default MaxWidthContainer;
