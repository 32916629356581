import { useCallback } from 'react';
import {
  ContractFunction,
  BytesValue,
  Interaction,
  SmartContract,
  Address,
  AddressValue,
} from '@elrondnetwork/erdjs/out';
import { getAccount, getLatestNonce } from '@elrondnetwork/dapp-core/utils';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import sendSmartContractTransaction from '../useSmartContractTransaction';
import Environment from '../../../Environment';

export const useUnFreezeTransaction = () => {
  const { address } = useGetAccountInfo();
  const GAS_LIMIT = 60000000;
  const getTransactionData = (tokenIdentifier: string, wallet: string) => {
    const transactionPayload = [];
    transactionPayload.push(BytesValue.fromUTF8(tokenIdentifier));
    transactionPayload.push(new AddressValue(new Address(wallet)));

    return {
      transactionPayload: transactionPayload,
    };
  };

  return useCallback(
    async (tokenIdentifier: string, wallet: string) => {
      const account = await getAccount(address);
      const nonce = getLatestNonce(account);
      const dataToSend = getTransactionData(tokenIdentifier, wallet);

      const contract = new SmartContract({
        address: new Address(Environment.elrond.smartContractAddressTokensManagement),
      });

      const smartContractFunction = new ContractFunction('unFreeze');

      const interaction = new Interaction(contract, smartContractFunction, dataToSend.transactionPayload);

      return sendSmartContractTransaction(interaction, nonce, {}, GAS_LIMIT);
    },
    [address],
  );
};
