import { EnvironmentsEnum } from '@elrondnetwork/dapp-core/types';
import getBuildTypeEnvironmentVariables from './builders/BuildTypeEnvironmentVariables';

function getEnvironment() {
  const envType: EnvironmentsEnum = (process.env.REACT_APP_ENVIRONMENT as EnvironmentsEnum) || EnvironmentsEnum.mainnet;

  return {
    ...getBuildTypeEnvironmentVariables(envType as EnvironmentsEnum),
  };
}

const Environment = getEnvironment();
export default Environment;
