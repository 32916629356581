import Environment from '../Environment';
import EsdtTokenModel from './models/EsdtTokenModel';
import NftTokenModel from './models/NftTokenModel';
import ApiClient from '../Http/ApiClient';

const ESDT_ROUTE = '/tokens/esdt';
const ESDT_META_ROUTE = '/tokens/meta-esdt';

export default class TokensService {
  static API_URL = Environment.microservice.spaceApi;
  private static api = new ApiClient();

  static async getEsdtTokens() {
    return this.api
      .withBaseUrl(this.API_URL)
      .get(`${ESDT_ROUTE}`)
      .then(response => response.data)
      .then(items => items.map((item: any) => new EsdtTokenModel(item)));
  }

  static async getEsdtTokenByIdentifier(identifier: string) {
    return this.api
      .withBaseUrl(this.API_URL)
      .get(`${ESDT_ROUTE}/${identifier}`)
      .then(response => response.data)
      .then(item => new EsdtTokenModel(item));
  }

  static async getMetaTokens() {
    return this.api
      .withBaseUrl(this.API_URL)
      .get(`${ESDT_META_ROUTE}`)
      .then(response => response.data)
      .then(items => items.map((item: any) => new NftTokenModel(item)));
  }
}
