import _ from 'lodash';
import { TThemeColors, TThemeProps } from '../../types';
import GlobalThemeProps from '../../config/global-theme-props';

const colors = {
  primary: '#367CFF',
  primaryTransparent: 'rgba(54, 124, 255, 0.2)',
  textOnPrimary: '#fff',
  secondary: '#FFB800',
  textOnSecondary: '#d5d5d5',
  success: '#3bad62',
  lightGreen: '#42AE00',
  pending: '#ff9b00',
  error: '#d14646',
  red: '#E30E00',
} as TThemeColors;

const CommonPartialTheme = {
  colors: colors,
} as TThemeProps;

const CommonTheme: TThemeProps = _.merge(GlobalThemeProps, CommonPartialTheme);

export default CommonTheme;
