import React, { useState } from 'react';
import styled from 'styled-components';
import Typography from '../../../components/Atomic/Typography';
import MaxWidthContainer from '../../../components/Layout/Containers/MaxWidthContainer';
import ProjectFilterModel from '../../../modules/Project/ProjectFilterModel';
import { OwnProjectListProvider } from '../../../modules/Project/OwnProjectListProvider';
import LoadMoreOwnProjectsList from '../../../components/Project/OwnProjectsList/LoadMoreOwnProjectsList';

const ScreenContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xxl}px;
  --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;
    gap: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const TitleAndParagraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;
`;

const ProjectListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProjectsContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  gap: ${props => props.theme.sizes.xxl * 3}px;
`;

const initialFilters = new ProjectFilterModel();
initialFilters.skip = 0;
initialFilters.limit = 3;

const LatestProjects = () => {
  const [filters, setFilters] = useState<ProjectFilterModel>(initialFilters);

  return (
    <ScreenContainer>
      <ContentContainer>
        <TitleAndParagraphs>
          <Typography.H2>{'Own Projects'}</Typography.H2>
          <Typography.Copy size={'large'}>{'Explore your projects added to the ESDT SPACE ecosystem'}</Typography.Copy>
        </TitleAndParagraphs>
        <OwnProjectListProvider filters={filters}>
          <ProjectsContainer>
            <ProjectListContainer>
              <LoadMoreOwnProjectsList filters={filters} onPageChange={setFilters} />
            </ProjectListContainer>
          </ProjectsContainer>
        </OwnProjectListProvider>
      </ContentContainer>
    </ScreenContainer>
  );
};

export default LatestProjects;
