import CreateProjectDto from '../../../../modules/Project/dto/create.project.dto';

export default class ProjectValidation {
  static validateProjectGeneralInfo(item: CreateProjectDto) {
    const errors: { [key: string]: string } = {};

    if (!item.name) {
      errors.name = 'emptyName';
    }

    if (!item.shortDescription) {
      errors.shortDescription = 'emptyShortDescription';
    }

    if (!item.longDescription) {
      errors.longDescription = 'emptyLongDescription';
    }

    if (item.shortDescription && item.shortDescription.length < 30) {
      errors.shortDescription = 'lengthShortDescription';
    }

    if (item.longDescription && item.longDescription.length < 50) {
      errors.longDescription = 'lengthLongDescription';
    }

    if (!item.tags) {
      errors.tags = 'emptyTags';
    }

    if (item.tokens.length === 0) {
      errors.tokenIdentifier = 'emptySaleToken';
    }

    return errors;
  }

  static validateProjectLinks(item: CreateProjectDto) {
    const errors: { [key: string]: string } = {};

    if (!item.websiteUrl) {
      errors.websiteUrl = 'emptyWebsiteUrl';
    }

    if (!item.whitepaperUrl) {
      errors.whitepaperUrl = 'emptyWhitepaperUrl';
    }

    if (!item.roadmapUrl) {
      errors.roadmapUrl = 'emptyRoadmapUrl';
    }

    if (!item.imageUrl) {
      errors.imageUrl = 'emptyImageUrl';
    }

    return errors;
  }
}
