import React, { useMemo, useState } from 'react';
import styles from './verification.form.module.scss';

import Icon from '../../../../../components/Atomic/Icon';
import Modal from '../../../../../components/Atomic/Modal';
import DefaultInput from '../../../../../components/Atomic/Input';
import DefaultButton from '../../../../../components/Atomic/Buttons/DefaultButton';

import verifyChannel from '../search.handler';

export default function VerificationForm() {
  const [channel, setChannel] = useState('');
  const [modalText, setModalText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isOfficialChannel, setIsOfficialChannel] = useState<boolean | undefined>(undefined);

  const inputError = useMemo(() => {
    if (isOfficialChannel || isOfficialChannel === undefined) return undefined;

    return 'This is not an official channel / account of EsdtSpace';
  }, [isOfficialChannel]);

  const verifyChannelHandler = () => {
    const { isOfficial, text } = verifyChannel(channel);

    setModalText(text);
    setShowModal(true);
    setIsOfficialChannel(isOfficial);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  const inputChangeHandler = (text: string) => {
    setChannel(text);
    setIsOfficialChannel(undefined);
  };

  return (
    <div className={styles.form}>
      <DefaultInput
        value={channel}
        error={inputError}
        onChange={event => inputChangeHandler(event.target.value)}
        placeholder={'Please enter the complete query information'}
      />

      <DefaultButton disabled={channel.length === 0} onClick={verifyChannelHandler}>
        Verify
      </DefaultButton>

      {showModal && (
        <Modal onClose={closeModalHandler}>
          <div data-result-type={isOfficialChannel ? 'success' : 'fail'} className={styles.modalContent}>
            <div className={styles.title}>
              <Icon name={`fa-solid fa-${isOfficialChannel ? 'check-circle' : 'info-circle'}`} />
              <h4>{!isOfficialChannel && `Non-`}Official Channel!</h4>
            </div>
            <div className={styles.textContent}>
              <div className={styles.verificationResult} dangerouslySetInnerHTML={{ __html: modalText }} />
              <div>You can verify the content and re-query!</div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
