import React, { ComponentType } from 'react';
import { GroupBase, OptionProps } from 'react-select';

import Select from '../../../Atomic/Pickers/Select';

import MenuList from './MenuListComponent';
import { OptionComponent } from './OptionComponent';
import { filterOption, tokenLabelExtractor, tokenValueExtractor } from './helpers';

export type TokenSelectorProps = any & {
  showBalance?: boolean;
};

const TokenSelector = ({ showBalance, ...props }: TokenSelectorProps) => {
  // const { translations } = useContext(LocalizationContext);

  return (
    <Select
      formatOptionLabel={tokenLabelExtractor}
      getOptionValue={tokenValueExtractor}
      getOptionLabel={tokenLabelExtractor}
      noOptionsMessage={({ inputValue }) => 'Not found'}
      filterOption={filterOption}
      components={{
        MenuList,
        Option: OptionComponent as ComponentType<OptionProps<unknown, boolean, GroupBase<unknown>>>,
      }}
      isMulti={true}
      isClearable
      {...props}
    />
  );
};

export default TokenSelector;
