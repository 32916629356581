import React, { useEffect, useRef, useState } from 'react';
import styles from './Table.module.scss';
import Typography from '../Typography';

interface TableRowProps {
  numberOfColumns: number | string;
  children?: React.ReactNode;
}

const Row: React.FC<TableRowProps> = ({ numberOfColumns, children }: TableRowProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowRef: any = useRef<HTMLDivElement>();

  useEffect(() => {
    if (rowRef.current) {
      rowRef.current.style.setProperty('--table-number-of-columns', numberOfColumns + '');
    }
  }, [rowRef, numberOfColumns]);

  return (
    <div ref={rowRef} className={styles.tableRow}>
      {children}
    </div>
  );
};

type TableProps = {
  tableHeaders: { name: string; hiddenInMobile?: boolean }[];
  tableRows?: { cells: React.ReactElement[] | string[] }[];
};

const DefaultTable = ({ tableHeaders, tableRows = [] }: TableProps) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div className={styles.table}>
      {isMobile && (
        <React.Fragment>
          {tableRows.length === 0 && (
            <div className={styles.emptySection}>
              <Typography.Copy size={'small'}>{'No data'}</Typography.Copy>
            </div>
          )}
          {tableRows.map((row, rowIndex: number) => (
            <div className={styles.mobileRow} key={`row-${rowIndex}`}>
              {row.cells.map((cell, cellIndex) => {
                return (
                  <div className={styles.mobileCell} key={`row-${cellIndex}`}>
                    {!tableHeaders[cellIndex].hiddenInMobile && <div>{tableHeaders[cellIndex].name}</div>}
                    <div className={'cell-value'}>{cell}</div>
                  </div>
                );
              })}
            </div>
          ))}
        </React.Fragment>
      )}
      {!isMobile && (
        <React.Fragment>
          {tableHeaders.length > 0 && (
            <Row numberOfColumns={tableHeaders.length}>
              {tableHeaders.map((header, index) => (
                <div className={styles.headerCell} key={`header-${index}`}>
                  <Typography.Copy>{header.name}</Typography.Copy>
                </div>
              ))}
            </Row>
          )}
          {tableRows.length === 0 && (
            <div className={styles.emptySection}>
              <Typography.Copy size={'small'}>{'No data'}</Typography.Copy>
            </div>
          )}
          {tableRows.map((row, rowIndex: number) => (
            <Row numberOfColumns={tableHeaders.length} key={`row-${rowIndex}`}>
              {row.cells.map((cell, cellIndex) => {
                return (
                  <div className={styles.cell} key={`row-${cellIndex}`}>
                    {cell}
                  </div>
                );
              })}
            </Row>
          ))}
        </React.Fragment>
      )}
    </div>
  );
};

export default DefaultTable;
