import React from 'react';

import Typography from '../../../../../components/Atomic/Typography';
import ProjectModel from '../../../../../modules/Project/ProjectModel';

import styles from './SmartContractsSection.module.scss';

const SmartContractsSection = ({ project }: { project: ProjectModel }) => {
  return (
    <div className={styles.section}>
      <Typography.H2>{'Smart Contracts'}</Typography.H2>
      <div className={styles.card}>
        {project.smartContracts.map((smartContract, key) => {
          return (
            <div className={styles.row} key={key}>
              <Typography.Copy className={'name'}>{smartContract.name}</Typography.Copy>
              <Typography.Copy>{smartContract.address}</Typography.Copy>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SmartContractsSection;
