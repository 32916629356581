import ProjectModel from '../ProjectModel';
import { useContext } from 'react';
import { EsdtTokensContext } from '../../Tokens/Esdt/EsdtTokensContextProvider';

const useProjectTokenImageUrl = (project: ProjectModel) => {
  const { esdtTokens } = useContext(EsdtTokensContext);
  const tokenInfo = esdtTokens?.find(token => token?.identifier === project?.tokens[0]);
  if (tokenInfo?.hasAssetImage()) {
    return tokenInfo.getAssetImageUrl();
  }
  return null;
};

export default useProjectTokenImageUrl;
