import { TThemeColors, TThemeProps } from '../../types';
import DefaultCommonTheme from './default-common';

const DarkTheme: Partial<TThemeProps> = {
  colors: {
    body: '#070707',
    background: '#070707',
    surface: 'linear-gradient(94.13deg, #191C25 0%, #0F141F 100%)',
    text: '#c4c4c4',
    titleText: '#e1e1e1',
    accentText: '#848484',
    border: '#39404A',
    highlight: '#4f4e4e',
    overlay: 'rgba(255, 255, 255, 0.2)',
    shadow: 'rgba(0,0,0,.25)',

    gradient: 'linear-gradient(94.13deg, #191C25 0%, #0F141F 100%)',
    gradient2: 'linear-gradient(83.04deg, #243748 3.83%, rgba(47, 52, 70, 0.3) 94.56%)',
    accentBackground: 'rgba(57, 64, 74, 0.3)',
    footerBackground: 'rgba(57, 64, 74, 0.3)',

    cardHover: 'linear-gradient(153.47deg, rgba(160, 170, 197, 0) -341.94%, #050912 95.11%)',
    cardBackground: 'linear-gradient(94.13deg, #191C25 0%, #0F141F 100%)',
    coloredGradient: 'linear-gradient(116.74deg, #EB00FF 0%, #00A3FF 100%)',

    buttonDisabledColor: '#4f4e4e',
  } as TThemeColors,
};

const DefaultDarkTheme = {
  ...DefaultCommonTheme,
  colors: {
    ...DefaultCommonTheme.colors,
    ...DarkTheme.colors,
  },
};

export default DefaultDarkTheme;
