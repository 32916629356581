import React from 'react';
import styles from './ParagraphsListRenderer.module.scss';
import Typography from '../../../Typography';

const ParagraphsListRenderer = ({ content }: { content: any }) => {
  let paragraphs: any[] = [];

  try {
    if (content.hasOwnProperty('paragraphs')) {
      paragraphs = [...content.paragraphs];
    } else if (typeof content === 'string') {
      paragraphs = [content];
    }
  } catch (e) {
    paragraphs = [content];
  }

  return (
    <div className={styles.wrapper}>
      {paragraphs.map((paragraph, paragraphIndex) => (
        <Typography.Copy key={paragraphIndex} dangerouslySetInnerHTML={{ __html: paragraph }} />
      ))}
    </div>
  );
};

export default ParagraphsListRenderer;
