import React from 'react';
import styles from './solutions.section.module.scss';

import items from './solutions.data';
import Typography from '../../../../../../components/Atomic/Typography';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

type Item = {
  title: string;
  icon: string;
  paragraphs: string[];
  link: string | undefined;
  disabled?: boolean;
};

function SolutionItem({ item }: { item: Item }) {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemIcon} style={{ backgroundImage: `url(${item.icon})` }} />
      <div className={styles.contentContainer}>
        <Typography.H4>{item.title}</Typography.H4>
        <div className={styles.paragraphsContainer}>
          {item.paragraphs.map((paragraph, index) => (
            <Typography.Copy key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </div>

        <a data-disabled={!!item.disabled} className={styles.itemLink} href={item.link}>
          {!!item.disabled ? 'Coming Soon' : 'Learn More'}
        </a>
      </div>
    </div>
  );
}

export default function SolutionsSection() {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.titleWrapper}>
        <h2 className={sectionStyles.sectionTitle}>Our Solutions</h2>
        <div className={sectionStyles.sectionParagraph}>Let us handle the burden and take your time back!</div>
        <div className={sectionStyles.sectionParagraph}>
          Our ecosystem provides a wide range of personalized solutions for all things blockchain &#8212; token sale,
          vesting, staking, DAO creation and everything else that’s needed to have a smooth transition to blockchain.
        </div>
      </div>

      <div className={styles.itemsWrapper}>
        {(items as Item[]).map((item: Item, index) => (
          <SolutionItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}
