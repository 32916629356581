import Environment from '../Environment';

import ApiClient from '../Http/ApiClient';
import KycInfoModel from './models/kyc.info.model';

export default class KYCService {
  private static api = new ApiClient();
  static getApplicantKycById(applicantId: string): Promise<KycInfoModel> {
    return this.api
      .withBaseUrl(Environment.microservice.spaceApi)
      .withAuthentication()
      .get('/kyc/account/applicant/' + applicantId)
      .then((response: any) => {
        return new KycInfoModel(response.data);
      });
  }

  static itemToModel = (item: any, model: any) => new model(item);
}
