import React, { useContext } from 'react';
import styled from 'styled-components';

import DefaultButton from '../../../Atomic/Buttons/DefaultButton';

import EmptyProjectsList from './components/EmptyProjectsList';
import ProjectFilterModel from '../../../../modules/Project/ProjectFilterModel';
import { ProjectsListContext } from '../../../../modules/Project/ProjectListProvider';
import ProjectModel from '../../../../modules/Project/ProjectModel';
import ProjectCard from '../../ProjectCard';

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Container = styled.div`
  --number-of-columns: 3;

  @media all and (min-width: 1800px) {
    --number-of-columns: 3;
  }

  @media all and ${({ theme: { device } }) => device.md} {
    --number-of-columns: 2;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    --number-of-columns: 1;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    --number-of-columns: 1;
  }

  display: grid;
  grid-template-columns: repeat(var(--number-of-columns), 1fr);
  row-gap: ${props => props.theme.sizes.xl}px;
  column-gap: ${props => props.theme.sizes.xl}px;
`;

type ComponentProps = {
  filters: ProjectFilterModel;
  disableLoadMore?: boolean;
  onPageChange: (value: ProjectFilterModel) => void;
};

const LoadMoreOwnProjectsList = ({ filters, onPageChange, disableLoadMore }: ComponentProps) => {
  const { projects, hasNextPage, currentPage, currentLimit, isLoading, isInitialized } =
    useContext(ProjectsListContext);

  const loadNextPage = () =>
    onPageChange &&
    hasNextPage &&
    onPageChange({ ...filters, ...{ skip: currentPage * currentLimit } } as ProjectFilterModel);

  if (projects.length === 0) {
    return <EmptyProjectsList loading={!isInitialized} />;
  }

  return (
    <ListWrapper>
      <Container>
        {projects.map((item: ProjectModel) => (
          <ProjectCard key={item.id} project={item} />
        ))}
      </Container>

      {hasNextPage && !disableLoadMore && (
        <DefaultButton type={'primary'} mode={'text'} text={'Show more'} onClick={loadNextPage} loading={isLoading} />
      )}
    </ListWrapper>
  );
};

export default LoadMoreOwnProjectsList;
