import React, { useState } from 'react';
import styled from 'styled-components';
import MaxWidthContainer from '../../../components/Layout/Containers/MaxWidthContainer';
import Typography from '../../../components/Atomic/Typography';
import { EgldToken } from '../../../modules/Tokens/CustomTokens';
import { ProjectListProvider } from '../../../modules/Project/ProjectListProvider';
import LoadMoreProjectsList from '../../../components/Project/ProjectsList/LoadMoreProjectsList';
import ProjectFilterModel from '../../../modules/Project/ProjectFilterModel';
import DefaultButton from '../../../components/Atomic/Buttons/DefaultButton';
import { ProjectTagsEnum } from '../../../modules/Project/enums/projectTagsEnum';

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xxl}px;
  --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxxl * 2}px;
    gap: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const TitleAndParagraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xs}px;
  align-items: flex-start;

  ${Typography.H1} {
    font-weight: 700;
  }
`;

const AmountsBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${props => props.theme.sizes.md}px;

  @media all and ${({ theme: { device } }) => device.md} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    grid-template-columns: 1fr 1fr;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    grid-template-columns: 1fr;
  }
`;

const AmountBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 20px;

  /* CardGradientV2 */

  background: ${props => props.theme.colors.gradient2};
  /* Border */

  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 15px;

  ${Typography.Copy} {
    font-weight: 900;
    text-transform: uppercase;
  }

  .amountValue {
    display: flex;
    flex-direction: column;

    ${Typography.Copy} {
      font-weight: 700;
      color: ${props => props.theme.colors.accentText};
    }

    ${Typography.H3} {
      font-weight: 900;
      color: #c7d2e6;
    }
  }
`;

const ProjectListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProjectsContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  gap: ${props => props.theme.sizes.xxl * 3}px;
`;

const ProjectsFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px;
  gap: 20px;

  @media all and ${({ theme: { device } }) => device.sm} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const ProjectTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px;
  gap: 20px;
`;

const ProjectTag = styled.div<{ disabled?: boolean; active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 65px;
  gap: 10px;
  opacity: ${props => (props.disabled ? '0.3' : '1')};
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  background: ${props =>
    props.active ? props.theme.colors.primary : 'linear-gradient(92.54deg, #161c28 0%, #1a1f2e 100%)'};
  border-radius: 10px;
`;

const initialFilters = new ProjectFilterModel();
initialFilters.skip = 0;
initialFilters.limit = 3;

const PersonalFeedScreen = () => {
  const [filters, setFilters] = useState<ProjectFilterModel>(initialFilters);

  const onChangeTagFilter = (value: string) => {
    setFilters(Object.assign(new ProjectFilterModel(), filters, { tag: value === filters.tag ? null : value }));
  };

  return (
    <ScreenContainer>
      <ContentContainer>
        <TitleAndParagraphs>
          <Typography.H1>{'Highlights'}</Typography.H1>
          <Typography.Copy size={'large'}>{'Your daily dose of Elrond highlights is here ⚡️'}</Typography.Copy>
        </TitleAndParagraphs>
        <AmountsBoxes>
          <AmountBoxWrapper>
            <Typography.Copy size={'large'}>{'Transactions'}</Typography.Copy>
            <div className={'amountValue'}>
              <Typography.H3>106,000</Typography.H3>
            </div>
          </AmountBoxWrapper>
          <AmountBoxWrapper>
            <Typography.Copy size={'large'}>{'New Addresses'}</Typography.Copy>
            <div className={'amountValue'}>
              <Typography.H3>704</Typography.H3>
            </div>
          </AmountBoxWrapper>
          <AmountBoxWrapper>
            <Typography.Copy size={'large'}>{'Trading Volume'}</Typography.Copy>
            <div className={'amountValue'}>
              <Typography.H3>$55,926,406</Typography.H3>
            </div>
          </AmountBoxWrapper>
          <AmountBoxWrapper>
            <Typography.Copy size={'large'}>{'Staking'}</Typography.Copy>
            <div className={'amountValue'}>
              <Typography.H3>14,309,282 {EgldToken.ticker}</Typography.H3>
            </div>
          </AmountBoxWrapper>
        </AmountsBoxes>
      </ContentContainer>
      <ContentContainer>
        <TitleAndParagraphs>
          <Typography.H1>{'Latest Projects'}</Typography.H1>
          <Typography.Copy size={'large'}>
            {'Explore the latest projects added to the ESDT SPACE ecosystem'}
          </Typography.Copy>
        </TitleAndParagraphs>
        <ProjectsFilterWrapper>
          <ProjectTags>
            {Object.values(ProjectTagsEnum).map((tag, key) => {
              return (
                <ProjectTag active={filters.tag === tag} onClick={() => onChangeTagFilter(tag)} key={key}>
                  <Typography.Copy>{`#${tag.toLowerCase()}`}</Typography.Copy>
                </ProjectTag>
              );
            })}
          </ProjectTags>
          <DefaultButton type={'primary'} mode={'text'} text={'Explore all projects'} to={'/app/projects'} />
        </ProjectsFilterWrapper>
        <ProjectListProvider filters={filters}>
          <ProjectsContainer>
            <ProjectListContainer>
              <LoadMoreProjectsList disableLoadMore={true} filters={filters} onPageChange={setFilters} />
            </ProjectListContainer>
          </ProjectsContainer>
        </ProjectListProvider>
      </ContentContainer>
    </ScreenContainer>
  );
};

export default PersonalFeedScreen;
