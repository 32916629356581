import { useCallback } from 'react';
import { ContractFunction, BytesValue, Interaction, SmartContract, Address } from '@elrondnetwork/erdjs/out';
import { getAccount, getLatestNonce } from '@elrondnetwork/dapp-core/utils';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import sendSmartContractTransaction from '../useSmartContractTransaction';
import Environment from '../../../Environment';

export const useMintTransaction = () => {
  const { address } = useGetAccountInfo();
  const GAS_LIMIT = 60000000;
  const getTransactionData = (tokenIdentifier: string, supply: string) => {
    const transactionPayload = [];
    transactionPayload.push(BytesValue.fromUTF8(tokenIdentifier));
    transactionPayload.push(BytesValue.fromUTF8(supply));

    return {
      transactionPayload: transactionPayload,
    };
  };

  return useCallback(
    async (tokenIdentifier: string, supply: string) => {
      const account = await getAccount(address);
      const nonce = getLatestNonce(account);
      const dataToSend = getTransactionData(tokenIdentifier, supply);

      const contract = new SmartContract({
        address: new Address(address),
      });

      const smartContractFunction = new ContractFunction('ESDTLocalMint');

      const interaction = new Interaction(contract, smartContractFunction, dataToSend.transactionPayload);

      return sendSmartContractTransaction(interaction, nonce, {}, GAS_LIMIT);
    },
    [address],
  );
};
