import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Typography from '../../../Atomic/Typography';
import MaxWidthContainer from '../../../Layout/Containers/MaxWidthContainer';
import DynamicContentRenderer from '../../../Atomic/Content/DynamicContentRenderer';

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionWrapper = styled.div`
  display: flex;
  padding-top: ${props => props.theme.sizes.xxxl * 2}px;
  padding-bottom: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    padding: ${props => props.theme.sizes.xxl * 2}px 0;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    min-height: initial;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    padding: ${props => props.theme.sizes.xxl * 2}px 0;
  }
`;

const TitleBottomLine = styled.div`
  width: 350px;
  position: relative;
  border-bottom: 1px solid #2d2d2d;
  content: '';

  ::before {
    content: '';
    position: absolute;
    border-bottom: 3px solid ${props => props.theme.colors.primary};
    height: 2px;
    width: 35%;
    left: 0;
    top: -3px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.sizes.xxs}px;
  flex-direction: column;
`;

const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.sizes.sm}px;
`;

const TitleAndDescriptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  margin-bottom: 100px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .title {
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
  }

  .description {
    span {
      font-weight: 600;
    }
  }
`;

const SectionItemWrapperDefault = ({ children }: { children: ReactNode }) => (
  <div>
    <SectionWrapper>
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </SectionWrapper>
  </div>
);

const LegalArticlesContent = ({
  title,
  paragraphs,
  articles,
}: {
  title: string;
  paragraphs: [];
  articles: [];
  image?: string;
}) => {
  return (
    <ScreenContainer>
      <SectionItemWrapperDefault>
        <TitleAndDescriptionsWrapper>
          <TitleWrapper>
            <Typography.H2>{title}</Typography.H2>
            <TitleBottomLine />
          </TitleWrapper>
          <ParagraphWrapper>
            {paragraphs.map((paragraph: string, index: number) => (
              <Typography.Copy key={index}>{paragraph}</Typography.Copy>
            ))}
          </ParagraphWrapper>
        </TitleAndDescriptionsWrapper>
        <ItemsWrapper>
          {articles.map((article: { title: string; items: Partial<{ type?: string }>[] }, index: number) => (
            <ItemWrapper key={index}>
              <Typography.Copy className={'title'} size={'large'}>
                {article.title}
              </Typography.Copy>
              {article.items.map((articleItem, index) => (
                <DynamicContentRenderer key={index} content={articleItem} customRenderers={{}} />
              ))}
            </ItemWrapper>
          ))}
        </ItemsWrapper>
      </SectionItemWrapperDefault>
    </ScreenContainer>
  );
};

export default LegalArticlesContent;
