import { useCallback } from 'react';
import { ContractFunction, BytesValue, Interaction, SmartContract, Address } from '@elrondnetwork/erdjs/out';
import { getAccount, getLatestNonce } from '@elrondnetwork/dapp-core/utils';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import sendSmartContractTransaction from '../useSmartContractTransaction';
import keccak from 'keccak';

export const useRegisterHeroTagTransaction = () => {
  const { address } = useGetAccountInfo();
  const GAS_LIMIT = 15000000;
  const getTransactionData = (heroTag: string) => {
    const transactionPayload = [];
    transactionPayload.push(BytesValue.fromUTF8(`${heroTag}.elrond`));

    return {
      transactionPayload: transactionPayload,
    };
  };

  const dnsScAddressForHerotag = (herotag: string) => {
    const hashedHerotag = keccak('keccak256').update(herotag).digest();

    const initialAddress = Buffer.from(Array(32).fill(1));
    const initialAddressSlice = initialAddress.slice(0, 30);
    const scId = hashedHerotag.slice(31);

    const deployer_pubkey = Buffer.concat([initialAddressSlice, Buffer.from([0, scId.readUIntBE(0, 1)])]);

    const scAddress = SmartContract.computeAddress(new Address(deployer_pubkey), 0);

    return scAddress;
  };

  return useCallback(
    async (heroTag: string) => {
      const account = await getAccount(address);
      const nonce = getLatestNonce(account);
      const dataToSend = getTransactionData(heroTag);
      const dnsScAddress = dnsScAddressForHerotag(`${heroTag}.elrond`);

      const contract = new SmartContract({ address: dnsScAddress });

      const smartContractFunction = new ContractFunction('register');

      const interaction = new Interaction(contract, smartContractFunction, dataToSend.transactionPayload);

      return sendSmartContractTransaction(interaction, nonce, {}, GAS_LIMIT);
    },
    [address],
  );
};
