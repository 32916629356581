import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

import Typography from '../../../components/Atomic/Typography';
import DefaultInput from '../../../components/Atomic/Input';
import DefaultTextarea from '../../../components/Atomic/Input/Textarea';
import ProjectTagPicker from '../../../components/Pickers/ProjectTagPicker';
import Stepper from '../../../components/Atomic/Stepper/components/Stepper';
import BoxWithGlowShadow from '../../../components/Atomic/BoxWithGlowShadow';
import DefaultButton from '../../../components/Atomic/Buttons/DefaultButton';
import { useStepperContext } from '../../../components/Atomic/Stepper/StepperProvider';
import MaxWidthContainer from '../../../components/Layout/Containers/MaxWidthContainer';
import WalletEsdtTokenSelector from '../../../components/Tokens/Selectors/WalletEsdtTokenSelector';

import ProjectModel from '../../../modules/Project/ProjectModel';
import TokenModel from '../../../modules/Tokens/models/TokenModel';
import ProjectRepository from '../../../modules/Project/ProjectRepository';
import CreateProjectDto from '../../../modules/Project/dto/create.project.dto';
import { LocalizationContext } from '../../../modules/I18n/LocalizationProvider';
import { ProjectTagsEnum } from '../../../modules/Project/enums/projectTagsEnum';
import { EsdtTokensContext } from '../../../modules/Tokens/Esdt/EsdtTokensContextProvider';

import ProjectValidation from '../ProjectCreateScreen/Validation/ProjectValidation';
import { AccountContext } from '../../../modules/Account/AccountContextProvider';

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.sizes.xxl}px 0;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xxl}px;
  padding-top: ${props => props.theme.sizes.xxxl * 2}px;
  padding-bottom: ${props => props.theme.sizes.xxxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    padding: ${props => props.theme.sizes.xxl * 2}px 0;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    min-height: initial;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    padding: ${props => props.theme.sizes.xxl * 2}px 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  border-radius: 10px;

  @media all and ${({ theme: { device } }) => device.sm} {
    gap: 30px;
  }

  .title {
    font-weight: 600;
  }
`;

const SectionItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.sizes.xl}px;

  @media all and ${({ theme: { device } }) => device.sm} {
    grid-template-columns: 1fr;
  }
`;

const StepperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xl}px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
`;

const RowItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: 30px;

  width: 100%;

  .title {
    font-weight: 600;
  }

  input {
    width: 100%;
  }
`;

const InputsRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

const ProjectEditScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { translations } = useContext(LocalizationContext);
  const translationsObject = translations.screens.projectCreate;

  const { esdtTokens } = useContext(EsdtTokensContext);
  const { incrementCurrentStep, decrementCurrentStep } = useStepperContext();
  const { account } = useContext(AccountContext);
  const [project, setProject] = useState(new ProjectModel());
  const [initialized, setInitialized] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedTokens, setSelectedTokens] = useState<TokenModel[]>([]);

  useEffect(() => {
    if (id) {
      ProjectRepository.getProject(id).then((data: ProjectModel) => {
        setProject(data);
        setInitialized(true);
      });
    }
  }, [id]);

  useEffect(() => {
    if (project.ownerAddress) {
      const accessArray = [...project.managers];
      accessArray.push(project.ownerAddress);
      if (accessArray.length > 0 && account.address) {
        if (accessArray.includes(account.address)) {
          setInitialized(true);
        } else {
          navigate('/');
        }
      }
    }
  }, [account.address, project.ownerAddress]);

  useEffect(() => {
    if (esdtTokens.length > 0 && project.tokens.length > 0) {
      setSelectedTokens(
        project.tokens.map(
          tokenIdentifier => esdtTokens.find((token: TokenModel) => token.identifier === tokenIdentifier) as TokenModel,
        ),
      );
    }
  }, [esdtTokens, project]);

  const onChangeManagersAttribute = (value: string) => {
    setProject(Object.assign(new CreateProjectDto(), project, { managers: value.split(',') }));
  };

  const onChangeAttribute = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setProject(Object.assign(new CreateProjectDto(), project, { [name]: value }));
  };

  const onChangeToken = (value: TokenModel[]) => {
    if (value.length > 0) {
      setProject(
        Object.assign(new CreateProjectDto(), project, { tokens: value.map((item: TokenModel) => item.identifier) }),
      );
      setSelectedTokens(value);
    } else {
      setProject(Object.assign(new CreateProjectDto(), project, { tokens: [] }));
      setSelectedTokens([]);
    }
  };

  const onProjectTagChange = (value: ProjectTagsEnum[]) => {
    setProject(Object.assign(new CreateProjectDto(), project, { tags: value }));
  };

  const onChangeSocialLinkAttribute = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const socialLinks = Object.assign({}, project.socialLinks);
    socialLinks[name] = value;

    setProject(Object.assign(new CreateProjectDto(), project, { socialLinks: socialLinks }));
  };

  const updateProjectCallback = () => {
    ProjectRepository.updateProject(project.id, project).then(() => {
      toast.success('Project updated succesfully');
    });
  };

  const onClickGeneralInfoNext = () => {
    const errors = ProjectValidation.validateProjectGeneralInfo(project);

    if (JSON.stringify(errors) !== JSON.stringify({})) {
      setErrors(errors);
      return;
    }

    incrementCurrentStep();
  };

  const onClickProjectLinksNext = () => {
    const errors = ProjectValidation.validateProjectLinks(project);

    if (JSON.stringify(errors) !== JSON.stringify({})) {
      setErrors(errors);
      return;
    }

    incrementCurrentStep();
  };

  const addNewSmartContract = () => {
    const smartContractsClone = JSON.parse(JSON.stringify(project.smartContracts));
    smartContractsClone.push({ name: '', address: '' });
    setProject(Object.assign(new CreateProjectDto(), project, { smartContracts: smartContractsClone }));
  };

  const onChangeSmartContractName = (index: number, value: string) => {
    const smartContractsClone = JSON.parse(JSON.stringify(project.smartContracts));
    smartContractsClone[index].name = value;
    console.log(smartContractsClone);
    setProject(Object.assign(new CreateProjectDto(), project, { smartContracts: smartContractsClone }));
  };

  const onChangeSmartContractAddress = (index: number, value: string) => {
    const smartContractsClone = JSON.parse(JSON.stringify(project.smartContracts));
    smartContractsClone[index].address = value;
    setProject(Object.assign(new CreateProjectDto(), project, { smartContracts: smartContractsClone }));
  };

  return (
    <ComponentContainer>
      {initialized && (
        <MaxWidthContainer>
          <SectionWrapper>
            <div>
              <Typography.H2>{'Update project'}</Typography.H2>
              <Typography.Copy>{'Update your project info'}</Typography.Copy>
            </div>
            <BoxWithGlowShadow>
              <ContentContainer>
                <Stepper>
                  <Stepper.Steps>
                    <Stepper.Step id="first" name={translationsObject.sections.projectInformation}>
                      <StepperContent>
                        <Typography.Copy className={'title'}>
                          {translationsObject.sections.projectInformation}
                        </Typography.Copy>
                        <SectionItemWrapper>
                          <DefaultInput
                            label={translationsObject.fields.name.label}
                            name={'name'}
                            placeholder={translationsObject.fields.name.placeholder}
                            value={project.name}
                            onChange={onChangeAttribute}
                            error={errors.name ? translationsObject.errors[errors.name] : null}
                          />
                          <DefaultInput
                            label={translationsObject.fields.shortDescription.label}
                            name={'shortDescription'}
                            placeholder={translationsObject.fields.shortDescription.placeholder}
                            value={project.shortDescription}
                            onChange={onChangeAttribute}
                            error={errors.shortDescription ? translationsObject.errors[errors.shortDescription] : null}
                          />
                          <DefaultTextarea
                            label={translationsObject.fields.longDescription.label}
                            name={'longDescription'}
                            placeholder={translationsObject.fields.longDescription.placeholder}
                            value={project.longDescription}
                            onChange={onChangeAttribute}
                            error={errors.longDescription ? translationsObject.errors[errors.longDescription] : null}
                          />
                        </SectionItemWrapper>
                        <SectionItemWrapper>
                          <ProjectTagPicker
                            value={project.tags}
                            onChange={onProjectTagChange}
                            error={errors.tags ? translationsObject.errors[errors.tags] : null}
                          />

                          <WalletEsdtTokenSelector
                            value={selectedTokens}
                            onChange={onChangeToken}
                            label={translationsObject.fields.token.label}
                            placeholder={translationsObject.fields.token.placeholder}
                            error={errors.tokenIdentifier ? translationsObject.errors[errors.tokenIdentifier] : null}
                          />
                        </SectionItemWrapper>

                        <ButtonsContainer>
                          <DefaultButton text={'Next step'} onClick={onClickGeneralInfoNext} />
                        </ButtonsContainer>
                      </StepperContent>
                    </Stepper.Step>
                    <Stepper.Step id="second" name={translationsObject.sections.projectLinks}>
                      <StepperContent>
                        <Typography.Copy className={'title'}>
                          {translationsObject.sections.projectLinks}
                        </Typography.Copy>
                        <SectionItemWrapper>
                          <DefaultInput
                            label={translationsObject.fields.websiteUrl.label}
                            name={'websiteUrl'}
                            placeholder={translationsObject.fields.websiteUrl.placeholder}
                            value={project.websiteUrl}
                            onChange={onChangeAttribute}
                            error={errors.websiteUrl ? translationsObject.errors[errors.websiteUrl] : null}
                          />
                          <DefaultInput
                            label={translationsObject.fields.whitepaperUrl.label}
                            name={'whitepaperUrl'}
                            placeholder={translationsObject.fields.whitepaperUrl.placeholder}
                            value={project.whitepaperUrl}
                            onChange={onChangeAttribute}
                            error={errors.whitepaperUrl ? translationsObject.errors[errors.whitepaperUrl] : null}
                          />
                          <DefaultInput
                            label={translationsObject.fields.roadmapUrl.label}
                            name={'roadmapUrl'}
                            placeholder={translationsObject.fields.roadmapUrl.placeholder}
                            value={project.roadmapUrl}
                            onChange={onChangeAttribute}
                            error={errors.roadmapUrl ? translationsObject.errors[errors.roadmapUrl] : null}
                          />
                          <DefaultInput
                            label={translationsObject.fields.imageUrl.label}
                            name={'imageUrl'}
                            placeholder={translationsObject.fields.imageUrl.placeholder}
                            value={project.imageUrl}
                            onChange={onChangeAttribute}
                            error={errors.imageUrl ? translationsObject.errors[errors.imageUrl] : null}
                          />
                        </SectionItemWrapper>
                        <ButtonsContainer>
                          <DefaultButton text={'Previous step'} onClick={decrementCurrentStep} />
                          <DefaultButton text={'Next step'} onClick={onClickProjectLinksNext} />
                        </ButtonsContainer>
                      </StepperContent>
                    </Stepper.Step>
                    <Stepper.Step id="third" name={translationsObject.sections.socialLinks}>
                      <StepperContent>
                        <Typography.Copy className={'title'}>{translationsObject.sections.socialLinks}</Typography.Copy>
                        <SectionItemWrapper>
                          <DefaultInput
                            label={translationsObject.fields.facebookUrl.label}
                            name={'facebook'}
                            placeholder={translationsObject.fields.facebookUrl.placeholder}
                            value={project.socialLinks.facebook}
                            onChange={onChangeSocialLinkAttribute}
                          />
                          <DefaultInput
                            label={translationsObject.fields.twitterUrl.label}
                            name={'twitter'}
                            placeholder={translationsObject.fields.twitterUrl.placeholder}
                            value={project.socialLinks.twitter}
                            onChange={onChangeSocialLinkAttribute}
                          />
                          <DefaultInput
                            label={translationsObject.fields.telegramUrl.label}
                            name={'telegram'}
                            placeholder={translationsObject.fields.telegramUrl.placeholder}
                            value={project.socialLinks.telegram}
                            onChange={onChangeSocialLinkAttribute}
                          />
                          <DefaultInput
                            label={translationsObject.fields.linkedInUrl.label}
                            name={'linkedIn'}
                            placeholder={translationsObject.fields.linkedInUrl.placeholder}
                            value={project.socialLinks.linkedIn}
                            onChange={onChangeSocialLinkAttribute}
                          />
                          <DefaultInput
                            label={translationsObject.fields.whatsAppUrl.label}
                            name={'whatsApp'}
                            placeholder={translationsObject.fields.whatsAppUrl.placeholder}
                            value={project.socialLinks.whatsApp}
                            onChange={onChangeSocialLinkAttribute}
                          />
                          <DefaultInput
                            label={translationsObject.fields.youtubeUrl.label}
                            name={'youtube'}
                            placeholder={translationsObject.fields.youtubeUrl.placeholder}
                            value={project.socialLinks.youtube}
                            onChange={onChangeSocialLinkAttribute}
                          />
                          <DefaultInput
                            label={translationsObject.fields.instagramUrl.label}
                            name={'instagram'}
                            placeholder={translationsObject.fields.instagramUrl.placeholder}
                            value={project.socialLinks.instagram}
                            onChange={onChangeSocialLinkAttribute}
                          />
                          <DefaultInput
                            label={translationsObject.fields.discordUrl.label}
                            name={'discord'}
                            placeholder={translationsObject.fields.discordUrl.placeholder}
                            value={project.socialLinks.discord}
                            onChange={onChangeSocialLinkAttribute}
                          />
                        </SectionItemWrapper>
                        <ButtonsContainer>
                          <DefaultButton text={'Previous step'} onClick={decrementCurrentStep} />
                          <DefaultButton text={'Update project'} onClick={updateProjectCallback} />
                        </ButtonsContainer>
                      </StepperContent>
                    </Stepper.Step>
                  </Stepper.Steps>
                </Stepper>
              </ContentContainer>
            </BoxWithGlowShadow>
            <ContentContainer>
              <Typography.H3>{'Managers'}</Typography.H3>
              <DefaultTextarea
                label={'Managers'}
                name={'managers'}
                placeholder={'Add managers wallet addresses separated by comma...'}
                value={project.managers.join(',')}
                onChange={e => onChangeManagersAttribute(e.target.value)}
              />
              <ButtonsContainer>
                <DefaultButton type={'primary'} text={'Save'} onClick={updateProjectCallback} />
              </ButtonsContainer>
            </ContentContainer>

            <ContentContainer>
              <Typography.H3>{'Smart contracts'}</Typography.H3>
              <RowItemWrapper>
                {project.smartContracts.map((smartContract, key) => {
                  return (
                    <InputsRowContainer key={key}>
                      <DefaultInput
                        label={'Smart contract name'}
                        name={'name'}
                        placeholder={'Smart contract name'}
                        value={smartContract.name}
                        onChange={e => onChangeSmartContractName(key, e.target.value)}
                      />
                      <DefaultInput
                        label={'Smart contract address'}
                        name={'address'}
                        placeholder={'Smart contract address'}
                        value={smartContract.address}
                        onChange={e => onChangeSmartContractAddress(key, e.target.value)}
                      />
                    </InputsRowContainer>
                  );
                })}
                <DefaultButton type={'primary'} mode={'text'} text={'Add new block'} onClick={addNewSmartContract} />
              </RowItemWrapper>
              <ButtonsContainer>
                <DefaultButton type={'primary'} text={'Save'} onClick={updateProjectCallback} />
              </ButtonsContainer>
            </ContentContainer>
          </SectionWrapper>
        </MaxWidthContainer>
      )}
    </ComponentContainer>
  );
};

export default ProjectEditScreen;
