import React, { useContext } from 'react';
import styled from 'styled-components';

/** Local Imports **/
import TitleParagraphsSection from './TitleParagraphsSection';

import sectionImageLight from './assets/play.png';
import sectionImageDark from './assets/play.png';
import { ThemeContext } from '../../../../../modules/Theme/ThemeProvider';
import EsdtTokenModel from '../../../../../modules/Tokens/models/EsdtTokenModel';

const ComponentWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  flex-direction: column;
  gap: ${props => props.theme.sizes.xxxl}px;
`;

const ComponentContainer = styled.div`
  display: flex;
  height: 100%;

  flex-direction: row;
  column-gap: ${props => props.theme.sizes.xxl}px;
  justify-content: space-between;
  align-items: flex-start;

  @media all and ${({ theme: { device } }) => device.md} {
    flex-direction: row;
    justify-content: center;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    row-gap: ${props => props.theme.sizes.xxl}px;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    row-gap: ${props => props.theme.sizes.lg}px;
  }
`;

const ImageComponent = styled.div<{ src: string }>`
  display: flex;
  flex-grow: 1;
  height: 100%;
  max-height: 800px;
  min-height: 650px;
  opacity: 1;

  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});

  @media all and ${({ theme: { device } }) => device.lg} {
    min-height: 500px;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    background-image: none;
    min-height: 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.sizes.xl}px;
  align-self: center;

  max-width: 800px;

  @media all and ${({ theme: { device } }) => device.md} {
    align-self: flex-start;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    align-self: flex-start;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    max-width: initial;
  }
`;

const HeroSection = ({ token }: { token: EsdtTokenModel }) => {
  const { mode } = useContext(ThemeContext);

  const sectionImage = mode === 'dark' ? sectionImageDark : sectionImageLight;

  return (
    <ComponentWrapper>
      <ComponentContainer>
        <ImageComponent src={sectionImage}>
          <ContentContainer>
            <TitleParagraphsSection
              title={'Edit token'}
              paragraphs={[
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
              ]}
              token={token}
            />
          </ContentContainer>
        </ImageComponent>
      </ComponentContainer>
    </ComponentWrapper>
  );
};

export default HeroSection;
