import Environment from '../Environment';

import EsdtTokenModel from '../Tokens/models/EsdtTokenModel';
import ApiClient from '../Http/ApiClient';
import { PortfolioType } from './types/portfolio.type';
import FeedSettingsModel from './models/feed.settings.model';
import PublicAccountModel from './models/public.account.model';

const API_ADDRESS = Environment.microservice.spaceApi;

export default class AccountService {
  private static api = new ApiClient();
  static filtersToString = (filters: any) =>
    Object.entries(filters)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

  static getInformation(walletAddress: string) {
    if (!walletAddress) {
      return Promise.resolve({});
    }

    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .get(`/accounts/${walletAddress}`)
      .then(response => response.data)
      .catch(() => Promise.resolve({}));
  }

  static async getEsdtTokens(address: string) {
    if (!address) {
      return Promise.resolve([]);
    }

    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .get(`/accounts/${address}/tokens`)
      .then(response => response.data)
      .then(response => this.arrayToModels(response, EsdtTokenModel))
      .catch(() => []);
  }

  static async getAccountFarms(address: string) {
    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .get(`/accounts/${address}/farms`)
      .then(response => response.data)
      .catch(() => []);
  }

  static async getAccountPortfolio(): Promise<PortfolioType> {
    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .get(`/accounts/portfolio/me`)
      .then(response => response.data)
      .catch(() => []);
  }

  static async getOwnAccount(): Promise<PublicAccountModel> {
    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .get(`/accounts/me`)
      .then(response => new PublicAccountModel(response.data));
  }

  static async checkHeroTagExists(herotag: string) {
    return this.api
      .withBaseUrl(Environment.elrond.apiAddress)
      .get(`/usernames/${herotag.trim()}`)
      .then(response => !!response.data)
      .catch(() => false);
  }

  static async updateFeedSettings(feedSettings: FeedSettingsModel): Promise<any> {
    return this.api
      .withBaseUrl(API_ADDRESS)
      .withAuthentication()
      .put('/accounts/feed/settings', feedSettings.serialize())
      .then(response => response.data)
      .catch(() => []);
  }

  static arrayToModels = (data: any, model: any) => data.map((item: any) => this.itemToModel(item, model));
  static itemToModel = (item: any, model: any) => new model(item);
}
