import SerializableModel from '../../Core/models/serializable.model';
import { ProjectTagsEnum } from '../enums/projectTagsEnum';

export default class CreateProjectDto extends SerializableModel {
  name: string;
  shortDescription: string;
  longDescription: string;
  imageUrl: string;
  websiteUrl: string;
  whitepaperUrl: string;
  roadmapUrl: string;
  tokens: string[] = [];
  managers: string[] = [];
  smartContracts: {
    name: string;
    address: string;
  }[] = [
    {
      name: '',
      address: '',
    },
  ];
  tags: ProjectTagsEnum[];

  socialLinks: { [key: string]: string | undefined } = {
    facebook: undefined,
    twitter: undefined,
    telegram: undefined,
    linkedIn: undefined,
    whatsApp: undefined,
    youtube: undefined,
    instagram: undefined,
    discord: undefined,
  };
}
