import React, { useContext } from 'react';
import styled from 'styled-components';
import Typography from '../../../../../../components/Atomic/Typography';
import { AccountContext } from '../../../../../../modules/Account/AccountContextProvider';
import EsdtTokenModel from '../../../../../../modules/Tokens/models/EsdtTokenModel';

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.sizes.xl}px;

  ${Typography.H1} {
    span {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type TitleParagraphsSectionProps = {
  title: string;
  paragraphs: string[];
  token: EsdtTokenModel;
};

const TitleParagraphsSection = ({ title, paragraphs = [], token }: TitleParagraphsSectionProps) => {
  const { account } = useContext(AccountContext);
  return (
    <ComponentContainer>
      <Typography.H2>{title}</Typography.H2>
      <ParagraphWrapper>
        {paragraphs.map((paragraph, index) => (
          <Typography.Copy key={index}>{paragraph}</Typography.Copy>
        ))}
      </ParagraphWrapper>
    </ComponentContainer>
  );
};

export default TitleParagraphsSection;
