import botsIcon from './../../assets/icons/Name=Bots.png';
import eventsIcon from './../../assets/icons/Name=Events.png';
import snapshotsIcon from './../../assets/icons/Name=Snapshots.png';
import managementIcon from './../../assets/icons/Name=Management.png';

const items = [
  {
    icon: managementIcon,
    title: 'Token Management',
    paragraphs: [
      'Manage your tokens properties, branding from the ESDT SPACE platform.',
      'Mint, burn, wipe tokens you have permissions for.',
    ],
    link: undefined,
    disabled: true,
  },
  {
    icon: snapshotsIcon,
    title: 'Token Snapshots',
    paragraphs: [
      'Schedule snapshots for any ESDT or NFT token straight from our app.',
      'Compute easy to use and share reports and averages for any token.',
    ],
    link: undefined,
    disabled: true,
  },
  {
    icon: eventsIcon,
    title: 'Events & Webhooks',
    paragraphs: [
      'Get notified when a certain event occurs on the blockchain.',
      'This event can be anything from a simple token (or NFT) transfer to a specific function being called on a smart contract.',
    ],
    link: undefined,
    disabled: true,
  },
  {
    icon: botsIcon,
    title: 'Bots',
    paragraphs: [
      'Enhance the experience for your members on your Telegram and Discord channels using our bots.',
      'Easy and reliable balance check, holder verification and role assignation for your discord server.',
    ],
    link: undefined,
    disabled: true,
  },
];

export default items;
