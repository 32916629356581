import React, { useContext } from 'react';

import { LocalizationContext } from '../../../../modules/I18n/LocalizationProvider';
import LegalArticlesContent from '../../../../components/screens/legal/legal.articles.content';

const TermsAndConditionsScreen = () => {
  const { translations } = useContext(LocalizationContext);
  const translationsObject = translations.screens.termsAndConditions;

  return (
    <LegalArticlesContent
      title={translationsObject.title}
      paragraphs={translationsObject.paragraphs}
      articles={translationsObject.articles}
    />
  );
};

export default TermsAndConditionsScreen;
