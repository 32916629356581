import React from 'react';

import pageStyles from '../../../../components/screens/common/page.blocks/page.module.scss';
import JoinCommunitySection from '../../../../components/screens/common/join.community.section';

/** Local Components **/
import HeroSection from './components/hero.section';
import ToolsSection from './components/tools.section';
import ManageProjectSection from './components/manage.projects';
import InlineLinksSection from './components/inline.links.section';
import RegisterProjectSection from './components/register.projects';

export default function BusinessHomepageScreen() {
  return (
    <div className={pageStyles.screenContainer}>
      <div className={pageStyles.heroSectionWrapper}>
        <HeroSection />
      </div>
      <div className={pageStyles.sectionsMaxWidthWrapper}>
        <InlineLinksSection />
        <RegisterProjectSection />
        <ManageProjectSection />
        <ToolsSection />
        <JoinCommunitySection />
      </div>
    </div>
  );
}
