import React from 'react';

import styles from './tools.item.module.scss';
import Typography from '../../../../../../../components/Atomic/Typography';

export type TToolItem = {
  icon: string;
  title: string;
  link: string | undefined;
  paragraphs: string[];
  disabled?: boolean;
};

type Props = {
  item: TToolItem;
};

export default function ToolItem(props: Props) {
  const { item } = props;

  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemIcon} style={{ backgroundImage: `url(${item.icon})` }} />
      <div className={styles.contentContainer}>
        <Typography.H4>{item.title}</Typography.H4>
        <div className={styles.paragraphsContainer}>
          {item.paragraphs.map((paragraph, index) => (
            <Typography.Copy key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </div>

        <a data-disabled={!!item.disabled} className={styles.itemLink} href={item.link}>
          {!!item.disabled ? 'Coming Soon' : 'Learn More'}
        </a>
      </div>
    </div>
  );
}
