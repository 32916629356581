import React from 'react';
import styled from 'styled-components';
import { OptionProps } from 'react-select';

import Icon from '../../../Atomic/Icon';
import TokenModel from '../../../../modules/Tokens/models/TokenModel';
import { transformNumber } from '../../../../modules/Helpers/numberTransformer';

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px;

  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};

  &[data-focused='true'] {
    background-color: ${props => props.theme.colors.highlight};
  }

  &[data-selected='true'] {
    color: ${props => props.theme.colors.textOnPrimary};
    background-color: ${props => props.theme.colors.primary};
  }
`;

const TokenInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  & div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
  }

  & > :first-child {
    font-weight: bold;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IdentifierContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  display: flex;
  margin-right: 10px;
  border-radius: 50%;
`;

const Container = OptionContainer as any;

type Option = OptionProps & {
  data: TokenModel;
  showBalance: boolean;
  selectProps: {
    showBalance: boolean;
  };
};

export const OptionComponent = (props: Option) => {
  const tokenBalanceValue = props.data && transformNumber(props.data.getDenominatedBalance());

  return (
    <Container
      data-selected={props.isSelected}
      data-focused={props.isFocused}
      data-disabled={props.isFocused}
      {...props.innerProps}
    >
      {props.data.hasAssetImage() && (
        <LogoContainer>
          <img src={props.data.getAssetImageUrl() as string} width={32} height={32} />
        </LogoContainer>
      )}
      <TokenInfoContainer>
        <NameContainer>
          <div>{props.data.name}</div>
          {props.data.isVerified() && <Icon name={'verified'} size={16} />}
        </NameContainer>
        <IdentifierContainer>
          <div>{props.data.identifier}</div>
          {/*{props.showBalance && (*/}
          <div title={tokenBalanceValue?.formattedValue}>
            {tokenBalanceValue?.approximatedValue} {props.data.ticker}
          </div>
          {/*)}*/}
        </IdentifierContainer>
      </TokenInfoContainer>
    </Container>
  );
};
