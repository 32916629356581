import React, { useContext } from 'react';
import styled from 'styled-components';

import DefaultRating from '../../Rating';
import Typography from '../../Atomic/Typography';
import DefaultButton from '../../Atomic/Buttons/DefaultButton';

import ProjectModel from '../../../modules/Project/ProjectModel';
import { EsdtTokensContext } from '../../../modules/Tokens/Esdt/EsdtTokensContextProvider';
import useProjectTokenImageUrl from '../../../modules/Project/hooks/useProjectTokenImageUrl';

import shortLogo from '../../Atomic/Branding/AppIcon/assets/short_logo.svg';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.md}px;
  padding: ${props => props.theme.sizes.lg}px;

  background: ${props => props.theme.colors.surface};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;

  ${Typography.H4} {
    text-align: center;
    text-transform: uppercase;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ProjectDescription = styled(Typography.Copy)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const TokenListIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.sizes.sm}px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.sizes.md}px;
  background: ${props => props.theme.colors.accentBackground};
  border-radius: 10px;
  gap: ${props => props.theme.sizes.lg}px;
`;

const DetailsItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: ${props => props.theme.sizes.lg}px;

  .label {
    font-weight: bold;
  }
`;

const TagsLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 10px;
  background: ${props => props.theme.colors.border};
  border-radius: 5px;

  .tags {
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const BottomContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

const BottomContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${props => props.theme.sizes.lg}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const ProjectCard = ({ project }: { project: ProjectModel }) => {
  const projectImageUrl = useProjectTokenImageUrl(project);
  const logoUrl = projectImageUrl ? projectImageUrl : shortLogo;
  const { esdtTokens } = useContext(EsdtTokensContext);

  const getTokenLogo = (identifier: string) => {
    const tokenInfo = esdtTokens?.find(token => token?.identifier === identifier);
    if (tokenInfo?.hasAssetImage()) {
      return tokenInfo.getAssetImageUrl();
    }
    return null;
  };

  return (
    <CardContainer>
      <LogoContainer>
        <img width={60} height={60} src={logoUrl} />
      </LogoContainer>
      <Typography.H4>{project.name}</Typography.H4>
      <ProjectDescription>{project.shortDescription}</ProjectDescription>

      <BottomContainerRow>
        <BottomContainerColumn>
          <DetailsContainer>
            <DetailsItemRow>
              <Typography.Copy className={'label'}>{'Tokens'}</Typography.Copy>
              <TokenListIconsContainer>
                {project.tokens.map(identifier => {
                  const logo = getTokenLogo(identifier);
                  return (
                    <React.Fragment key={identifier}>
                      {logo ? (
                        <img width={30} height={30} src={logo} />
                      ) : (
                        <Typography.Copy>{identifier}</Typography.Copy>
                      )}
                    </React.Fragment>
                  );
                })}
              </TokenListIconsContainer>
            </DetailsItemRow>
            <DetailsItemRow>
              <Typography.Copy className={'label'}>{'Categories'}</Typography.Copy>
              {project.tags.map((tag, index) => (
                <TagsLabel key={index}>
                  <Typography.Copy className={'tags'} size={'tiny'}>
                    {tag}
                  </Typography.Copy>
                </TagsLabel>
              ))}
            </DetailsItemRow>
            <DetailsItemRow>
              <Typography.Copy className={'label'}>{'Rating'}</Typography.Copy>
              <DefaultRating totalFilledStars={3} readonly={true} size={22} />
            </DetailsItemRow>
          </DetailsContainer>
          <ButtonContainer>
            <DefaultButton
              mode={'outline'}
              type={'primary'}
              text={'View Project'}
              to={'/app/projects/' + project.id + '/view'}
            />
          </ButtonContainer>
        </BottomContainerColumn>
      </BottomContainerRow>
    </CardContainer>
  );
};

export default ProjectCard;
