import intl from 'react-intl-universal';

import Environment from '../Environment';
import { APP_LANGUAGE_STORAGE_KEY, TRANSLATIONS } from './LocalizationConstants';

export default function determineInitialLocale() {
  let currentLocale = intl.determineLocale({
    urlLocaleKey: APP_LANGUAGE_STORAGE_KEY,
    cookieLocaleKey: APP_LANGUAGE_STORAGE_KEY,
    localStorageLocaleKey: APP_LANGUAGE_STORAGE_KEY,
  });

  if (!TRANSLATIONS.hasOwnProperty(currentLocale)) {
    currentLocale = Environment.defaultLanguage;
  }

  return currentLocale;
}

/* eslint-disable */
// @ts-ignore
String.prototype.replaceParameters =
  function () {
    'use strict';
    // @ts-ignore
    let str = this.toString();
    if (arguments.length) {
      const typeOfArguments = typeof arguments[0];
      let key;
      const args =
        typeOfArguments === 'string' || typeOfArguments === 'number'
          ? Array.prototype.slice.call(arguments)
          : arguments[0];

      for (key in args) {
        str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
      }
    }

    return str;
  };
/* eslint-enable */
