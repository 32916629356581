import SerializableModel from './serializable.model';

export default class SkipLimitPaginationModel<T> extends SerializableModel {
  skip = 0;
  limit = 10;
  totalCount = 0;
  results: T[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(params: any) {
    super();
    this.initializeModel(params);
    this.skip = parseInt(params.skip);
    this.limit = parseInt(params.limit);
  }

  isLastPage(): boolean {
    return this.totalCount - this.skip <= this.limit;
  }

  getCurrentPage(): number {
    if (this.totalCount <= 0 || this.totalCount <= this.limit) {
      return 1;
    }

    return Math.ceil(this.skip / this.limit) + 1;
  }
}
