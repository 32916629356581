import React from 'react';

import DefaultButton from '../../../../../../components/Atomic/Buttons/DefaultButton';
import heroStyles from '../../../../../../components/screens/common/page.blocks/hero.module.scss';

/** Local Imports **/
import styles from './hero.section.module.scss';
import sectionImage from './assets/hero-illustration.png';

const HeroSection = () => {
  return (
    <div className={styles.componentWrapper}>
      <div className={styles.componentContent}>
        <div className={styles.limitedContent}>
          <div className={heroStyles.heroContentContainer}>
            <div>
              <h1 className={heroStyles.heroTitle}>ESDT SPACE</h1>
              <h2 className={heroStyles.heroSubtitle}>Partners</h2>
            </div>
            <div className={heroStyles.heroParagraphsWrapper}>
              <div className={heroStyles.heroParagraph}>
                As a partner of EsdtSpace, you will have the opportunity to join a group of leaders who are dedicated to
                simplifying the blockchain experience for all users.
              </div>

              <div className={heroStyles.heroParagraph}>
                Our partnerships are designed to enable faster onboarding experiences and a host of other value-added
                services. By working together, we can create powerful, intuitive blockchain experiences that drive
                growth and success for both of our businesses.
              </div>

              <div className={heroStyles.heroParagraph}>
                Join us in our mission to make blockchain accessible and intuitive for everyone. Together, we can make a
                real difference in the industry and create a brighter future for all.
              </div>
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            <DefaultButton type={'primary'} text={'BECOME A PARTNER'} />
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={sectionImage} />
      </div>
    </div>
  );
};

export default HeroSection;
