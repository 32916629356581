import React from 'react';
import styled from 'styled-components';

import FAQItems from '../../../../components/FAQItems';
import Typography from '../../../../components/Atomic/Typography';

import HeroSection from './components/hero.section';
import SolutionsSection from './components/solutions.section';
import blockStyles from '../../../../components/screens/common/page.blocks/page.module.scss';
import TeamSection from './components/team.section';

const TitleAndFaq = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;

  ${Typography.H2} {
    font-weight: bold;
  }
`;

const faqItems = [
  {
    title: 'What is your mission?',
    description:
      'To be the leading blockchain service provider on MultiversX and help as many projects as possible with a smooth transition to blockchain while saving the costs.',
  },
  {
    title: 'Why are you building on the MultiversX (Elrond) blockchain?',
    description:
      'The MultiversX blockchain has many advantages that the ESDT SPACE team can utilize. For example:\n' +
      'Best performance - 15,000 current TPS, 6s block time, $0.001 tx cost, can scale beyond 100,000 (263,000 TPS in testnet)\n' +
      'Best scalability - Scales via Adaptive State Sharding, consensus via Secure Proof of Stake\n' +
      'Extremely reliable - Zero downtime since launch\n' +
      'Best mix of Dapp ease of use with security embedded - Maiar money app with progressive security, social recovery, embedded DeFi\n' +
      'Next-gen of SDK & tools for blockchain dev',
  },
  {
    title: 'I want to become an ambassador, how can I apply?',
    description:
      'We appreciate all interest in our project and would love to talk to you about the possibility of you becoming an ambassador. If you have any questions about becoming an ambassador, please feel free to contact us at contact@esdt.space.',
  },
];

const AboutUsScreen = () => {
  return (
    <div className={blockStyles.screenContainer}>
      <div className={blockStyles.heroSectionWrapper}>
        <HeroSection />
      </div>
      <div className={blockStyles.sectionsMaxWidthWrapper}>
        <SolutionsSection />
        <TeamSection />
        <TitleAndFaq>
          <h3>{'Frequently Asked Questions'}</h3>
          <Typography.Copy>
            Here you can find the most frequently asked questions and the answers to them. If you have other questions,
            feel free to drop an email to contact@esdt.space.
          </Typography.Copy>
          <FAQItems items={faqItems} />
        </TitleAndFaq>
      </div>
    </div>
  );
};

export default AboutUsScreen;
