import { getChainID } from '@elrondnetwork/dapp-core/utils';
import { INonce, Interaction } from '@elrondnetwork/erdjs/out';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';

const defaultTransactionParams = {
  errorMessage: 'There was an error',
  successMessage: 'Transaction processed',
  processingMessage: 'Processing transaction',
  submittedMessage: 'Transaction submitted',
  transactionDuration: 20000,
};

type TransactionDisplayInfo = {
  errorMessage: string;
  successMessage: string;
  processingMessage: string;
  submittedMessage: string;
  transactionDuration: number;
};

export default async function sendSmartContractTransaction(
  interaction: Interaction,
  addressNonce: INonce,
  transactionParams: Partial<TransactionDisplayInfo> = {},
  gasLimit = 10000000,
) {
  const chainId = getChainID();
  interaction = interaction.withNonce(addressNonce).withGasLimit(gasLimit).withChainID(chainId);

  const transaction = interaction.buildTransaction();

  const { sessionId } = await sendTransactions({
    transactions: transaction,
    transactionsDisplayInfo: {
      ...defaultTransactionParams,
      ...transactionParams,
    },
    completedTransactionsDelay: 100,
    redirectAfterSigning: false,
  });

  return sessionId;
}
