import React from 'react';
import styles from './inline.links.section.module.scss';

import InlineLink from '../../../../../../components/screens/common/inline.link';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

export default function InlineLinksSection() {
  return (
    <div className={styles.sectionContainer}>
      <h3 className={`${sectionStyles.sectionSubtitle} ${styles.sectionTitle}`}>Explore the Ecosystem</h3>
      <div className={styles.itemsWrapper}>
        <InlineLink title={'Whitepaper'} isComingSoon />
        <InlineLink title={'Tokenomics'} isComingSoon />
        <InlineLink title={'Roadmap'} isComingSoon />
        <InlineLink title={'Partners'} url={'/partners'} />
      </div>
    </div>
  );
}
