import React, { useContext, useMemo } from 'react';

import DefaultSelect from '../../Atomic/Pickers/Select';

import { LocalizationContext } from '../../../modules/I18n/LocalizationProvider';
import { ProjectTagsEnum } from '../../../modules/Project/enums/projectTagsEnum';

type Props = {
  value: ProjectTagsEnum | ProjectTagsEnum[];
  onChange: (tags: ProjectTagsEnum[]) => void;

  [key: string]: unknown;
};

type TagOption = { value: ProjectTagsEnum; label: string };

export default function ProjectTagPicker(props: Props) {
  const { value, onChange, ...rest } = props;

  const { translations } = useContext(LocalizationContext);
  const translationsObject = translations.screens.projectCreate;
  const industriesTranslations = translations.components.project.tags;

  const options = useMemo(() => {
    const industries: TagOption[] = [];

    Object.entries(ProjectTagsEnum).forEach(([, value]) => {
      industries.push({
        value: value,
        label: industriesTranslations[value] || value,
      });
    });

    return industries;
  }, [industriesTranslations]);

  const selectedOption = useMemo(() => options.find((item: TagOption) => item.value === value), [value, options]);

  return (
    <DefaultSelect
      name={'tags'}
      options={options}
      value={selectedOption}
      label={translationsObject.fields.tags.label}
      placeholder={translationsObject.fields.tags.placeholder}
      onChange={(option: any) => onChange([option.value as ProjectTagsEnum])}
      {...rest}
    />
  );
}
