import React from 'react';
import Typography from '../../../../../components/Atomic/Typography';
import EsdtTokenModel from '../../../../../modules/Tokens/models/EsdtTokenModel';
import styles from './TokenPropertiesSection.module.scss';

const TokenPropertiesSection = ({ token }: { token: EsdtTokenModel }) => {
  const getTokenLogo = () => {
    let logo = null;
    if (token?.hasAssetImage()) {
      logo = token.getAssetImageUrl();
    }

    return logo ? <img width={30} height={30} src={logo} /> : <Typography.Copy>{'No logo'}</Typography.Copy>;
  };

  return (
    <div className={styles.section}>
      <Typography.H2>{'Token Properties'}</Typography.H2>
      <div className={styles.tokens}>
        <div className={styles.card}>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Logo'}</Typography.Copy>
            {getTokenLogo()}
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Identifier'}</Typography.Copy>
            <Typography.Copy>{token.identifier}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Owner'}</Typography.Copy>
            <Typography.Copy>{token.owner}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Type'}</Typography.Copy>
            <Typography.Copy>{token.type}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Decimals'}</Typography.Copy>
            <Typography.Copy>{token.decimals}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Paused'}</Typography.Copy>
            <Typography.Copy>{token.isPaused ? 'Yes' : 'No'}</Typography.Copy>
          </div>
          {token.nonce && (
            <div className={styles.row}>
              <Typography.Copy className={'name'}>{'Nonce'}</Typography.Copy>
              <Typography.Copy>{token.nonce}</Typography.Copy>
            </div>
          )}
          {token.price ? (
            <div className={styles.row}>
              <Typography.Copy className={'name'}>{'Price'}</Typography.Copy>
              <Typography.Copy>{`$${token.price}`}</Typography.Copy>
            </div>
          ) : null}
          {token.supply && (
            <div className={styles.row}>
              <Typography.Copy className={'name'}>{'Supply'}</Typography.Copy>
              <Typography.Copy>{token.supply}</Typography.Copy>
            </div>
          )}
          {token.burnt && (
            <div className={styles.row}>
              <Typography.Copy className={'name'}>{'Burnt'}</Typography.Copy>
              <Typography.Copy>{token.burnt}</Typography.Copy>
            </div>
          )}
          {token.minted && (
            <div className={styles.row}>
              <Typography.Copy className={'name'}>{'Minted'}</Typography.Copy>
              <Typography.Copy>{token.minted}</Typography.Copy>
            </div>
          )}
        </div>
        <div className={styles.card}>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Can Burn'}</Typography.Copy>
            <Typography.Copy>{token.canBurn ? 'Yes' : 'No'}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Can Change Owner'}</Typography.Copy>
            <Typography.Copy>{token.canChangeOwner ? 'Yes' : 'No'}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Can Freeze'}</Typography.Copy>
            <Typography.Copy>{token.canFreeze ? 'Yes' : 'No'}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Can Mint'}</Typography.Copy>
            <Typography.Copy>{token.canMint ? 'Yes' : 'No'}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Can Pause'}</Typography.Copy>
            <Typography.Copy>{token.canPause ? 'Yes' : 'No'}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Can Upgrade'}</Typography.Copy>
            <Typography.Copy>{token.canUpgrade ? 'Yes' : 'No'}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Can Wipe'}</Typography.Copy>
            <Typography.Copy>{token.canWipe ? 'Yes' : 'No'}</Typography.Copy>
          </div>
          <div className={styles.row}>
            <Typography.Copy className={'name'}>{'Can add special roles'}</Typography.Copy>
            <Typography.Copy>{token.canAddSpecialRoles ? 'Yes' : 'No'}</Typography.Copy>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenPropertiesSection;
