import React, { useContext } from 'react';
import ProjectModel from '../../../../../modules/Project/ProjectModel';
import styled from 'styled-components';
import Typography from '../../../../../components/Atomic/Typography';
import { EsdtTokensContext } from '../../../../../modules/Tokens/Esdt/EsdtTokensContextProvider';
import DefaultButton from '../../../../../components/Atomic/Buttons/DefaultButton';
import shortLogo from '../../../../../components/Atomic/Branding/AppIcon/assets/short_logo.svg';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;
  height: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.sizes.lg}px;
  gap: ${props => props.theme.sizes.lg}px;
  background: ${props => props.theme.colors.surface};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  height: 100%;
`;

const TokenRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TokenLogoAndName = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.sizes.lg}px;
  align-items: center;
`;

const ProjectTokens = ({ project }: { project: ProjectModel }) => {
  const { esdtTokens } = useContext(EsdtTokensContext);

  const getTokenInfo = (identifier: string) => {
    return esdtTokens?.find(token => token?.identifier === identifier);
  };

  return (
    <SectionContainer>
      <Typography.H2>{'Project Tokens'}</Typography.H2>
      <CardContainer>
        {project.tokens.map(tokenIdentifier => {
          const tokenInfo = getTokenInfo(tokenIdentifier);
          const logo = tokenInfo?.hasAssetImage() ? tokenInfo.getAssetImageUrl() : shortLogo;

          return (
            <TokenRow key={tokenIdentifier}>
              <TokenLogoAndName>
                <img width={60} height={60} src={logo ? logo : ''} />
                <Typography.Copy>{tokenInfo?.name}</Typography.Copy>
              </TokenLogoAndName>
              <DefaultButton
                mode={'outline'}
                type={'primary'}
                text={'View'}
                to={'/app/tokens/' + tokenIdentifier + '/view'}
              />
            </TokenRow>
          );
        })}
      </CardContainer>
    </SectionContainer>
  );
};

export default ProjectTokens;
