import React from 'react';
import styles from './team.section.module.scss';
import sectionStyles from '../../../../../../components/screens/common/page.blocks/section.module.scss';

import allTeam, { TeamMember } from './team.data';
import Icon from '../../../../../../components/Atomic/Icon';

type TeamMemberProps = {
  item: TeamMember;
};

function TeamMemberItem(props: TeamMemberProps) {
  const { item } = props;

  return (
    <div className={styles.teamMember}>
      <div className={styles.avatar} style={{ backgroundImage: `url(${item.image})` }} />

      <div className={styles.nameAndTitle}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.title}>{item.position}</div>
      </div>

      <div className={styles.description}>{item.description}</div>

      <div className={styles.socialLinksWrapper}>
        {Object.entries(item.social).map(([key, value]) => {
          return (
            <a className={styles.socialIcon} key={key} href={value} target={'_blank'} rel="noreferrer">
              <Icon name={`fa-brands fa-${key}`} />
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default function TeamSection() {
  return (
    <div>
      <div className={styles.sectionContainer}>
        <div className={styles.titleWrapper}>
          <h2 className={sectionStyles.sectionTitle}>Our Team</h2>
          <div className={sectionStyles.sectionParagraph}>
            At EsdtSpace, we are proud to have a team of talented and experienced professionals who are dedicated to
            delivering the best possible results for our clients. With over 45 years of combined experience, our team
            has the knowledge and expertise to handle any challenge that comes our way.
          </div>

          <div className={sectionStyles.sectionParagraph}>
            But we are more than just a group of experienced professionals - we are a diverse and dynamic team that
            knows how to work together to achieve our goals. From developing customized solutions to collaborating on
            challenging projects, we have the teamwork, expertise, and perspective to deliver exceptional results every
            time.
          </div>

          <div className={sectionStyles.sectionParagraph}>
            So if you are looking for a team of dedicated, talented professionals who can help you achieve your goals,
            look no further than EsdtSpace. We are here to help you succeed!
          </div>
        </div>

        <div className={styles.teamMembersWrapper}>
          {allTeam.map((member, index) => (
            <TeamMemberItem key={index} item={member} />
          ))}
        </div>
      </div>
    </div>
  );
}
