import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './DefaultButton.module.scss';
import Icon from '../../Icon';

type TButtonIconProps = {
  name: string;
  size?: number;
};

type TButtonProps = {
  children?: React.ReactElement | string;
  text?: string;
  textOnSmallScreen?: string;
  buttonType?: 'primary' | 'secondary';
  // eslint-disable-next-line react/require-default-props
  iconProps?: TButtonIconProps;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  type?: string;
  mode?: string;
  to?: string;
};

function DefaultButton({
  children,
  text,
  textOnSmallScreen,
  iconProps,
  disabled,
  loading,
  onClick,
  type,
  mode,
  to,
  ...props
}: TButtonProps) {
  const navigate = useNavigate();
  const buttonHasOnClick = useMemo(() => typeof onClick === 'function', [onClick]);

  const buttonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return false;

    if (buttonHasOnClick) {
      if (onClick) {
        onClick();
      }
    } else {
      if (to) {
        navigate(to);
      }
    }

    return false;
  };

  return (
    <div
      className={styles.container}
      data-type={type}
      data-mode={mode}
      onClick={buttonClick}
      data-disabled={disabled}
      {...props}
    >
      <div className={styles.content}>
        <span>
          {children}
          {text}
          {textOnSmallScreen}
        </span>
        {!loading && iconProps && <Icon {...iconProps} />}
      </div>
    </div>
  );
}

export default DefaultButton;
