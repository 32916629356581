import React, { createContext, useEffect, useState } from 'react';

import SkipLimitPaginationModel from '../Core/models/skip.limit.pagination.model';

/** Local Imports **/
import ProjectModel from './ProjectModel';
import ProjectRepository from './ProjectRepository';
import ProjectFilterModel from './ProjectFilterModel';

const initialState = {
  isLoading: false,
  isInitialized: false,
  projects: [] as ProjectModel[],
  hasNextPage: false,
  currentPage: 1,
  currentLimit: 0,
};

type ProjectListProviderProps = {
  children: JSX.Element;
  filters: ProjectFilterModel;
};

export const OwnProjectsListContext = createContext(initialState);
export const OwnProjectListProvider = ({ children, filters }: ProjectListProviderProps) => {
  const [isLoading, setIsLoading] = useState(initialState.isLoading);
  const [isInitialized, setIsInitialized] = useState(initialState.isInitialized);
  const [projects, setProjects] = useState<ProjectModel[]>(initialState.projects);
  const [hasNextPage, setHasNextPage] = useState(initialState.hasNextPage);
  const [currentPage, setCurrentPage] = useState(initialState.currentPage);
  const [currentLimit, setCurrentLimit] = useState(filters.limit);

  useEffect(() => {
    setIsLoading(true);
    ProjectRepository.findOwnProjects(filters)
      .then((response: SkipLimitPaginationModel<ProjectModel>) => {
        if (response.getCurrentPage() === 1) {
          setProjects(response.results.map(item => new ProjectModel(item)));
        } else {
          setProjects(prevState => prevState.concat(response.results.map(item => new ProjectModel(item))));
        }

        setHasNextPage(!response.isLastPage());
        setCurrentPage(response.getCurrentPage());
        setCurrentLimit(response.limit);
        return response;
      })
      .then(() => {
        setIsLoading(false);
        setIsInitialized(true);
      })
      .catch(() => {
        setIsInitialized(true);
        setIsLoading(false);
      });
  }, [filters]);

  return (
    <OwnProjectsListContext.Provider
      value={{ projects, isLoading, isInitialized, hasNextPage, currentPage, currentLimit }}
    >
      {children}
    </OwnProjectsListContext.Provider>
  );
};
