import React, { PropsWithChildren } from 'react';
import styles from './Modal.module.scss';
import Icon from '../Icon';

type Props = PropsWithChildren & {
  title?: string;
  onClose?: () => void;
};

export default function Modal(props: Props) {
  const { children, title = '', onClose = () => {} } = props;

  return (
    <div className={`${styles.wrapper} modal`}>
      <div className={`${styles.container} modal-container`}>
        <div className={styles.topBar}>
          <div>{title}</div>
          <div className={styles.closeButtonContainer} onClick={onClose}>
            <Icon name={'fa-solid fa-xmark'} />
          </div>
        </div>
        <div className={`${styles.content} modal-content`}>{children}</div>
      </div>
    </div>
  );
}
