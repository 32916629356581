import React, { useContext } from 'react';
import styled from 'styled-components';

import Typography from '../../../../Atomic/Typography';

import { ReactComponent as EmptyStateLight } from '../assets/empty-state-light.svg';
import { ReactComponent as EmptyStateDark } from '../assets/empty-state-dark.svg';

import DefaultButton from '../../../../Atomic/Buttons/DefaultButton';
import { ThemeContext } from '../../../../../modules/Theme/ThemeProvider';

const ComponentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: ${props => props.theme.sizes.xxl * 3}px;
  padding: ${props => props.theme.sizes.xxl * 3}px ${props => props.theme.sizes.xxl * 3}px;

  background-color: ${props => props.theme.colors.background};
  border-radius: var(--default-border-radius);

  svg {
    width: 256px;
    height: 256px;
    max-width: 80%;
    max-height: 256px;
  }

  @media all and ${({ theme: { device } }) => device.md} {
    gap: ${props => props.theme.sizes.xxl}px;
    padding: ${props => props.theme.sizes.xxl}px;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    padding: ${props => props.theme.sizes.xl}px;
    gap: ${props => props.theme.sizes.xl}px;

    svg {
      width: 192px;
      height: 192px;
      max-width: 80%;
      max-height: 192px;
    }
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    flex-direction: column-reverse;
  }
`;

const TextsWrapper = styled.div`
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: ${props => props.theme.sizes.xs}px;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: ${props => props.theme.sizes.md}px;
`;

type Props = {
  loading: boolean;
};

const EmptyProjectsList = (props: Props) => {
  const { isDarkTheme } = useContext(ThemeContext);

  const { loading } = props;
  const EmptyStateComponent = isDarkTheme ? EmptyStateDark : EmptyStateLight;

  return (
    <ComponentContainer>
      {!loading && (
        <ContentContainer>
          <TextsWrapper>
            <Typography.Copy size={'large'}>Your projects list is empty</Typography.Copy>
            <Typography.Copy>Why don’t you go ahead and create one now?</Typography.Copy>
          </TextsWrapper>

          <DefaultButton to={'/app/projects/create'} text={'CREATE PROJECT'} />
        </ContentContainer>
      )}

      {loading && (
        <ContentContainer>
          <TextsWrapper>
            <Typography.Copy size={'large'}>Loading your projects...</Typography.Copy>
            <Typography.Copy>Please wait...</Typography.Copy>
          </TextsWrapper>
        </ContentContainer>
      )}

      <EmptyStateComponent />
    </ComponentContainer>
  );
};

export default EmptyProjectsList;
