import TokenModel from './TokenModel';
import { TOKEN_TYPE_ESDT } from '../TokensContstants';

export default class EsdtTokenModel extends TokenModel {
  type = TOKEN_TYPE_ESDT;
  nonce = '';

  description = undefined;
  owner = '';

  canBurn = undefined;
  canChangeOwner = undefined;
  canFreeze = undefined;
  canMint = undefined;
  canPause = undefined;
  canUpgrade = undefined;
  canWipe = undefined;
  canAddSpecialRoles = undefined;

  supply = undefined;

  burnt = undefined;
  minted = undefined;

  isPaused = undefined;
  status = undefined;

  constructor(params: any) {
    super(params);
    this.initializeModel(params);
  }
}
