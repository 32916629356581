import styled from 'styled-components';
import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import Typography from '../Atomic/Typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .accordion {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
`;

const AccordionItemWrapper = styled(AccordionItem)<{ expanded: boolean }>`
  background: ${props => (props.expanded ? '#141519' : 'transparent')};
  border: 1px solid #1d2129;
  border-radius: 5px;

  .title {
    font-weight: bold;
  }

  .accordion__item {
    color: ${props => props.theme.colors.text};
  }

  .accordion__heading {
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
  }

  .accordion__panel {
    padding: 20px;
    padding-top: 0;
  }
`;

type FAQItem = {
  title: string;
  description: string;
};

const FAQItems = ({ items }: { items: FAQItem[] }) => {
  const [expanded, setExpanded] = useState<any>(null);

  return (
    <Container>
      <Accordion>
        {items.map((item, key) => {
          return (
            <AccordionItemWrapper onClick={() => setExpanded(key)} expanded={expanded === key} key={key}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Typography.Copy>{item.title}</Typography.Copy>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Typography.Copy>{item.description}</Typography.Copy>
              </AccordionItemPanel>
            </AccordionItemWrapper>
          );
        })}
      </Accordion>
    </Container>
  );
};

export default FAQItems;
