import React, { createContext, ReactNode, useContext, useState } from 'react';

const defaultStepperState = {
  steps: [],
  currentStep: 0,
};

type TStepperContext = {
  steps: JSX.Element[];
  currentStep: number;
  addSteps: (steps: JSX.Element[]) => void;
  incrementCurrentStep: () => void;
  decrementCurrentStep: () => void;
};

const StepperContext = createContext({} as TStepperContext);
export const StepperProvider = ({ children }: { children: ReactNode }) => {
  const [steps, setSteps] = useState<JSX.Element[]>(defaultStepperState.steps);
  const [currentStep, setCurrentStep] = useState(defaultStepperState.currentStep);

  const addSteps = (steps: JSX.Element[]) => {
    setSteps(steps);
  };

  const incrementCurrentStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep);
    }
  };

  const decrementCurrentStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(currentStep);
    }
  };

  return (
    <StepperContext.Provider value={{ steps, currentStep, addSteps, incrementCurrentStep, decrementCurrentStep }}>
      {children}
    </StepperContext.Provider>
  );
};

export const useStepperContext = (): TStepperContext => useContext<TStepperContext>(StepperContext);
