import React from 'react';
import { Outlet } from 'react-router-dom';

import { StepperProvider } from '../../../components/Atomic/Stepper/StepperProvider';

export default function RouteWithStepper() {
  return (
    <StepperProvider>
      <Outlet />
    </StepperProvider>
  );
}
